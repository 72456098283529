import styled from 'styled-components/macro';
import { colors } from 'core/components';

export const ToolButton = styled.button`
  width: 2.25rem;
  height: 2.25rem;
  fill: ${colors.slate};
  transition: fill 0.2s;

  &:hover {
    fill: ${colors.blue};
  }

  svg {
    pointer-events: none;
  }
`;
