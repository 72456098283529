import styled from 'styled-components/macro';
import { colors } from 'core/components';

export const Wrapper = styled.section`
  position: relative;
  max-width: 648px;
`;

export const DetailHeader = styled.h4`
  ${({ theme }) => theme.text.headings.h3};
  margin: 0;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SubmissionInformation = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.tiny};
  color: ${colors.black70};
  margin-top: 0.25rem;
  margin-bottom: 2rem;
`;
