import styled from 'styled-components/macro';
import PencilIcon from 'assets/pencil-2.svg?react';

export const Container = styled.div`
  padding: 32px;
  margin-bottom: 16px;
  border: 1px solid ${({ theme }) => theme.colors.black30};
  border-radius: 16px;
  box-shadow: 0px 1px 4px 0px ${({ theme }) => theme.colors.text20};
  scroll-margin-top: 200px;
`;

export const Title = styled.h4`
  ${({ theme }) => theme.text.body.default};
  font-size: 20px;
  margin-bottom: 4px;
`;

export const Description = styled.p`
  ${({ theme }) => theme.text.body.default};
  color: ${({ theme }) => theme.colors.black70};
`;

export const TitleContainerWithIcon = styled.div`
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 24px;
  display: flex;
`;

export const PencilIconStyled = styled(PencilIcon)`
  width: 20px;
  height: 18px;
  margin-left: 0.5rem;
  path {
    stroke: ${({ theme }) => theme.colors.blue50};
  }
`;

export const EditTemplateInstructions = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin: 2rem 0 2rem 0;
`;

export const EditableHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  box-sizing: border-box;
`;

export const Field = styled.div`
  font-weight: 700;
`;
