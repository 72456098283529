import styled from 'styled-components/macro';

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  > div {
    font-weight: 700;
    line-height: 150%;
  }
`;

export const GenerateImpressionKitContainer = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: ${({ theme }) => theme.colors.blue10};
  border-radius: 8px;
  height: 120px;
  > div {
    color: ${({ theme }) => theme.colors.blue50};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
`;
