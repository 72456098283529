import { MaterialStates } from 'generated/core/graphql';

export const LegacyMaterialStates = {
  NOT_STARTED: 'not_started',
  UPLOADED: 'uploaded',
  READY_TO_SUBMIT: 'ready_to_submit',
  PROCESSING: 'processing',
  SUBMITTED: 'submitted',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  NEEDS_REPAIR: 'needs_repair',
  NEEDS_CLARIFICATION: 'needs_clarification',
  CLARIFICATION_PROVIDED: 'clarification_provided',
  REJECTED_PHOTOS: 'rejected_photos',
  EVALUATION: 'evaluation',
  COLLECTION: 'collection',
  PUSHED_TO_PRODUCTION: 'pushed_to_production',
} as const;

export enum PhotoStates {
  UPLOADED,
  MISSING,
  REJECTED,
}

export const ClinicianActionStates = [
  LegacyMaterialStates.NEEDS_CLARIFICATION,
  LegacyMaterialStates.REJECTED,
  LegacyMaterialStates.NOT_STARTED,
  LegacyMaterialStates.COLLECTION,
  LegacyMaterialStates.UPLOADED,
  LegacyMaterialStates.READY_TO_SUBMIT,
] as const;

export const CoreClinicianActionStates = [
  MaterialStates.NotStarted,
  MaterialStates.Uploaded,
  MaterialStates.NeedsClarification,
];

export type ClinicianActionState = (typeof ClinicianActionStates)[number];
export type ClinicianActionStateDict<T> = { [key in ClinicianActionState]: T };

export enum Materials {
  Photos = 'photos',
  Scans = 'scans',
  Xrays = 'xrays',
  TreatmentGoals = 'treatment_goals',
  TreatmentPlan = 'treatment_plan_staging',
}

export enum TreatmentProtocolLabels {
  StandardRx = 'Standard Rx',
  Airway = 'Airway',
}

// This is needed since the new protocol labels are only for display purposes.
// As previous labels are still used under `section.label` in the intake form
export const legacyProtocolLabelMap = {
  CandidRx: TreatmentProtocolLabels.StandardRx,
  CandidAirway: TreatmentProtocolLabels.Airway,
};
