import { useEffect, useState } from 'react';
import api from 'state/api';
import { CaseSoftwareChoice } from 'generated/core/graphql';

export default ({
  caseRef,
  currentSoftware,
  notes,
}: {
  caseRef: string;
  currentSoftware?: CaseSoftwareChoice | null;
  notes: string;
}) => {
  let software;
  if (currentSoftware === CaseSoftwareChoice.Vision) {
    software = CaseSoftwareChoice.Archform;
  } else {
    software = CaseSoftwareChoice.Vision;
  }

  const [fn, mutationResult] = api.useReinitiateTreatmentPlanningMutation();
  const [message, setMessage] = useState<string>('');
  const [status, setStatus] = useState<string>('');

  useEffect(() => {
    console.log('mutationResult', mutationResult);
    if (mutationResult.isSuccess) {
      setMessage(
        `Successfully reinitiated treatment planning for case ${caseRef}. New Treatment Planning software is ${mutationResult?.data?.caseSoftware?.software}`
      );
      setStatus('success');
    } else if (mutationResult.isError) {
      setMessage(
        `Failed to reinitiate treatment planning for case ${caseRef}.`
      );
      setStatus('error');
    }
  }, [mutationResult]);
  const executor = async () => {
    await fn({ caseRef, software, notes });
  };

  return {
    executor,
    result: mutationResult,
    message,
    status,
  };
};
