import { fetchApi } from 'utils/api';
import { RestMethod } from 'utils/api-types';

export const fetchRejectionReasons = async () => {
  try {
    const res = await fetchApi(
      '/api/v1/gateway/case/treatment-plan/rejection-reasons/',
      'GET'
    );

    return res;
  } catch (err) {
    throw new Error('Could not fetch rejection reasons');
  }
};

export const getOrderCatalog = async () => {
  try {
    const res = await fetchApi(`/api/v1/catalog/`, RestMethod.GET);

    return res;
  } catch (err) {
    throw new Error('Could not fetch order catalog');
  }
};
