import styled from 'styled-components/macro';
import { Button, type } from 'core/components';
import { Wrap, DesktopParent } from 'components/Header/Header.css';

export const Header = styled(Wrap)`
  display: flex;
  align-items: center;
`;

export const HeaderContent = styled(DesktopParent)`
  padding: 16.65px 1.25rem;
  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: flex;
  }
`;

export const HeaderTitle = styled(type.H4)`
  flex: 1;
  margin: 0.25rem 0 0;
`;

export const StatusContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  @media ${({ theme }) => theme.mediaQueries.tabletAndAbove} {
    min-width: 280px;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 1.75rem;
  width: 100%;
`;
