import React, { useContext } from 'react';
import { AuthContext } from 'components/AuthProvider';
import { ACCESS_GROUPS } from 'constants/index';
import {
  OrderItemContainer,
  Radio,
  StyledImage,
  Title,
  Description,
  TextContainer,
  OrderItemsContainer,
} from 'components/ProductThumbnail/ProductThumbnail.css';
import { ExtendedOrderItemType } from 'pages/Shipping/utils';
import { NotificationContext } from 'core/components';
import { ShippingTrackerStatusChoices } from 'generated/core/graphql';

/**
 * List of shipping tracker statuses that require shipping manager privileges.
 */
const requiresShippingManager = [
  ShippingTrackerStatusChoices.InTransit,
  ShippingTrackerStatusChoices.Delivered,
  ShippingTrackerStatusChoices.OutForDelivery,
  ShippingTrackerStatusChoices.AvailableForPickup,
];

/**
 * Evaluates whether an order item is selectable based on shipping manager privileges and shipment status.
 * @param oi - The order item to evaluate.
 * @param isShippingManager - Indicates whether the user has shipping manager privileges.
 * @returns True if the order item is selectable, false otherwise.
 */
const evaluateOrderItem = (
  oi: ExtendedOrderItemType,
  isShippingManager: boolean
): boolean => {
  // If there is no shipment or the user has shipping manager privileges, the order item is selectable.
  if (!oi?.relatedShipment || isShippingManager) {
    return true;
  }

  const shouldBlock = !!oi.relatedShipment.shipments?.some((shipment) => {
    // If not a shipping manager, only allow selection if the shipment status is not in requiresShippingManager.
    if (
      shipment &&
      !requiresShippingManager.includes(
        shipment.tracker?.status as ShippingTrackerStatusChoices
      )
    ) {
      return true;
    }

    return false;
  });

  return shouldBlock;
};

/**
 * Component that renders a list of product thumbnails for order items.
 * @param orderItems - The list of order items to display.
 * @param selectedOrderItem - The currently selected order item.
 * @param onSelectOrderItem - Callback function to handle order item selection.
 */
const ProductThumbnailList = ({
  orderItems,
  selectedOrderItem,
  onSelectOrderItem,
}: {
  orderItems: ExtendedOrderItemType[];
  selectedOrderItem: ExtendedOrderItemType | null;
  onSelectOrderItem: (item: ExtendedOrderItemType) => void;
}) => {
  const { checkHasAccess } = useContext(AuthContext);
  const isShippingManager = checkHasAccess(ACCESS_GROUPS.SHIPPING_MANAGER);
  const { showNotification } = useContext(NotificationContext);

  if (!orderItems) {
    return null;
  }

  return (
    <OrderItemsContainer>
      {orderItems.map((oi) => {
        const isSelectable = evaluateOrderItem(oi, isShippingManager);
        const hasAddressLine = oi?.shippingAddress?.addressLine1;

        return (
          <OrderItemContainer
            key={oi.orderItemRef}
            selected={selectedOrderItem?.orderItemRef === oi.orderItemRef}
            disabled={!isSelectable}
            onClick={() => {
              if (isSelectable && hasAddressLine) {
                onSelectOrderItem(oi);
              } else if (isSelectable && !hasAddressLine) {
                showNotification(
                  'Cannot select this Order Item. Missing Shipping address',
                  'error'
                );
              } else if (!isSelectable && !isShippingManager) {
                showNotification(
                  'You do not have permission to select this item.',
                  'error'
                );
              }
            }}
          >
            <Radio
              type="radio"
              name="ProductThumbnail"
              disabled={false}
              checked={selectedOrderItem?.orderItemRef === oi.orderItemRef}
              readOnly
              onClick={() => {
                if (oi?.shippingAddress?.addressLine1) {
                  onSelectOrderItem(oi);
                } else {
                  showNotification(
                    'Cannot select this Order Item. Missing Shipping address',
                    'error'
                  );
                }
              }}
            />
            <StyledImage
              src={oi.product?.content?.image || ''}
              alt={oi.product?.name || ''}
            />
            <TextContainer>
              <Title>{oi.product?.name}</Title>
              <Description>Quantity: {oi.quantity}</Description>
            </TextContainer>
            <TextContainer flexDirection="column">
              <Title>Kraken Order ID</Title>
              {oi.krakenOrderId?.map((orderId, i) => (
                <Description key={i}>{orderId}</Description>
              ))}
            </TextContainer>
            <TextContainer flexDirection="column">
              <Title>Kraken State</Title>
              {oi.krakenState?.map((state, i) => (
                <Description key={i}>{state}</Description>
              ))}
            </TextContainer>
          </OrderItemContainer>
        );
      })}
    </OrderItemsContainer>
  );
};

export default ProductThumbnailList;
