import { useEffect, useState } from 'react';
import api from 'state/api';
import { Status } from 'generated/core/graphql';

export default ({ caseRef }: { caseRef: string }) => {
  const [pollingInterval, setPollingInterval] = useState(2000);
  const [fn, mutationResult] = api.useProcessManufacturingRequestMutation();

  const [getLatestManufacturingRequest, latestManufacturingRequestResult] =
    api.useLazyGetLatestManufacturingRequestQuery({ pollingInterval });

  useEffect(() => {
    getLatestManufacturingRequest({ caseRef });
  }, [getLatestManufacturingRequest]);

  useEffect(() => {
    if (mutationResult.isSuccess) {
      setPollingInterval(5000);
    }
  }, [mutationResult.data]);

  useEffect(() => {
    if (
      latestManufacturingRequestResult.status === 'fulfilled' &&
      latestManufacturingRequestResult.data?.status !== Status.Pending
    ) {
      // Setting polling interval to 0 will stop polling
      setPollingInterval(0);
    }
  }, [
    latestManufacturingRequestResult.data,
    latestManufacturingRequestResult.status,
  ]);

  const executor = async ({ caseRef }: { caseRef: string }) => {
    await fn({ caseRef, isTest: false });
  };

  return {
    processManufacturingRequest: executor,
    processManufacturingRequestState: mutationResult,
    latestManufacturingRequest: latestManufacturingRequestResult.data,
  } as const;
};
