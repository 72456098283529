import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { Button, Divider, Modal, theme } from 'core/components';
import {
  HeaderText,
  Content,
  ButtonRow,
  ModalContainer,
} from 'components/Modals/ArchiveCaseModal.css';
import { Input, TextArea } from 'styles/inputs.css';

export const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text90};
  margin: 8px 0;
`;

type CreateTemplateModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (templateName: string, description?: string) => void;
  isLoading?: boolean;
};

const CreateTemplateModal = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
}: CreateTemplateModalProps) => {
  const [templateName, setTemplateName] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const handleTemplateNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTemplateName(event.target.value);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(event.target.value);
  };

  const submitForm = () => {
    onConfirm(templateName, description);
  };

  return (
    <Modal
      hideCloseButton={false}
      onClose={onClose}
      isOpen={isOpen}
      overlayColor={theme.colors.text50}
    >
      <ModalContainer>
        <HeaderText>Create a new case template</HeaderText>
        <Divider />
        <Content>
          <Title>Case template name</Title>
          <Input
            placeholder="Enter template name"
            type="text"
            onChange={handleTemplateNameChange}
          />
          <Title>Description (optional)</Title>
          <TextArea
            placeholder="Explain the purpose of this protocol so you can easily identify it later"
            onChange={handleDescriptionChange}
          />
        </Content>
        <Divider />
        <ButtonRow>
          <Button
            buttonType="secondary"
            disabled={!templateName}
            onClick={() => submitForm()}
            isLoading={isLoading}
          >
            Submit
          </Button>
        </ButtonRow>
      </ModalContainer>
    </Modal>
  );
};

export default CreateTemplateModal;
