import { OrganizationTypes } from 'generated/legacy/graphql';
import {
  CANDID_BRAND_NAME,
  getBrandSettings,
  GLIDEWELL_BRAND_NAME,
  OLIV_BRAND_NAME,
} from 'utils/brands';
export const AUTH_GROUPS = {
  CANDID_PRO: 'candid-pro',
  CANDID_PRO_ADMIN: 'candid-pro-admin-crud',
  JOURNEY_ADMIN: 'journey-admin',
  ORTHO: 'ortho',
  PROD_ENG: 'prod-eng',
  STUDIO: 'studio',
  SUPPORT: 'support',
  SUPPORT_MANAGER: 'support-manager',
  TREATMENT_PLAN_TECH: 'treatment-plan-tech',
  TREATMENT_PLAN_QA: 'treatment-plan-qa',
  XTENDOPS: 'xtendops',
  MATERIAL_REVIEW: 'material-review',
  BILLING_ADMIN: 'billing-admin-crud',
  BILLING_VIEWER: 'billing-admin-r',
  TPMX: 'tpmx',
  SHIPPING_MANAGER: 'shipping-manager',
  PROMOTIONS_MANAGER: 'promotions-manager',
};
export const AUTH_GROUP_TYPES = Object.values(AUTH_GROUPS);

export const ACCESS_GROUPS = {
  ADMIN: [AUTH_GROUPS.PROD_ENG],
  PRACTICE_MANAGEMENT: [AUTH_GROUPS.CANDID_PRO_ADMIN],
  AUTOMATED_BILLING_READ: [
    AUTH_GROUPS.CANDID_PRO_ADMIN,
    AUTH_GROUPS.BILLING_VIEWER,
    AUTH_GROUPS.BILLING_ADMIN,
  ],
  AUTOMATED_BILLING_ADMIN: [
    AUTH_GROUPS.BILLING_VIEWER,
    AUTH_GROUPS.BILLING_ADMIN,
  ],
  PROMOTIONS_READ: [AUTH_GROUPS.PROMOTIONS_MANAGER],
  CANDID_PRO: [AUTH_GROUPS.CANDID_PRO],
  JOURNEY_ADMIN: [AUTH_GROUPS.JOURNEY_ADMIN],
  ORTHO: [AUTH_GROUPS.ORTHO],
  STAFF: AUTH_GROUP_TYPES.filter(
    (group) => group !== AUTH_GROUPS.ORTHO && group !== AUTH_GROUPS.CANDID_PRO
  ),
  STL: [AUTH_GROUPS.STUDIO, AUTH_GROUPS.SUPPORT],
  STUDIO: [AUTH_GROUPS.STUDIO],
  XRAY: [AUTH_GROUPS.STUDIO, AUTH_GROUPS.SUPPORT],
  SUPPORT: [AUTH_GROUPS.SUPPORT],
  SUPPORT_MANAGER: [AUTH_GROUPS.SUPPORT_MANAGER],
  XTENDOPS: [AUTH_GROUPS.XTENDOPS],
  QUALITY_CONTROL: [AUTH_GROUPS.TREATMENT_PLAN_QA],
  TP_TECH: [AUTH_GROUPS.TREATMENT_PLAN_TECH],
  MATERIAL_REVIEW: [AUTH_GROUPS.MATERIAL_REVIEW],
  SHIPPING_MANAGER: [AUTH_GROUPS.SHIPPING_MANAGER],
};

// Photo views are ordered for the ABO array
export const PHOTO_VIEWS = {
  EXTRAORAL_SMILE: 'Extraoral Smile (Facial)',
  EXTRAORAL_NO_SMILE: 'Extraoral No Smile (Facial)',
  EXTRAORAL_RIGHT_PROFILE_NO_SMILE: 'Extraoral Right Profile No Smile (Facial)',
  INTRAORAL_UPPER_OCCLUSAL: 'Intraoral Upper Occlusal (Teeth)',
  INTRAORAL_LOWER_OCCLUSAL: 'Intraoral Lower Occlusal (Teeth)',
  INTRAORAL_RIGHT_LATERAL: 'Intraoral Right Lateral View (Teeth)',
  INTRAORAL_FRONTAL_VIEW: 'Intraoral Frontal View (Teeth)',
  INTRAORAL_LEFT_LATERAL: 'Intraoral Left Lateral View (Teeth)',
};
export const PHOTO_VIEW_TYPES = Object.keys(PHOTO_VIEWS);

// Photo views are ordered for the ABO array
// We don't use the reducer everywhere
export const PRISM_PHOTO_VIEWS = {
  extraoral_smile: 'Extraoral Smile (Facial)',
  extraoral_no_smile: 'Extraoral No Smile (Facial)',
  extraoral_right_profile_no_smile: 'Extraoral Right Profile No Smile (Facial)',
  intraoral_upper_occlusal: 'Intraoral Upper Occlusal (Teeth)',
  intraoral_lower_occlusal: 'Intraoral Lower Occlusal (Teeth)',
  intraoral_right_lateral_view: 'Intraoral Right Lateral View (Teeth)',
  intraoral_frontal_view: 'Intraoral Frontal View (Teeth)',
  intraoral_left_lateral_view: 'Intraoral Left Lateral View (Teeth)',
  intraoral_upper_occlusal_w_aligners: 'Intraoral Upper Occlusal With Aligners',
  intraoral_lower_occlusal_w_aligners: 'Intraoral Lower Occlusal With Aligners',
  intraoral_right_lateral_view_w_aligners:
    'Intraoral Right Lateral View With Aligners',
  intraoral_frontal_view_w_aligners: 'Intraoral Frontal View With Aligners',
  intraoral_left_lateral_view_w_aligners:
    'Intraoral Left Lateral View With Aligners',
};

export const PRISM_PHOTO_VIEW_TYPES = Object.keys(PRISM_PHOTO_VIEWS);

export const PRISM_AGGREGATE_STATES = {
  CREATED: 'created',
  COLLECTION: 'collection',
  EVALUATION: 'evaluation',
  SUBMISSION: 'submission_under_review',
  COMPLETED: 'completed',
  NOT_TREATABLE: 'could_not_treat',
};

export const PRISM_HISTORY_EVENTS = {
  PHOTO_UPLOADED: 'submitted',
  PHOTO_APPROVED: 'approved',
  PHOTO_REJECTED: 'rejected',
  GENERIC_REJECTED: 'rejected',
  ORTHO_SUBMITTED: 'ortho-submitted',
  ORTHO_APPROVED: 'ortho-approved',
  ORTHO_REJECTED: 'ortho-rejected',
  ORTHO_REJECTED_CUSTOMER: 'ortho-rejected_customer',
  ORTHO_REJECTED_PHOTOS: 'ortho-rejected_photos',
  ORTHO_REJECTED_MISSING_INFO: 'ortho-rejected_missing_information',
  ORTHO_NEEDS_CLARIFICATION: 'ortho-needs_clarification',
  CLARIFICATION_PROVIDED: 'clarification_provided',
  REOPENED: 'reopened',
};

export const NON_EDITABLE_AGGREGATE_STATES = [
  'submission_under_review',
  'completed',
  'could_not_treat',
];

export const PRODUCT_SKUS = {
  replacements: 'AKREPL0001',
};

export const CUST_CREATOR_ERROR_MESSAGES = {
  unsupported_photo_file_type:
    'File type not supported. Please re-upload with a .jpg, .jpeg or .png file type.',
  photo_submission:
    'Photos could not be submitted. Please refresh and try again. If the problem persists, please contact support.',
  photo_upload:
    'Photos could not be uploaded. Please refresh and try again. If the problem persists, please contact support.',
  treatment_details_submission:
    'Treatment details could not be submitted. Please refresh and try again. If the problem persists, please contact support.',
  treatment_goals_submission:
    'Treatment goals could not be submitted. Please refresh and try again. If the problem persists, please contact support.',
  scans_submission:
    'Scans could not be submitted. Please refresh and try again. If the problem persists, please contact support.',
  xray_submission:
    'X-rays could not be submitted. Please refresh and try again. If the problem persists, please contact support.',
  unknown_scan_type: 'Please select scan type for all files',
  both_scan_types_required:
    'Exactly one upper and one lower scan must be provided.',
  too_many_scans:
    'Too many scans have been provided for this patient. There may only be one of each type.',
  empty_stl_file:
    'The scan you are trying to submit is a zipped file. Please drag the file to your desktop and then re-upload. If you continue to have trouble, please contact support for assistance.',
  aws_s3_failure: 'One or more uploads failed. Please try again.',
};

export const FILE_SIZE_LIMIT = 20 * 1024 * 1024;

export const FILE_SIZE_LIMIT_ERROR_MESSAGE = `File size too large. Please re-upload with a file size less than ${
  FILE_SIZE_LIMIT / 1024 / 1024
} MB.`;

export const EMPTY_FILE_MESSAGE =
  'The file you are trying to upload appears to be corrupted. If you continue to have trouble, please contact support for assistance.';

export const AUTO_EXPAND_HISTORY_GROUPS = [
  ACCESS_GROUPS.MATERIAL_REVIEW,
  ACCESS_GROUPS.ORTHO,
  ACCESS_GROUPS.QUALITY_CONTROL,
  ACCESS_GROUPS.TP_TECH,
  ACCESS_GROUPS.SUPPORT,
  ACCESS_GROUPS.SUPPORT_MANAGER,
].flat();

export const PRACTICE_ORGANIZATION_TYPES = [
  {
    value: OrganizationTypes.ManagingOrganization,
    displayValue: 'Managing Organization',
  },
  {
    value: OrganizationTypes.OfficeLocation,
    displayValue: 'Office Location',
  },
];

export const BRAND_NAMES = [
  {
    value: CANDID_BRAND_NAME,
    displayValue: getBrandSettings(CANDID_BRAND_NAME).label,
  },
  {
    value: GLIDEWELL_BRAND_NAME,
    displayValue: getBrandSettings(GLIDEWELL_BRAND_NAME).label,
  },
  {
    value: OLIV_BRAND_NAME,
    displayValue: getBrandSettings(OLIV_BRAND_NAME).label,
  },
];
