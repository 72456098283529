import moment from 'moment';
import { AlertCardGetPropsType } from 'pages/Patient/types';

export const getRefinementsRequestedAlertCardProps = ({
  subsequentCase,
  selectedCase,
}: AlertCardGetPropsType) => {
  const {
    currentStep,
    daysOnCurrentStep,
    itiType,
    hasDentalWorkBeenDone,
    notes,
    itiPaymentCategory,
  } = subsequentCase?.data || {};
  return {
    currentStep,
    daysOnCurrentStep,
    itiType,
    providerFacingState: selectedCase?.caseState?.providerFacing ?? '',
    hasDentalWorkBeenDone,
    notes: notes,
    paymentCategory: itiPaymentCategory,
  };
};

export const getCaseCanceledDataAlertCardProps = ({
  selectedCase,
}: AlertCardGetPropsType) => {
  const { caseState } = selectedCase || {};

  return {
    reason: caseState?.transitionReason ?? 'No reason provided',
    notes: `Closed on ${moment(caseState?.lastUpdated).format('MMM D, YYYY')}.`, //TODO: closed by whom? do we have this information
  };
};

//TODO: Add transition reason on the backend, and make the reason dynamic
export const getCaseCompletedDataAlertCardProps = ({
  selectedCase,
}: AlertCardGetPropsType) => {
  const { caseState } = selectedCase || {};
  let reason = 'No reason provided';
  if (caseState?.transitionReason) {
    reason = caseState.transitionReason;
  }

  return {
    reason: reason,
    notes:
      'If you’ve closed this case in error and want to order more retainers for this case, contact support.',
  };
};
