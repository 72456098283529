import styled from 'styled-components/macro';

export const ToothWrapper = styled.div<{
  isSelected: boolean;
  disabled?: boolean;
}>`
  position: relative;
  height: 24px;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  svg {
    ${({ theme, isSelected, disabled }) => `
    display: block;
    width: 100%;
    height: 100%;
  
    path {
      ${
        isSelected
          ? `
          fill: ${disabled ? theme.colors.black40 : theme.colors.blue10};
          stroke: ${disabled ? theme.colors.black50 : theme.colors.blue50};
        `
          : `
          fill: ${disabled ? theme.colors.black05 : theme.colors.white};
          stroke: ${disabled ? theme.colors.black30 : theme.colors.black50};
        `
      }
    }
`}
`;
