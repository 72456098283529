import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, FormikValues } from 'formik';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { captureException } from '@sentry/react';

import { DentalNotationOptions } from 'generated/legacy/graphql';
import { getBrandFromDomain, getBrandSupportedFeatures } from 'utils/brands';
import { Template } from 'generated/core/graphql';
import { NotificationContext, type } from 'core/components';
import { AuthContext } from 'components/AuthProvider';
import { CUST_CREATOR_ERROR_MESSAGES } from 'constants/index';
import { RESERVED_QUESTION_KEYS } from 'constants/treatmentObjective';
import { PageWrapper, LeftArrow, BackButton } from 'pages/Patient/styles.css';
import { getClinicalTemplatePayload } from 'utils/treatmentObjective';

import {
  selectActiveCase,
  selectPatient,
  fetchTreatmentGoalQuestions,
  selectPatientDoctorPreferences,
  selectCanEditSubmission,
} from 'pages/Patient/patientSlice';
import TreatmentObjectives from 'components/TreatmentObjectives/TreatmentObjectives';
import { SkeletonCaseCreator } from 'pages/Patient/CaseCreator/Skeletons';
import { RouteParams } from 'pages/Patient/CaseCreator/types';
import Footer, { Steps } from 'pages/Patient/Footer';
import api from 'state/api';
import {
  getTotalAndAnsweredQuestionsCount,
  getQuestionsAndAnswersFromForm,
} from 'utils/treatmentObjective';
import useTreatmentObjective from 'hooks/useTreatmentObjective';
import { CaseTypeNames } from 'constants/Case';

const TreatmentObjectivesPage = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch<RouteParams>();
  const { push } = useHistory();
  const { showNotification } = useContext(NotificationContext);
  const { userInfo } = useContext(AuthContext);
  const patient = useSelector(selectPatient);

  //Queries
  const activeCase = useSelector(selectActiveCase);
  const doctorPreferences = useSelector(selectPatientDoctorPreferences);
  const canEditSubmission = useSelector(selectCanEditSubmission);
  const isRetainerCase = activeCase?.caseType.name === CaseTypeNames.RETAINER;

  const {
    initialValues,
    latestTreatmentObjectiveWithQuestions,
    systemTemplates,
    caseTemplates,
    isLoadingGetLatestTreatmentObjectiveWithQuestions,
    isLoadingCaseTemplates,
    selectedCaseTemplate,
    setSelectedCaseTemplate,
    setCurrentFormValues,
  } = useTreatmentObjective({
    userId: userInfo?.id,
    caseType: activeCase?.caseType.name,
    caseRef: activeCase?.caseRef,
    userAccountPreferencesTemplateId:
      userInfo?.accountPreferences?.doctor
        ?.alignerClinicalPreferencesTemplateId, // Pass the user's clinical preferences template id to force using it in the form if submitting again
  });

  const [
    getOrCreateUserClinicalPreferencesTemplate,
    { data: clinicalPreferencesTemplate },
  ] = api.useLazyGetOrCreateTemplateQuery();

  const [submitTreatmentObjectiveMutation, { isLoading: isSubmitting }] =
    api.useSubmitTreatmentObjectiveMutation();

  useEffect(() => {
    const brand = getBrandFromDomain();
    const { UseClinicalPreferencesTemplates: useClinicalPreferencesTemplates } =
      getBrandSupportedFeatures(brand);

    if (!userInfo || !useClinicalPreferencesTemplates) {
      return;
    }

    const clinicalPreferencesTemplatePayload = getClinicalTemplatePayload(
      userInfo,
      brand
    );

    if (clinicalPreferencesTemplatePayload) {
      getOrCreateUserClinicalPreferencesTemplate(
        clinicalPreferencesTemplatePayload
      );
    }
  }, [userInfo]);

  useEffect(() => {
    if (!latestTreatmentObjectiveWithQuestions || !caseTemplates) {
      return;
    }

    const submittedIds =
      latestTreatmentObjectiveWithQuestions.data.templateSections.map(
        (ts) => ts.templateId
      );

    const previouslySelectedCaseTemplate =
      caseTemplates.find((t) => submittedIds.includes(t.id)) ||
      systemTemplates?.find((t) => submittedIds.includes(t.id));

    if (previouslySelectedCaseTemplate) {
      setSelectedCaseTemplate(previouslySelectedCaseTemplate as Template);
    }
  }, [latestTreatmentObjectiveWithQuestions, caseTemplates]);

  const stringsToNumbers = (values: string[]): number[] => {
    return values?.map((value) => Number(value)) || [];
  };

  const onSubmit = async (values?: FormikValues): Promise<boolean> => {
    if (
      !userInfo ||
      !values ||
      !activeCase?.caseRef ||
      !patient?.id ||
      !systemTemplates?.length
    ) {
      return false;
    }
    try {
      const { total, current } = getTotalAndAnsweredQuestionsCount({
        values,
        systemTemplate: selectedCaseTemplate,
        includeChiefComplaint: !isRetainerCase,
      });

      const isDraft = total !== current;

      const template = clinicalPreferencesTemplate;

      const templateSections = [
        getQuestionsAndAnswersFromForm({
          template: selectedCaseTemplate as Template,
          answers: values,
        }),
        getQuestionsAndAnswersFromForm({
          template: template as Template,
          answers: JSON.parse(template?.userAnswers?.root ?? '{}'),
        }),
      ];

      const payload = {
        caseRef: activeCase.caseRef,
        patientId: Number(patient.id),
        isDraft,
        data: {
          ...(!isRetainerCase && {
            chiefComplaint: values[RESERVED_QUESTION_KEYS.CHIEF_COMPLAINT],
            toothCharts: {
              cannotMove: stringsToNumbers(
                values[RESERVED_QUESTION_KEYS.CANNOT_MOVE]
              ),
              eruptionCompensation: stringsToNumbers(
                values[RESERVED_QUESTION_KEYS.ERUPTION_COMPENSATION]
              ),
              willExtract: stringsToNumbers(
                values[RESERVED_QUESTION_KEYS.WILL_EXTRACT]
              ),
            },
          }),
          templateSections: templateSections,
        },
      };

      await submitTreatmentObjectiveMutation({
        input: payload,
      }).unwrap();

      showNotification(`Treatment goals saved`, 'success');

      await dispatch(
        fetchTreatmentGoalQuestions({
          caseRef: activeCase.caseRef,
          fetchBetaForms: false,
          formsToFetch: [],
        })
      );
      return true;
    } catch (err) {
      showNotification(
        CUST_CREATOR_ERROR_MESSAGES.treatment_goals_submission,
        'error'
      );

      captureException(err);
      return false;
    }
  };

  const showLoading =
    isLoadingGetLatestTreatmentObjectiveWithQuestions || isLoadingCaseTemplates;

  if (showLoading) {
    return <SkeletonCaseCreator />;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values }) => {
        useEffect(() => {
          setCurrentFormValues(values);
        }, [values]);

        const { total, current } = getTotalAndAnsweredQuestionsCount({
          values,
          systemTemplate: selectedCaseTemplate as Template,
          includeChiefComplaint: !isRetainerCase,
        });

        return (
          <PageWrapper isNarrow>
            <BackButton
              onClick={() => {
                push(`${match.url.split('/').slice(0, -1).join('/')}`);
              }}
            >
              <LeftArrow /> Back to all case tasks
            </BackButton>
            <type.H4 style={{ marginBottom: '16px' }}>Treatment goals</type.H4>
            <TreatmentObjectives
              systemTemplates={systemTemplates as Template[]}
              caseTemplates={caseTemplates as Template[]}
              selectedCaseTemplate={selectedCaseTemplate}
              setSelectedCaseTemplate={setSelectedCaseTemplate}
              showDescription
              dentalNotation={
                doctorPreferences?.dentalNotation as DentalNotationOptions
              }
              canEditSubmission={canEditSubmission}
              showCaseTemplateSelector
            />
            <Footer
              currentStep={Steps.TreatmentGoals}
              progressBarProps={{ total, current }}
              rightMessage={
                total > 0 && current === total
                  ? '🎉  You’ve completed all the tasks for this case!'
                  : ''
              }
              onSubmit={() => {
                return onSubmit(values);
              }}
              disabled={false}
              isCtaLoading={isSubmitting}
            />
          </PageWrapper>
        );
      }}
    </Formik>
  );
};

export default TreatmentObjectivesPage;
