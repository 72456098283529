import React from 'react';
import EyeIcon from 'assets/ic_eye.svg?react';
import EyeOffIcon from 'assets/ic_eye-off.svg?react';
import styled from 'styled-components';

const EyeButton = styled.button`
  & svg {
    stroke: ${({ theme }) => theme.colors.text};
  }
`;

const EyeToggleButton = ({
  hide,
  setHide,
}: {
  hide: boolean;
  setHide: (hide: boolean) => void;
}) => {
  return (
    <EyeButton type="button" onClick={() => setHide(!hide)}>
      {hide ? <EyeOffIcon /> : <EyeIcon />}
    </EyeButton>
  );
};

export default EyeToggleButton;
