import styled from 'styled-components';
import Edit from 'assets/ic_edit-2.svg?react';
import Clock from 'assets/ic_clock.svg?react';

export const NoteEditorContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 1rem;
  h3 {
    margin-bottom: 0.5rem;
  }
`;

export const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.black30};
  border-radius: 5px;
  resize: none;
  line-height: 1rem;
  overflow-y: auto;
  height: 100px;
  @media ${({ theme }) => theme.mediaQueries.tabletAndAbove} {
    height: 250px;
  }
`;

export const EditButton = styled.button`
  margin-bottom: 4px;
  scale: 0.8;
  :disabled {
    opacity: 0.25;
  }
`;

export const EditorHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  & h3 {
    margin: 0;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 2rem;
  gap: 0.5rem;
`;

export const LengthTracker = styled.div<{ text: string; maxLength: number }>`
  ${(props) => {
    const { text, maxLength } = props;
    return `
      color: ${text.length > maxLength ? `${props.theme.colors.red50}` : `${props.theme.colors.text}`};
      ::after {
        content: '${text.length}/${maxLength}';
      }
    `;
  }}
`;

export const StyledEditIcon = styled(Edit)`
  stroke: ${({ theme }) => theme.colors.text};
`;

export const StyledClockIcon = styled(Clock)`
  stroke: ${({ theme }) => theme.colors.black70};
`;

export const LastModifiedText = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    color: ${theme.colors.black90};
    margin: 0;
    gap: 0.5rem;
    & p {
      margin-top: 20px
    }
`}
`;
