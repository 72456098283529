import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { DocumentNode } from 'graphql';
import moment from 'moment';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import {
  CandidProCustomerDocument,
  CandidProCustomerQuery,
  CandidProCustomerQueryVariables,
  Maybe,
  PhotoTypeFieldsFragment,
  PrismAggregatesDocument,
  PrismAggregatesQuery,
  PrismAggregatesQueryVariables,
  SubmitCaseDocument,
  CreatePrismPhotoMutation,
  CreatePrismPhotoMutationVariables,
  CreatePrismPhotoDocument,
  PhotoTypes,
  SubmitCaseMutation,
  SubmitCaseMutationVariables,
  TransitionPrismSubmissionDocument,
  TransitionPrismSubmissionMutation,
  TransitionPrismSubmissionMutationVariables,
  TransitionSubmissionInput,
  AddressType,
  MeQuery,
  MeQueryVariables,
  MeDocument,
  CreatePrismPhotoMlMutation,
  CreatePrismPhotoMlMutationVariables,
  CreatePrismPhotoMlDocument,
  GetPhotoUploadDataQuery,
  GetPhotoUploadDataQueryVariables,
  GetPhotoUploadDataDocument,
  GetPhotoClassificationQuery,
  GetPhotoClassificationQueryVariables,
  GetPhotoClassificationDocument,
  TransitionJourneyDocument,
  TransitionJourneyMutation,
  TransitionJourneyMutationVariables,
  LegacyUpdateCaseMutation,
  LegacyUpdateCaseMutationVariables,
  LegacyUpdateCaseDocument,
} from 'generated/legacy/graphql';
import { getOrderCatalog } from 'api/case';
import {
  AddScanMutation,
  AddScanMutationVariables,
  AddScanDocument,
  AddXrayMutation,
  AddXrayMutationVariables,
  AddXrayDocument,
  GetMaterialUploadDataQuery,
  GetMaterialUploadDataQueryVariables,
  GetMaterialUploadDataDocument,
  RemoveMaterialMutation,
  RemoveMaterialMutationVariables,
  RemoveMaterialDocument,
  SubmitIntakeMutation,
  SubmitIntakeMutationVariables,
  SubmitIntakeDocument,
  IntakeSectionInput,
  GetFormDocument,
  GetFormQuery,
  GetFormQueryVariables,
  GetIntakeFormByCaseRefDocument,
  GetIntakeFormByCaseRefQuery,
  GetIntakeFormByCaseRefQueryVariables,
  GetMaterialEvaluationRejectionReasonsQuery,
  GetScansByCaseRefDocument,
  GetScansByCaseRefQuery,
  GetScansByCaseRefQueryVariables,
  GetXraysByCaseRefDocument,
  GetXraysByCaseRefQuery,
  GetXraysByCaseRefQueryVariables,
  Intake,
  IntakeTypes,
  QuestionTypes,
  ScanTypes,
  MaterialEvaluationTypes,
  GetTreatmentPlanStagingsByCaseRefQueryVariables,
  GetTreatmentPlanStagingsByCaseRefDocument,
  TreatmentPlanStaging,
  GetTreatmentPlanStagingsByCaseRefQuery,
  FormSection,
  GetCasesQuery,
  GetCasesQueryVariables,
  GetCasesDocument,
  MaterialActionTypes,
  MaterialState,
  MaterialStates,
  StateTransitions,
  WorkflowUploadSources,
  CreateCaseMutation,
  CreateCaseMutationVariables,
  CreateCaseDocument,
  CancelCaseDocument,
  CancelCaseMutation,
  CancelCaseMutationVariables,
  CaseSource,
  CompleteCaseMutation,
  CompleteCaseMutationVariables,
  CompleteCaseDocument,
  AddMaterialEvaluationsMutation,
  AddMaterialEvaluationsMutationVariables,
  AddMaterialEvaluationsDocument,
  ReopenCaseMutation,
  ReopenCaseDocument,
  ReopenCaseMutationVariables,
  CreateOrderDocument,
  CreateOrderMutation,
  CreateOrderMutationVariables,
  GetMostRecentlyApprovedTpStagingForPatientDocument,
  GetMostRecentlyApprovedTpStagingForPatientQuery,
  GetMostRecentlyApprovedTpStagingForPatientQueryVariables,
  RefreshCaseStateDocument,
  RefreshCaseStateMutation,
  RefreshCaseStateMutationVariables,
  GetLatestTreatmentObjectiveWithQuestionsDocument,
  GetLatestTreatmentObjectiveWithQuestionsQuery,
  GetLatestTreatmentObjectiveWithQuestionsQueryVariables,
} from 'generated/core/graphql';

import { fetchOrderItemsShipping } from 'api/orders';
import {
  ApolloClientType,
  client as apolloClient,
  coreClient,
} from 'gql/GraphQLProvider';
import { RootState } from 'state/store';
import { autoQuery } from 'state/system';
import { Sort, sortByCreated, sortByDate, splitList } from 'utils/prism';

import { OrderItemsWithShipment } from 'components/Modals/OrderShippingModal/types';
import { AlertTypeEnum } from 'pages/Patient/types';
import { getValidFileExtension } from 'pages/Patient/utils';

import {
  getAnswerFieldName,
  getExplanationFieldName,
  getListAnswerFieldName,
} from 'components/FormikForms/utils';
import {
  ClinicianActionState,
  ClinicianActionStates,
  LegacyMaterialStates,
  PhotoStates,
} from 'constants/Material';
import { FormikValues } from 'formik';
import { groupBy } from 'lodash';
import { FileInfo, cleanFilenameForAws } from 'utils/materials';
import { StringMap } from 'utils/types';
import { getCarrierTrackingUrl, getProfilePhoto } from 'pages/Patient/utils';

import {
  capitalizeAWord,
  lowercaseAWord,
  snakeCaseToCamelCase,
} from 'utils/string';

import { formatPhoneNumber } from 'utils/customer';

import { FormProps } from 'pages/Patient/CaseCreator/BasicInfo/BasicInfoForm';
import { ProfileInfoType } from 'pages/Patient/types';
import {
  AddressFormType,
  SelectedAddress,
  SelectedAddressType,
} from 'components/AddressForm/types';
import { ALIGNER_JOURNEY_TYPE } from 'pages/Case/CaseProvider';
import { JourneyTransition } from 'pages/Case/types';
import {
  POST_TREATMENT_STATUSES,
  PROVIDER_FACING_STATUSES,
} from 'constants/caseStatus';
import {
  ProviderFacingStates,
  LastStepRetainerIneligibleTpFeatures,
  CaseTypeNames,
} from 'constants/Case';
import { OperationVariables } from '@apollo/client';
import { CaseCloseType } from 'components/Modals/CaseInProgressModal';
import { ProductTypes } from 'types/checkout';
import {
  CANDID_BRAND_NAME,
  convertToBrand,
  getBrandSKUs,
  SupportedBrand,
} from 'utils/brands';

type CoreCase = GetCasesQuery['getCases'][0];
type SubmittableMaterialTypes =
  | GetXraysByCaseRefQuery['getXrayMaterialsByCaseRef'][0]
  | GetScansByCaseRefQuery['getScanMaterialsByCaseRef'][0];

type EvaluationRejectionReason =
  GetMaterialEvaluationRejectionReasonsQuery['getMaterialEvaluationRejectionReasons'][0];
type MaterialEvaluationRejectionReasonMap = {
  [key: string]: EvaluationRejectionReason[] | undefined;
};
type BasicInfoSection = {
  displayAddressConfirmation?: boolean;
  addressSuggestions?: AddressFormType[];
  basicInfoFormValues?: FormProps;
};
export type CreateProOrderArgs = {
  caseRef: string;
  customerId: string;
  shippingAddress: SelectedAddress;
  shippingAddressType: SelectedAddressType;
  sendPatientUpdate: boolean;
  clientCouponCode?: string | null;
  scanIntervalDays?: number;
  enableCoreAlignerOrders?: boolean;
};

export type Patient = CandidProCustomerQuery['customer'];
export type Case = GetCasesQuery['getCases'][0];
type TpStagingSlim =
  GetMostRecentlyApprovedTpStagingForPatientQuery['getMostRecentlyApprovedTpStagingForPatient'];

type PatientState = {
  patient: null | Patient;
  orderItems: OrderItemsWithShipment[];
  cases: GetCasesQuery['getCases'];
  selectedCaseRef: string | null;
  basicInfoState: BasicInfoSection;
  intakeForms: GetIntakeFormByCaseRefQuery['getIntakeMaterialsByCaseRef'];
  latestTreatmentObjective: GetLatestTreatmentObjectiveWithQuestionsQuery['getLatestTreatmentObjectiveWithQuestions'];
  materialEvaluationRejectionReasonsMap: MaterialEvaluationRejectionReasonMap;
  numberMissingPhotos: number;
  prismAggregates: PrismAggregatesQuery['prismAggregates'];
  treatmentPlanStagings: GetTreatmentPlanStagingsByCaseRefQuery['getTreatmentPlanStagingsByCaseRef'];
  xrays: GetXraysByCaseRefQuery['getXrayMaterialsByCaseRef'];
  scans: GetScansByCaseRefQuery['getScanMaterialsByCaseRef'];
  treatmentGoalForms: GetFormQuery['getForm'][];
  postSubmitPrismClarificationInfo: NeedsClarificationInfo | null;
  pendingClarificationResponse: NeedsClarificationInfo | null;
  profilePhoto: string | null;
  userInfo: MeQuery['me'];
  orderCatalogs: any[];
  isLastStepEligible: boolean | null;
  areUnsubmittedTreatmentGoalsValid: boolean;
  selectedProductType: ProductTypes | null;
  isRefinementModalOpen: boolean;
  isQualityTicketModalOpen: boolean;
  isProductSelectionModalOpen: boolean;
  mostRecentlyApprovedTpStagingForPatient: TpStagingSlim;
};

const initialState: PatientState = {
  cases: [],
  patient: null,
  orderItems: [],
  orderCatalogs: [],
  selectedCaseRef: null,
  mostRecentlyApprovedTpStagingForPatient: null,
  basicInfoState: {
    displayAddressConfirmation: false,
    addressSuggestions: [],
    basicInfoFormValues: {
      firstName: '',
      lastName: '',
      email: '',
      dateOfBirth: null,
      day: '',
      month: '',
      year: '',
      practiceId: '1',
      shippingAddress: {
        addressType: 'shipping',
        addressLine1: '',
        addressLine2: '',
        businessName: '',
        city: '',
        zip: '',
        validatedBy: null,
      },
      legalGuardian: {
        firstName: '',
        middleName: '',
        lastName: '',
        preferredName: '',
        phone: '',
        birthday: null,
      },
    },
  },
  intakeForms: [],
  latestTreatmentObjective: null,
  materialEvaluationRejectionReasonsMap: {},
  prismAggregates: null,
  numberMissingPhotos: 0,
  treatmentPlanStagings: [],
  xrays: [],
  scans: [],
  treatmentGoalForms: [],
  postSubmitPrismClarificationInfo: null,
  pendingClarificationResponse: null,
  profilePhoto: null,
  userInfo: null,
  isLastStepEligible: null,
  areUnsubmittedTreatmentGoalsValid: false,
  selectedProductType: null,
  isRefinementModalOpen: false,
  isQualityTicketModalOpen: false,
  isProductSelectionModalOpen: false,
};

export const fetchPatient =
  autoQuery.createQueryAction<CandidProCustomerQueryVariables>(
    'patient/fetchPatient',
    CandidProCustomerDocument,
    'patient/setPatient'
  );

export const fetchUserInfo = autoQuery.createQueryAction<MeQueryVariables>(
  'patient/fetchUserInfo',
  MeDocument,
  'patient/setUserInfo'
);

export const fetchOrders = createAsyncThunk(
  'patient/fetchOrders',
  async ({ caseRef }: { caseRef: string }) =>
    fetchOrderItemsShipping([], caseRef)
);

export const submitCase = createAsyncThunk(
  'patient/submitCase',
  async (input: SubmitCaseMutationVariables, store) => {
    const { data } = await apolloClient.mutate<
      SubmitCaseMutation,
      SubmitCaseMutationVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: SubmitCaseDocument,
      variables: input,
    });

    const state = store.getState() as RootState;
    const customerId = state.patient.patient?.id;
    customerId && store.dispatch(fetchPatient({ customerId }));
    return data;
  }
);

const updateCase = createAsyncThunk(
  'patient/updateCase',
  async (input: LegacyUpdateCaseMutationVariables) => {
    const { data } = await apolloClient.mutate<
      LegacyUpdateCaseMutation,
      LegacyUpdateCaseMutationVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: LegacyUpdateCaseDocument,
      variables: input,
    });

    return data;
  }
);

export const approveTreatmentPlanActions = createAsyncThunk(
  'patient/approveTreatmentPlanActions',
  async (input: CreateProOrderArgs, store) => {
    const { cases, treatmentPlanStagings, patient, intakeForms } = (
      store.getState() as RootState
    ).patient;
    const activeCase = cases.find((c) => c.isActive);
    const latestTpStaging = [...treatmentPlanStagings].sort(
      sortByCreated(Sort.Desc)
    )[0];
    if (!activeCase || !latestTpStaging?.id || !patient?.id) {
      return;
    }
    const { scanIntervalDays, ...proOrderInput } = input;

    await coreClient.mutate<
      AddMaterialEvaluationsMutation,
      AddMaterialEvaluationsMutationVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: AddMaterialEvaluationsDocument,
      variables: {
        caseRef: activeCase.caseRef,
        materialEvaluationsInput: [
          {
            materialId: latestTpStaging.id,
            materialEvaluationType:
              MaterialEvaluationTypes.ProClinicianEvaluation,
            approved: true,
            data: {
              evaluationNotes: '',
            },
            repairable: false,
            rejectionReasons: [],
          },
        ],
        patientId: Number(patient?.id),
        annotatedFileLocations: [],
      },
    });

    if (patient.practice && patient.practice.id) {
      const shippingAddress = proOrderInput.shippingAddress;
      const isRefinementCase =
        activeCase.caseType.name === CaseTypeNames.REFINEMENTS;
      const skusByBrand = getBrandSKUs(
        convertToBrand(patient?.user?.brandInfo?.name, CANDID_BRAND_NAME)
      );

      const orderItems = [
        {
          productVariantSku: isRefinementCase
            ? skusByBrand.refinementGood.sku
            : skusByBrand.alignerGood.sku,
          quantity: 1,
          sentPatientShippingUpdate: proOrderInput.sendPatientUpdate,
        },
        {
          productVariantSku: isRefinementCase
            ? skusByBrand.refinement.sku
            : skusByBrand.aligner.sku,
          quantity: 1,
          sentPatientShippingUpdate: proOrderInput.sendPatientUpdate,
        },
      ];

      const requestedZoomWhitening = intakeForms.find((tg) => {
        if (!tg.isDraft) {
          return tg.data.sections.some((section) =>
            section.answers.some(
              (answer) => answer.questionKey === 'whitening' && !!answer.answer
            )
          );
        }
      });

      if (requestedZoomWhitening) {
        orderItems.push({
          productVariantSku: 'ZOOMWHITENING00003',
          quantity: 1,
          sentPatientShippingUpdate: proOrderInput.sendPatientUpdate,
        });
      }

      // Create the order with both the good and the service
      await store
        .dispatch(
          createOrder({
            autoActivate: true,
            caseRef: proOrderInput?.caseRef,
            practiceId: parseInt(patient?.practice.id ?? 1),
            couponCodes: proOrderInput.clientCouponCode ?? [],
            patientId: parseInt(patient?.id),
            orderItems: orderItems,
            shippingAddress: {
              name: `${shippingAddress.firstName} ${shippingAddress.lastName}`,
              country: shippingAddress.countryCode ?? '',
              addressLines: shippingAddress.addressLine2
                ? [
                    shippingAddress.addressLine1 ?? '',
                    shippingAddress.addressLine2 ?? '',
                  ]
                : [shippingAddress.addressLine1 ?? ''],
              city: shippingAddress.city ?? '',
              postalCode: shippingAddress.zip ?? '',
              adminRegion: shippingAddress.stateCode ?? '',
              skipDeliveryValidation: Boolean(shippingAddress.validatedBy),
            },
          })
        )
        .unwrap();
    }

    await store.dispatch(
      fetchTreatmentPlanStagings({
        caseRef: activeCase?.caseRef,
      })
    );

    if (activeCase?.caseRef && scanIntervalDays) {
      await store.dispatch(
        updateCase({
          caseInput: {
            caseRef: activeCase?.caseRef,
            updates: {
              data: { scanIntervalDays },
            },
          },
        })
      );
    }

    return;
  }
);

const cancelLegacyCase = createAsyncThunk(
  'patient/cancelLegacyCase',
  async ({
    caseRef,
    closeReason,
  }: {
    caseRef: string;
    closeReason?: string;
  }) => {
    const { data } = await apolloClient.mutate<
      TransitionJourneyMutation,
      TransitionJourneyMutationVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: TransitionJourneyDocument,
      variables: {
        caseRef: caseRef,
        component: ALIGNER_JOURNEY_TYPE,
        transition: JourneyTransition.ForceCaseCanceled,
        transitionReason: closeReason ?? 'Creating a new case, case cancelled',
      },
    });
    return data;
  }
);

const completeLegacyCase = createAsyncThunk(
  'patient/completeLegacyCase',
  async ({
    caseRef,
    closeReason,
  }: {
    caseRef: string;
    closeReason?: string;
  }) => {
    const { data } = await apolloClient.mutate<
      TransitionJourneyMutation,
      TransitionJourneyMutationVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: TransitionJourneyDocument,
      variables: {
        caseRef: caseRef,
        component: ALIGNER_JOURNEY_TYPE,
        transition: JourneyTransition.ForceCaseCompleted,
        transitionReason:
          closeReason ?? 'Creating a new case, case completed automatically',
      },
    });
    return data;
  }
);

const completeCoreCase = createAsyncThunk(
  'patient/completeCase',
  async (input: CompleteCaseMutationVariables) => {
    const { data } = await coreClient.mutate<
      CompleteCaseMutation,
      CompleteCaseMutationVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: CompleteCaseDocument,
      variables: input,
    });

    return data;
  }
);

export const cancelCoreCase = createAsyncThunk(
  'patient/cancelCase',
  async (input: CancelCaseMutationVariables) => {
    const { data } = await coreClient.mutate<
      CancelCaseMutation,
      CancelCaseMutationVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: CancelCaseDocument,
      variables: input,
    });

    return data;
  }
);

export const reopenCase = createAsyncThunk(
  'patient/reopenCase',
  async (caseRef: string, store) => {
    const cases = (store.getState() as RootState).patient.cases;
    //If we ever have 2 cases with the same case ref, something went very wrong
    const casesToReopen = cases.filter((c) => {
      return c.caseRef === caseRef;
    });

    if (casesToReopen.length !== 1) {
      return;
    }

    const caseToReopen = casesToReopen[0];
    //If the case is already active, we don't need to do anything
    //We probably shouldn't even have gotten here
    if (caseToReopen.isActive) {
      return;
    }

    if (caseToReopen.source === CaseSource.Core) {
      const { data } = await coreClient.mutate<
        ReopenCaseMutation,
        ReopenCaseMutationVariables
      >({
        fetchPolicy: 'no-cache',
        mutation: ReopenCaseDocument,
        variables: {
          caseRef: caseRef,
        },
      });
      return data;
    } else {
      const { data } = await apolloClient.mutate<
        TransitionJourneyMutation,
        TransitionJourneyMutationVariables
      >({
        fetchPolicy: 'no-cache',
        mutation: TransitionJourneyDocument,
        variables: {
          caseRef: caseRef,
          component: ALIGNER_JOURNEY_TYPE,
          transition: JourneyTransition.ForceCaseActive,
        },
      });
      return data;
    }
  }
);

type CaseCloseVariables = {
  caseCloseType: CaseCloseType;
  closureReason: string;
};

type CreateCaseVariables = {
  caseCreationInput: CreateCaseMutationVariables;
  caseCloseInput?: CaseCloseVariables;
};

export const createCase = createAsyncThunk(
  'patient/createCase',
  async (input: CreateCaseVariables, store) => {
    const { cases } = (store.getState() as RootState).patient;

    const activeCases = cases.filter((c) => c.isActive);

    //Filter out retainer cases, since they will never have evaluation requirements
    const existingCaseUsedBypassOrtho = cases
      .filter(
        (c) =>
          c.caseType.name === CaseTypeNames.ALIGNER ||
          c.caseType.name === CaseTypeNames.REFINEMENTS
      )
      //Next find if there are any cases that have do not ortho review requirements
      .some((c) =>
        c.workflow?.productionRequirements.some((pr) =>
          //Check that none of the requirements are ortho evaluations
          //If that is true, this case is a bypass ortho
          pr.evaluationRequirements.every(
            (er) =>
              er?.evaluationType !==
              MaterialEvaluationTypes.OrthodonticEvaluation
          )
        )
      );

    const completeCaseReason =
      input.caseCloseInput?.closureReason ??
      `Case ${input.caseCloseInput?.caseCloseType === CaseCloseType.Cancel ? 'cancelled' : 'completed'} to start another case`;
    try {
      await Promise.all(
        activeCases.map(async (c) => {
          let result;
          if (c.source === CaseSource.Core) {
            result =
              input.caseCloseInput?.caseCloseType === CaseCloseType.Complete
                ? await store.dispatch(
                    completeCoreCase({
                      caseRef: c.caseRef,
                      reason: completeCaseReason,
                    })
                  )
                : await store.dispatch(
                    cancelCoreCase({
                      caseRef: c.caseRef,
                      reason: completeCaseReason,
                    })
                  );
          } else {
            result =
              input.caseCloseInput?.caseCloseType === CaseCloseType.Complete
                ? await store.dispatch(
                    completeLegacyCase({
                      caseRef: c.caseRef,
                      closeReason: completeCaseReason,
                    })
                  )
                : await store.dispatch(
                    cancelLegacyCase({
                      caseRef: c.caseRef,
                      closeReason: completeCaseReason,
                    })
                  );
          }
          if ((result as any)?.error) {
            throw new Error('Failed to close case');
          }
        })
      );
      const { data: result } = await coreClient.mutate<
        CreateCaseMutation,
        CreateCaseMutationVariables
      >({
        fetchPolicy: 'no-cache',
        mutation: CreateCaseDocument,
        variables: {
          caseOptions: input.caseCreationInput.caseOptions,
          workflowOptions: {
            ...input.caseCreationInput.workflowOptions,
            bypassOrthoReview:
              input.caseCreationInput.workflowOptions?.bypassOrthoReview ||
              existingCaseUsedBypassOrtho,
          },
        },
      });
      return result;
    } catch (e) {
      Sentry.captureException('Failed to create core case');
      throw e;
    }
  }
);

export const fetchMostRecentlyApprovedTpStagingForPatient = createAsyncThunk(
  'patient/fetchMostRecentlyApprovedTpStagingForPatient',
  async (patientId: number) => {
    const { data } = await coreClient.query<
      GetMostRecentlyApprovedTpStagingForPatientQuery,
      GetMostRecentlyApprovedTpStagingForPatientQueryVariables
    >({
      fetchPolicy: 'no-cache',
      query: GetMostRecentlyApprovedTpStagingForPatientDocument,
      variables: { patientId },
    });
    return data?.getMostRecentlyApprovedTpStagingForPatient;
  }
);

export const fetchPatientLastStepEligible = createAsyncThunk(
  'patient/fetchPatientLastStepEligible',
  async (_, store) => {
    // Case's eligibility
    let { cases, treatmentPlanStagings } = (store.getState() as RootState)
      .patient;
    const activeCase = cases.find((c) => c.isActive);
    if (!activeCase) {
      return false;
    }
    const isCaseEligible = POST_TREATMENT_STATUSES.includes(
      activeCase?.caseState?.providerFacing as PROVIDER_FACING_STATUSES
    );
    if (!isCaseEligible) {
      return false;
    }
    // Exception for retainer case type, it is always eligible regardless of the treatment plan
    if (activeCase?.caseType?.name === CaseTypeNames.RETAINER) {
      return true;
    }
    // TP's eligibilty
    if (
      treatmentPlanStagings.length === 0 ||
      treatmentPlanStagings[0]?.caseRef !== activeCase?.caseRef
    ) {
      const { data } = await coreClient.query<
        GetTreatmentPlanStagingsByCaseRefQuery,
        GetTreatmentPlanStagingsByCaseRefQueryVariables
      >({
        fetchPolicy: 'no-cache',
        query: GetTreatmentPlanStagingsByCaseRefDocument,
        variables: {
          caseRef: activeCase?.caseRef,
        },
      });
      treatmentPlanStagings = data?.getTreatmentPlanStagingsByCaseRef ?? [];
    }
    const lastStaging = [...treatmentPlanStagings].sort(
      sortByCreated(Sort.Desc)
    )[0];
    const tp_data = lastStaging?.data;
    if (!tp_data) {
      return false; // If we don't have data, it is ineligible
    }
    return !Object.entries(tp_data).some(
      ([key, value]) =>
        LastStepRetainerIneligibleTpFeatures.includes(key) && value
    );
  }
);

type updateScanUploadTypePayload = {
  caseRef: string;
  newState: WorkflowUploadSources;
};

export const selectPatient = (state: RootState) => state.patient.patient;
const selectOrderItems = (state: RootState) => state.patient.orderItems;

export const selectPatientName = createSelector(selectPatient, (patient) =>
  patient ? `${patient.firstName} ${patient.lastName}` : null
);

export const selectHomeAddress = createSelector(
  selectPatient,
  (patient) =>
    patient?.addresses?.find(
      (a) => a.addressType === 'Shipping Address'
    ) as AddressType
);

const selectProvider = createSelector(
  selectPatient,
  (patient) => patient?.referringDentist
);

export const selectPatientBrandName = createSelector(
  selectPatient,
  (patient): SupportedBrand =>
    convertToBrand(patient?.user?.brandInfo?.name, CANDID_BRAND_NAME)
);

export const selectProfileInfo = createSelector(
  selectPatient,
  selectHomeAddress,
  selectProvider,
  (patient, address, provider) => {
    const {
      preferredName,
      firstName,
      lastName,
      birthday,
      user,
      sex,
      phone,
      legalGuardian,
      practice,
      referringDentist,
    } = patient || {};
    const fullName = `${firstName} ${lastName}`;
    const displayAddress = address
      ? `${address.addressLine1},${
          address.addressLine2 ? `${address.addressLine2}, ` : ``
        } ${address.city}, ${address.stateCode} ${address.zip}`
      : '';
    return {
      id: patient?.id,
      preferredName: preferredName || firstName,
      fullName,
      sex,
      phone,
      dateOfBirth: moment(birthday ?? '').format('MMM D, YYYY'),
      email: user?.email,
      displayAddress,
      legalGuardian,
      treatingProviderName: provider?.fullName,
      treatingProviderId: provider?.id,
      practiceName: practice?.name,
      practiceId: practice?.id,
      referringDentistId: referringDentist?.id,
    } as ProfileInfoType;
  }
);

export const selectCases = (state: RootState) => state.patient.cases;

export const selectLatestCase = createSelector(
  selectCases,
  (cases) => cases?.[0]
);

export const selectActiveCase = createSelector(selectCases, (cases) =>
  cases?.find((c) => c?.isActive)
);
const selectSelectedCaseRef = (state: RootState) =>
  state.patient.selectedCaseRef;

export const selectSelectedCase = createSelector(
  selectCases,
  selectSelectedCaseRef,
  selectActiveCase,
  (cases, caseRef, activeCase) => {
    if (!caseRef && cases.length > 0) {
      return activeCase ?? cases[0];
    }

    return cases?.find((c) => c?.caseRef === caseRef);
  }
);

const selectSelectedCaseState = createSelector(
  selectSelectedCase,
  (selectedCase) => selectedCase?.caseState ?? null
);

export const selectBypassOrthoReview = createSelector(
  selectSelectedCase,
  (selectedCase) => {
    // if there are no production requirements, we can bypass ortho review
    if (!selectedCase?.workflow?.productionRequirements) {
      return true;
    }

    const allEvaluationsTypesNeeded =
      selectedCase!.workflow!.productionRequirements!.flatMap(
        (req) => req.evaluationRequirements
      );

    // bypass ortho review if the 'ortho_evaluation' is not present
    return !allEvaluationsTypesNeeded?.some(
      (ev) =>
        ev?.evaluationType === MaterialEvaluationTypes.OrthodonticEvaluation
    );
  }
);

export const selectPatientBasicInfoFormValues = createSelector(
  selectPatient,
  (patient) => {
    const shippingAddress = patient?.addresses?.find(
      (a) => a.addressType === 'Shipping Address'
    );
    const patientDob =
      (patient?.birthday && moment(patient.birthday, 'YYYY-MM-DD')) || null;

    const guardianDob =
      (patient?.legalGuardian?.birthday &&
        moment(patient?.legalGuardian?.birthday, 'YYYY-MM-DD')) ||
      null;

    return {
      firstName: patient?.firstName || '',
      lastName: patient?.lastName || '',
      email: patient?.user?.email || '',
      dateOfBirth: patientDob ? `${patientDob?.format('MM/DD/YYYY')}` : null,
      day: patientDob ? `${patientDob?.date()}` : '',
      month: patientDob ? `${patientDob?.month() + 1}` : '',
      year: patientDob ? `${patientDob?.year()}` : '',
      preferredName: patient?.preferredName || undefined,
      sex: patient?.sex || undefined,
      phone: patient?.phone
        ? formatPhoneNumber(patient?.phone).formatedPhoneNumber
        : undefined,
      middleName: patient?.middleName || undefined,
      practiceId: patient?.practice?.id || undefined,
      shippingAddress: {
        addressType: 'shipping',
        addressLine1: shippingAddress?.addressLine1 ?? '',
        addressLine2: shippingAddress?.addressLine2 ?? '',
        businessName: shippingAddress?.businessName ?? '',
        city: shippingAddress?.city ?? '',
        zip: shippingAddress?.zip ?? '',
        stateCode: shippingAddress?.stateCode,
      },
      legalGuardian: {
        firstName: patient?.legalGuardian?.firstName || '',
        middleName: patient?.legalGuardian?.middleName || '',
        lastName: patient?.legalGuardian?.lastName || '',
        preferredName: patient?.legalGuardian?.preferredName || '',
        phone: patient?.legalGuardian?.phone || '',
        birthday: guardianDob ? `${guardianDob.format('MM/DD/YYYY')}` : null,
        sex: patient?.legalGuardian?.sex || undefined,
      },
    };
  }
);

type SetterPayload<K extends keyof PatientState> = {
  key: K;
  value: PatientState[K];
};

const createGraphQLFetcher = <
  QueryType,
  VariableType extends OperationVariables,
>(
  actionType: string,
  query: DocumentNode,
  setter: (data: Maybe<QueryType>) => SetterPayload<keyof PatientState>,
  client: ApolloClientType = apolloClient
) =>
  createAsyncThunk(actionType, async (variables: VariableType, store) => {
    try {
      const { data } = await client.query<QueryType, VariableType>({
        query,
        variables,
      });

      if (setter) {
        store.dispatch(patientSlice.actions.fetched(setter(data)));
      }

      return data;
    } catch (e) {
      if (setter) {
        store.dispatch(patientSlice.actions.fetched(setter(null)));
      }
      throw e;
    }
  });

export const fetchCases = createGraphQLFetcher<
  GetCasesQuery,
  GetCasesQueryVariables
>(
  'patient/fetchCases',
  GetCasesDocument,
  (data) => {
    const cases = data?.getCases || initialState.cases;
    cases.sort(sortByCreated(Sort.Desc));
    return {
      key: 'cases',
      value: cases,
    };
  },
  coreClient
);

export const fetchTreatmentPlanStagings = createGraphQLFetcher<
  GetTreatmentPlanStagingsByCaseRefQuery,
  GetTreatmentPlanStagingsByCaseRefQueryVariables
>(
  'patient/fetchTreatmentPlanStagings',
  GetTreatmentPlanStagingsByCaseRefDocument,
  (data) => {
    const treatmentPlanStagings = data?.getTreatmentPlanStagingsByCaseRef || [];
    return {
      key: 'treatmentPlanStagings',
      value: treatmentPlanStagings,
    };
  },
  coreClient
);

export const fetchScans = createGraphQLFetcher<
  GetScansByCaseRefQuery,
  GetScansByCaseRefQueryVariables
>(
  'patient/fetchScans',
  GetScansByCaseRefDocument,
  (data) => {
    const scans = data?.getScanMaterialsByCaseRef || initialState.scans;
    scans.sort(sortByCreated(Sort.Desc));
    return {
      key: 'scans',
      value: scans,
    };
  },
  coreClient
);

export const fetchXrays = createGraphQLFetcher<
  GetXraysByCaseRefQuery,
  GetXraysByCaseRefQueryVariables
>(
  'patient/fetchXrays',
  GetXraysByCaseRefDocument,
  (data) => {
    const xrays = data?.getXrayMaterialsByCaseRef || initialState.xrays;
    xrays.sort(sortByCreated(Sort.Desc));
    return {
      key: 'xrays',
      value: xrays,
    };
  },
  coreClient
);

export const fetchPrismAggregates = createAsyncThunk(
  'patient/fetchPrismAggregates',
  async ({ caseRef }: PrismAggregatesQueryVariables, store) => {
    const { data } = await apolloClient.query<
      PrismAggregatesQuery,
      PrismAggregatesQueryVariables
    >({
      query: PrismAggregatesDocument,
      variables: {
        caseRef,
      },
    });

    const aggregates = data.prismAggregates ?? [];
    const sortedAggregates = [...aggregates].sort((a, b) =>
      sortByDate(a?.createdAt!, b?.createdAt!)
    );
    if (sortedAggregates) {
      store.dispatch(
        patientSlice.actions.fetched({
          key: 'prismAggregates',
          value: sortedAggregates,
        })
      );
    }
    const activeCaseRef = (
      store.getState() as RootState
    ).patient.patient?.cases?.find((c) => c?.isActive)?.caseRef;
    if (caseRef === activeCaseRef) {
      store.dispatch({
        type: 'patient/setProfilePhoto',
        payload: getProfilePhoto(sortedAggregates),
      });
    }
  }
);

export const fetchMaterials = createAsyncThunk(
  'patient/fetchMaterials',
  async (
    {
      caseRef,
      fetchBetaForms = false,
      formsToFetch = [],
    }: { caseRef: string; fetchBetaForms: boolean; formsToFetch: string[] },
    store
  ) => {
    if (!caseRef) {
      return;
    }
    store.dispatch(fetchScans({ caseRef }));
    store.dispatch(fetchXrays({ caseRef }));
    store.dispatch(fetchPrismAggregates({ caseRef }));
    store.dispatch(
      fetchTreatmentGoalQuestions({
        caseRef,
        fetchBetaForms,
        formsToFetch,
      })
    );
  }
);

type TransitionPrismSubbmissionThunkInput = TransitionSubmissionInput & {
  thunkOptions?: {
    refreshPrismAggregates?: boolean;
  };
};

export const transitionPrismSubmission = createAsyncThunk(
  'patient/transitionPrismSubmission',
  async (thunkInput: TransitionPrismSubbmissionThunkInput, store) => {
    const { thunkOptions, ...input } = thunkInput;
    const { data } = await apolloClient.mutate<
      TransitionPrismSubmissionMutation,
      TransitionPrismSubmissionMutationVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: TransitionPrismSubmissionDocument,
      variables: {
        input,
      },
    });

    // default to older functionality
    const refreshOnSuccess = thunkOptions?.refreshPrismAggregates ?? true;
    if (refreshOnSuccess) {
      const currentCase = selectActiveCase(store.getState() as RootState);
      const caseRef = currentCase?.caseRef;

      if (caseRef) {
        store.dispatch(fetchPrismAggregates({ caseRef }));
      }
    }

    return data;
  }
);

export const uploadPhoto = createAsyncThunk(
  'patient/uploadPhoto',
  async (
    {
      file,
      aggregateRef,
      view,
      newAggregateState,
    }: {
      file: File;
      aggregateRef: string;
      view: PhotoTypes;
      newAggregateState: any;
    },
    store
  ) => {
    const fileExtension = getValidFileExtension(file);
    const result = await apolloClient.mutate<
      CreatePrismPhotoMutation,
      CreatePrismPhotoMutationVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: CreatePrismPhotoDocument,
      variables: {
        input: {
          aggregateRef,
          photoType: view as PhotoTypes,
          fileExt: fileExtension,
        },
      },
    });

    if (result.errors) {
      // just throw the first error for simplicity, shouldn't have multiple errors anyway
      throw new Error(result.errors[0].message);
    }
    const photoResult = result.data?.createPrismPhoto;

    const { url, fields } = photoResult?.uploadData ?? {};
    const uploadData = new FormData();
    const parsedFields: {
      [key: string]: string;
    } = JSON.parse(fields);
    Object.entries(parsedFields).forEach(([key, value]) =>
      uploadData.append(key, value as string | Blob)
    );
    uploadData.append('file', file);

    await axios({
      method: 'POST',
      url: url!,
      data: uploadData,
      headers: {
        'Content-Type': file.type,
      },
    });

    store.dispatch(
      addPhoto({
        photo: photoResult?.photo!,
        aggregateRef,
        newAggregateState,
      })
    );
  }
);

export const uploadPhotos = createAsyncThunk(
  'patient/uploadPhotos',
  async (
    {
      caseRef,
      files,
      aggregateRef,
      photoStates,
      prismState,
    }: {
      caseRef: string;
      files: File[];
      aggregateRef: string;
      photoStates: { [key: string]: PhotoStates };
      prismState: string;
    },
    store
  ) => {
    const newPhotoStates = { ...photoStates };
    let missingPhotos = 0;
    let erroredPhoto = 0;
    //reset alert
    store.dispatch(setMissingPhotos({ numberMissingPhotos: 0 }));
    const updatedPhotos: string[] = [];
    const results = files.map(async (file) => {
      const fileExtension = getValidFileExtension(file);
      const fileName = file.name;

      const getPhotoResults = await apolloClient.query<
        GetPhotoUploadDataQuery,
        GetPhotoUploadDataQueryVariables
      >({
        query: GetPhotoUploadDataDocument,
        variables: {
          caseRef,
          fileName: fileName,
          fileExt: fileExtension,
        },
      });

      //If this failed to upload, note it, and move on to the next photo.
      if (getPhotoResults.errors) {
        erroredPhoto++;
        return;
      }

      const { url, fields, awsLocation } =
        getPhotoResults.data.getPhotoUploadData ?? {};

      const uploadData = new FormData();

      Object.entries(fields).forEach(([key, value]) =>
        uploadData.append(key, value as string | Blob)
      );
      uploadData.append('file', file);
      await axios({
        method: 'POST',
        url: url!,
        data: uploadData,
        headers: {
          'Content-Type': file.type,
        },
      });

      const classificationResult = await apolloClient.query<
        GetPhotoClassificationQuery,
        GetPhotoClassificationQueryVariables
      >({
        query: GetPhotoClassificationDocument,
        variables: {
          awsLocation: awsLocation!,
        },
      });

      if (classificationResult.errors) {
        //If we get an error here, we weren't able to classify, so skip and mark as missing
        missingPhotos++;
        return;
      }

      const photoType = classificationResult.data.getPhotoClassification;

      //If we have not added this photo to the array already
      if (!updatedPhotos.includes(photoType.name as string)) {
        updatedPhotos.push(photoType.name);
        newPhotoStates[photoType.name] = PhotoStates.UPLOADED;
        const result = await apolloClient.mutate<
          CreatePrismPhotoMlMutation,
          CreatePrismPhotoMlMutationVariables
        >({
          fetchPolicy: 'no-cache',
          mutation: CreatePrismPhotoMlDocument,
          variables: {
            input: {
              awsLocation: awsLocation!,
              aggregateRef,
              fileExt: fileExtension,
              photoType: photoType.name as PhotoTypes,
            },
          },
        });

        if (result.errors) {
          //Mark the photo as error and skip
          erroredPhoto++;
          return;
        }

        const photo = result.data?.createPrismPhotoMl?.photo;

        const newAggregateState = getNewPhotoAggregateState(
          prismState,
          newPhotoStates
        );

        store.dispatch(
          addPhoto({
            photo: photo!,
            aggregateRef,
            newAggregateState,
          })
        );
      } else {
        missingPhotos++;
      }
    });
    await Promise.all(results);

    if (missingPhotos > 0) {
      store.dispatch(setMissingPhotos({ numberMissingPhotos: missingPhotos }));
      Sentry.captureException('Unable to classify case ' + caseRef);
    }

    if (erroredPhoto) {
      throw new Error(
        'There was an issue uploading one or more photo. Please try again'
      );
    }
  }
);

const getNewPhotoAggregateState = (
  prismState: string,
  photoStates: { [key: string]: PhotoStates }
) => {
  const anyMissing = Object.values(photoStates).some(
    (ps) => ps === PhotoStates.MISSING
  );

  const anyRejected = Object.values(photoStates).some(
    (ps) => ps === PhotoStates.REJECTED
  );

  let newAggregateState = prismState;
  if (!anyMissing) {
    newAggregateState = LegacyMaterialStates.EVALUATION;
  } else if (anyRejected) {
    newAggregateState = LegacyMaterialStates.REJECTED;
  }

  return newAggregateState;
};

const getMaterialUploadData = async ({
  patientId,
  fileName,
}: {
  patientId: number;
  fileName: string;
}) => {
  const response = await coreClient.query<
    GetMaterialUploadDataQuery,
    GetMaterialUploadDataQueryVariables
  >({
    query: GetMaterialUploadDataDocument,
    variables: {
      patientId,
      fileName: cleanFilenameForAws(fileName),
    },
  });

  return {
    fields: response.data.getMaterialUploadData.fields as StringMap,
    url: response.data.getMaterialUploadData.url!,
    awsFileLocation: response.data.getMaterialUploadData.awsLocation ?? '',
  };
};

export const uploadScan = createAsyncThunk(
  'patient/uploadScan',
  async (
    {
      file,
      patientId,
      caseRef,
      scanType,
    }: {
      file: File;
      patientId: number;
      caseRef: string;
      scanType: ScanTypes;
    },
    store
  ) => {
    const { fields, url, awsFileLocation } = await getMaterialUploadData({
      patientId,
      fileName: file.name,
    });

    const data = new FormData();
    Object.entries(fields).forEach(([key, value]) => data.append(key, value));
    data.append('file', file);

    await axios({
      method: 'POST',
      url,
      data,
      headers: {
        'Content-Type': file.type,
      },
    });

    const { data: addScanData } = await coreClient.mutate<
      AddScanMutation,
      AddScanMutationVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: AddScanDocument,
      variables: {
        awsFileLocation,
        data: {
          captureDate: moment().format('YYYY-MM-DD'),
        },
        patientId,
        caseRef,
        scanType,
        filename: file.name,
      },
    });
    const scanResult = addScanData?.addScan?.scan;
    if (!scanResult || !scanResult.id) {
      throw Error('Problem adding scan, please refresh the page');
    }

    store.dispatch(addScans({ materials: [scanResult] as ScanFragment[] }));
  }
);

export const uploadXrays = createAsyncThunk(
  'patient/uploadXrays',
  async (
    {
      selectedFiles,
      patientId,
      caseRef,
      capturedWithinYearOfSubmission,
    }: {
      selectedFiles: FileList | File[];
      patientId: number;
      caseRef: string;
      capturedWithinYearOfSubmission: boolean;
    },
    store
  ) => {
    const fileArray =
      selectedFiles instanceof FileList
        ? Array.from(selectedFiles)
        : selectedFiles;
    const results = await Promise.all(
      fileArray.map(async (file) => {
        try {
          const { fields, url, awsFileLocation } = await getMaterialUploadData({
            patientId,
            fileName: file.name,
          });

          const data = new FormData();
          Object.entries(fields).forEach(([key, value]) =>
            data.append(key, value)
          );
          data.append('file', file);

          await axios({
            method: 'POST',
            url,
            data,
            headers: {
              'Content-Type': file.type,
            },
          });

          const { data: addXrayResult } = await coreClient.mutate<
            AddXrayMutation,
            AddXrayMutationVariables
          >({
            fetchPolicy: 'no-cache',
            mutation: AddXrayDocument,
            variables: {
              awsFileLocation,
              data: {
                capturedWithinYearOfSubmission,
              },
              patientId: Number(patientId),
              caseRef,
              filename: file.name,
            },
          });
          return {
            result: addXrayResult?.addXray?.xray!,
          };
        } catch (err) {
          return {
            error: err,
          };
        }
      })
    );

    // Regardless of any failures, we still want to add/show the
    // successful ones to ensure the case submission is successful and to
    // prevent orphaned materials without submissions.
    const selectedXrays = results
      .filter((r) => !!r.result)
      .map((r) => r.result);
    store.dispatch(addXrays({ materials: selectedXrays as XrayFragment[] }));

    const exceptions = results.filter((r) => !!r.error);

    if (exceptions.length) {
      throw new Error(
        'There was an issue uploading one or more files. Please try uploading those files again'
      );
    }
  }
);

export const removeMaterial = createAsyncThunk(
  'patient/removeMaterial',
  async ({ materialId }: { materialId: string }) => {
    await coreClient.mutate<
      RemoveMaterialMutation,
      RemoveMaterialMutationVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: RemoveMaterialDocument,
      variables: {
        materialId,
      },
    });
  }
);

export const uploadIntakeForms = createAsyncThunk(
  'patient/uploadIntakeForms',
  async ({
    patientId,
    intakeType,
    sections,
    version,
    isDraft,
    caseRef,
  }: {
    patientId: number;
    intakeType: IntakeTypes;

    sections: IntakeSectionInput[];
    version: number | undefined;

    isDraft: boolean;
    caseRef: string;
  }) => {
    await coreClient.mutate<
      SubmitIntakeMutation,
      SubmitIntakeMutationVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: SubmitIntakeDocument,
      variables: {
        patientId,
        intakeType,
        data: {
          sections,
          version,
        },
        isDraft,
        caseRef,
      },
    });
  }
);

const fetchQuestions = async (
  materialType: IntakeTypes,
  version: Maybe<number> | undefined,
  fetchBetaForms: boolean
) => {
  const { data } = await coreClient.query<GetFormQuery, GetFormQueryVariables>({
    query: GetFormDocument,
    variables: {
      materialType,
      version,
      beta: fetchBetaForms,
    },
  });
  return data;
};

type GetIntakeFormByCaseRefVariables = {
  caseRef: string;
  formsToFetch: string[];
  fetchBetaForms: boolean;
  refetchQuestions?: boolean;
};

export const fetchTreatmentGoalQuestions = createAsyncThunk(
  'patient/fetchTreatmentGoalQuestions',
  async (
    {
      caseRef,
      fetchBetaForms,
      refetchQuestions = true,
      formsToFetch,
    }: GetIntakeFormByCaseRefVariables,
    store
  ) => {
    const existingObjectives = await coreClient.query<
      GetLatestTreatmentObjectiveWithQuestionsQuery,
      GetLatestTreatmentObjectiveWithQuestionsQueryVariables
    >({
      query: GetLatestTreatmentObjectiveWithQuestionsDocument,
      variables: {
        caseRef,
      },
    });

    if (existingObjectives) {
      store.dispatch(
        patientSlice.actions.fetched({
          key: 'latestTreatmentObjective',
          value:
            existingObjectives.data.getLatestTreatmentObjectiveWithQuestions,
        })
      );
    }
    const existingForm = await coreClient.query<
      GetIntakeFormByCaseRefQuery,
      GetIntakeFormByCaseRefQueryVariables
    >({
      query: GetIntakeFormByCaseRefDocument,
      variables: {
        caseRef,
      },
    });

    if (existingForm) {
      store.dispatch(
        patientSlice.actions.fetched({
          key: 'intakeForms',
          value: existingForm.data.getIntakeMaterialsByCaseRef,
        })
      );
    }

    const latestForm =
      existingForm?.data?.getIntakeMaterialsByCaseRef[
        existingForm?.data?.getIntakeMaterialsByCaseRef.length - 1
      ];

    const name = latestForm?.materialType?.name;
    const latestVersion = latestForm?.data?.version;

    if (refetchQuestions && formsToFetch?.length > 0) {
      const fetchedForms = await Promise.all(
        formsToFetch.map((formName) => {
          const versionToFetch = name === formName ? latestVersion : undefined;
          return fetchQuestions(
            formName as IntakeTypes,
            versionToFetch,
            fetchBetaForms
          );
        })
      );

      const schemas = fetchedForms
        .filter((f) => f?.getForm)
        .map((f) => f?.getForm);

      store.dispatch(
        patientSlice.actions.fetched({
          key: 'treatmentGoalForms',
          value: schemas,
        })
      );
    }
  }
);

type PracticeOrder = Required<
  Omit<CreateOrderMutationVariables, 'patientId' | 'caseRef'>
>;
type PatientOrder = Required<CreateOrderMutationVariables>;

export const createOrder = createAsyncThunk(
  'patient/createOrder',
  async (input: PracticeOrder | PatientOrder) => {
    const response = await coreClient.mutate<
      CreateOrderMutation,
      CreateOrderMutationVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: CreateOrderDocument,
      variables: input,
    });

    return response;
  }
);

export const refreshCaseState = createAsyncThunk(
  'patient/refreshCaseState',
  async (variables: RefreshCaseStateMutationVariables) => {
    await coreClient.mutate<
      RefreshCaseStateMutation,
      RefreshCaseStateMutationVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: RefreshCaseStateDocument,
      variables,
    });
  }
);

type AddMaterialsPayload<T extends MaterialFragment> = {
  materials: T[];
};
type RemoveMaterialPayload = {
  materialId: string;
};

type AddClarificationRequestPayload = {
  clarificationRequest: ClarificationRequestsFragment;
  materialClassification: 'scan' | 'xray';
  caseRef?: string;
};

type AddPhotoPayload = {
  photo: PhotoTypeFieldsFragment;
  aggregateRef: string;
  newAggregateState: string;
};

type MissingPhotosPayload = {
  numberMissingPhotos: number;
};

const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    resetPatientState: () => initialState,
    setPatient(
      state: PatientState,
      action: PayloadAction<{ customer: CandidProCustomerQuery['customer'] }>
    ) {
      state.patient = action.payload.customer;
    },
    setUserInfo(state: any, action: PayloadAction<{ me: MeQuery['me'] }>) {
      state.userInfo = action.payload.me;
    },
    setSelectedCaseRef: (
      state: PatientState,
      action: PayloadAction<string>
    ) => {
      state.selectedCaseRef = action.payload;
    },
    setBasicInfo(state: PatientState, action: PayloadAction<BasicInfoSection>) {
      state.basicInfoState = { ...state.basicInfoState, ...action.payload };
    },
    updateScanUploadType(
      state: PatientState,
      action: PayloadAction<updateScanUploadTypePayload>
    ) {
      const cases =
        state?.cases?.map((c) => {
          if (c?.caseRef === action.payload.caseRef) {
            return {
              ...c,
              workflow: {
                ...c.workflow!,
                materialIntakeRequirements:
                  c?.workflow?.materialIntakeRequirements?.map((req) => {
                    if (req.name === 'scans') {
                      return {
                        ...req,
                        options: {
                          uploadSource: action.payload.newState,
                        },
                      };
                    } else {
                      return req;
                    }
                  }) || [],
              },
            };
          } else {
            return c;
          }
        }) || [];

      state.cases = cases;
    },
    setProfilePhoto: (state: PatientState, action: PayloadAction<string>) => {
      state.profilePhoto = action.payload;
    },
    setClarificationRequest: (
      state: PatientState,
      action: PayloadAction<AddClarificationRequestPayload>
    ) => {
      if (!action.payload.caseRef) {
        return;
      }
      //Update the material
      const { clarificationRequest, materialClassification } = action.payload;
      if (materialClassification === 'scan') {
        state.scans = state.scans.map((s) => ({
          ...s,
          clarificationRequests: s.clarificationRequests.map((cr) => {
            if (cr.id === clarificationRequest.id) {
              return clarificationRequest;
            }
            return cr;
          }),
        }));
      }
      if (materialClassification === 'xray') {
        state.xrays = state.xrays.map((x) => ({
          ...x,
          clarificationRequests: x.clarificationRequests.map((cr) => {
            if (cr.id === clarificationRequest.id) {
              return clarificationRequest;
            }
            return cr;
          }),
        }));
      }
      //Update the case state
      state.cases = state.cases.map((c) => {
        if (c.caseRef === action.payload.caseRef) {
          const caseState = c.caseState;

          if (!caseState) {
            return c;
          }

          const newState: MaterialState = {
            state: MaterialStates.AwaitingOrthodonticEvaluation,
            transition: StateTransitions.ProvideClarification,
          };

          if (materialClassification === 'scan') {
            caseState.scans = newState;
          }

          if (materialClassification === 'xray') {
            caseState.xrays = newState;
          }

          return {
            ...c,
            caseState: caseState,
          };
        } else {
          return c;
        }
      });
    },
    setPendingClarificationResponse: (
      state: PatientState,
      action: PayloadAction<NeedsClarificationInfo | null>
    ) => {
      state.pendingClarificationResponse = action.payload;
    },
    setPostSubmitPrismClarificationInfo: (
      state: PatientState,
      action: PayloadAction<NeedsClarificationInfo>
    ) => {
      state.postSubmitPrismClarificationInfo = action.payload;
    },
    addPhoto: (state, action: PayloadAction<AddPhotoPayload>) => {
      // we currently hack the aggregate state by passing in the expected state
      // see comments around hackyGetAggregateStatePostUploadPhoto
      const { photo, aggregateRef, newAggregateState } = action.payload;
      state.prismAggregates =
        state.prismAggregates?.map((agg) => {
          if (agg?.ref !== aggregateRef) {
            return agg;
          }
          const newAgg = {
            ...agg,
            photoSet: [photo, ...agg.photoSet],
          };
          if (newAgg.stateData?.data) {
            newAgg.stateData.data = newAggregateState;
          }
          return newAgg;
        }) || [];
    },
    updatePhotoType: (state, action: PayloadAction<UpdatePhotoPayload>) => {
      const { newPhotoTypep, aggregateRef, photoRef } = action.payload;
      state.prismAggregates =
        state.prismAggregates?.map((agg) => {
          if (agg?.ref !== aggregateRef) {
            return agg;
          }

          const newPhotoSet = agg.photoSet.map((photo) => {
            if (photo.ref !== photoRef) {
              return photo;
            }

            const newPhoto = {
              ...photo,
              photoType: {
                id: '',
                name: newPhotoTypep,
                label: '',
              },
            };
            return newPhoto;
          });
          const newAgg = {
            ...agg,
            photoSet: newPhotoSet,
          };
          return newAgg;
        }) || [];
    },
    setMissingPhotos: (state, action: PayloadAction<MissingPhotosPayload>) => {
      state.numberMissingPhotos = action.payload.numberMissingPhotos;
    },

    addScans: (
      state,
      action: PayloadAction<AddMaterialsPayload<ScanFragment>>
    ) => {
      const { materials } = action.payload;
      state.scans = [...materials, ...state.scans];
    },
    addXrays: (
      state,
      action: PayloadAction<AddMaterialsPayload<XrayFragment>>
    ) => {
      const { materials } = action.payload;
      state.xrays = [...materials, ...state.xrays];
    },
    removeScan: (state, action: PayloadAction<RemoveMaterialPayload>) => {
      const { materialId } = action.payload;
      state.scans = state.scans.filter((m) => m.id !== materialId);
    },
    removeXray: (state, action: PayloadAction<RemoveMaterialPayload>) => {
      const { materialId } = action.payload;
      state.xrays = state.xrays.filter((m) => m.id !== materialId);
    },
    setUnSubmittedTreatmentGoalsAreValid: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.areUnsubmittedTreatmentGoalsValid = action.payload;
    },
    setSelectedProduct: (state, action: PayloadAction<ProductTypes | null>) => {
      state.selectedProductType = action.payload;
    },
    setIsRefinementModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isRefinementModalOpen = action.payload;
    },
    setIsQualityTicketModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isQualityTicketModalOpen = action.payload;
    },
    setIsProductSelectionModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isProductSelectionModalOpen = action.payload;
    },
    fetched<K extends keyof PatientState>(
      state: PatientState,
      action: PayloadAction<SetterPayload<K>>
    ) {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCatalogItems.fulfilled, (state, action) => {
      state.orderCatalogs = action.payload;
    });
    builder.addCase(fetchOrders.fulfilled, (state, action) => {
      state.orderItems = action.payload;
    });
    builder.addCase(fetchPatientLastStepEligible.fulfilled, (state, action) => {
      state.isLastStepEligible = action.payload;
    });
    builder.addCase(
      fetchMostRecentlyApprovedTpStagingForPatient.fulfilled,
      (state, action) => {
        state.mostRecentlyApprovedTpStagingForPatient = action.payload;
      }
    );
  },
});

type UpdatePhotoPayload = {
  aggregateRef: string;
  photoRef: string;
  newPhotoTypep: string;
};

export const {
  resetPatientState,
  setSelectedCaseRef,
  setBasicInfo,
  updateScanUploadType,
  setClarificationRequest,
  setPostSubmitPrismClarificationInfo,
  setPendingClarificationResponse,
  updatePhotoType,
  addScans,
  addXrays,
  removeScan,
  removeXray,
  setUnSubmittedTreatmentGoalsAreValid: setUnsubmittedTreatmentGoalsAreValid,
  setSelectedProduct,
  setIsRefinementModalOpen,
  setIsQualityTicketModalOpen,
  setIsProductSelectionModalOpen,
  setUserInfo,
} = patientSlice.actions;

const { addPhoto, setMissingPhotos } = patientSlice.actions;

export const selectSectionsCanCollect = createSelector(
  selectActiveCase,
  (activeCase) =>
    activeCase?.workflow?.materialIntakeRequirements?.map(
      (requirement) => requirement.name
    ) || []
);

const selectCanCollectPhotos = createSelector(
  selectSectionsCanCollect,
  (sectionsCanCollect) => sectionsCanCollect.includes('photos')
);

const selectCanCollectScans = createSelector(
  selectSectionsCanCollect,
  (sectionsCanCollect) => sectionsCanCollect.includes('scans')
);

const selectCanCollectXrays = createSelector(
  selectSectionsCanCollect,
  (sectionsCanCollect) => sectionsCanCollect.includes('xrays')
);

export const selectCanCollectTreatmentGoals = createSelector(
  selectSectionsCanCollect,
  (sectionsCanCollect) => sectionsCanCollect.includes('treatment_goals')
);

export const selectHasMissingPhotoos = (state: RootState) =>
  state.patient.numberMissingPhotos;

const selectIntakeForms = (state: RootState) => state.patient.intakeForms;
const selectPrismAggregates = (state: RootState) =>
  state.patient.prismAggregates;

const selectTreatmentPlanStagings = (state: RootState) =>
  state.patient.treatmentPlanStagings;

export const selectAreUnsubmittedTreatmentGoalsValid = (state: RootState) =>
  state.patient.areUnsubmittedTreatmentGoalsValid;

const selectSortedIntakeForms = createSelector(
  selectIntakeForms,
  // need to create a copy of the array before sorting otherwise
  // you get a TypeError for altering a read only object
  (intakes) => ([...(intakes ?? [])] as Intake[]).sort(sortByCreated(Sort.Desc))
);

export const selectedTreatmentGoalFormsFromWorkflow = createSelector(
  selectActiveCase,
  (activeCase) => {
    const treatmentGoals =
      activeCase?.workflow?.materialIntakeRequirements?.find(
        (req) => req.name === 'treatment_goals'
      );

    if (!treatmentGoals) {
      return [];
    }

    //We should only need to submit one intake form for treatment goals
    //But we might have a choice in that one form
    const choices =
      treatmentGoals?.materialRequirements[0]?.materialTypeChoices;
    const ret: string[] =
      choices
        ?.map((c) => c ?? '')
        .filter((mat) => {
          if (mat) {
            return true;
          }
          return false;
        }) ?? [];
    return ret;
  }
);

const latestTreatmentObjective = (state: RootState) =>
  state.patient.latestTreatmentObjective;

export const selectLatestIntake = createSelector(
  selectSortedIntakeForms,
  (intakes) => intakes?.[0]
);

export const selectLatestIntakeOrTreatmentObjective = createSelector(
  selectSortedIntakeForms,
  latestTreatmentObjective,
  (intakes, treatmentObjective) => {
    if (treatmentObjective) {
      return treatmentObjective;
    }

    return intakes?.[0];
  }
);

export const selectTreatmentGoalsState = createSelector(
  selectLatestIntakeOrTreatmentObjective,
  (intake) => {
    if (!intake) {
      return LegacyMaterialStates.NOT_STARTED;
    }
    if (intake.isDraft) {
      return LegacyMaterialStates.UPLOADED;
    }

    return LegacyMaterialStates.SUBMITTED;
  }
);

export const selectAreTreatmentGoalsReadyForSubmit = createSelector(
  selectTreatmentGoalsState,
  selectCanCollectTreatmentGoals,
  (state, canCollectTreatmentGoals) =>
    canCollectTreatmentGoals ? state === LegacyMaterialStates.SUBMITTED : true
);

export const selectUnsubmittedTreatmentGoals = createSelector(
  selectAreTreatmentGoalsReadyForSubmit,
  selectLatestIntakeOrTreatmentObjective,
  (isMaterialCreated, intake) => {
    if (isMaterialCreated && intake?.submissions?.length === 0) {
      return intake;
    } else {
      return null;
    }
  }
);

const selectDoctorAccountPreferences = (state: RootState) => {
  return state.patient.userInfo?.accountPreferences?.doctor || {};
};

const selectDefaultProtocol = (state: RootState) => {
  return state.patient.userInfo?.accountPreferences?.doctor?.defaultProtocol;
};

export const selectTreatmentGoalForms = (state: RootState) => {
  return state.patient.treatmentGoalForms;
};

export const selectCompletedIntakeForms = createSelector(
  selectSortedIntakeForms,
  (intakes) => intakes.filter((intake) => !intake.isDraft)
);

export const selectDefaultTreatmentGoalValues = createSelector(
  selectDefaultProtocol,
  selectDoctorAccountPreferences,
  selectTreatmentGoalForms,

  (defaultProtocol, doctorPreferences, treatmentGoalForms) => {
    const initialValues: FormikValues = {};

    if (!defaultProtocol) {
      return initialValues;
    }

    treatmentGoalForms.forEach((form) => {
      form?.formSchema.forEach((section: FormSection) => {
        section?.questions?.forEach((question) => {
          if (question?.hasDefaultPreferenceOption) {
            const questionType = question.questionType!;
            const protocolPrefix = lowercaseAWord(section.label) as string;
            const currentQuestionKey =
              protocolPrefix +
              question
                ?.questionKey!.split(' ')
                .map((key) => capitalizeAWord(key))
                .join('');

            switch (questionType) {
              case QuestionTypes.Boolean: {
                const defaultPreference =
                  doctorPreferences[currentQuestionKey as keyof object];
                if (defaultPreference !== null) {
                  initialValues[
                    getAnswerFieldName(section.label, question?.questionKey!)
                  ] = doctorPreferences[currentQuestionKey as keyof object]
                    ? 'yes'
                    : 'no';
                }
                break;
              }
              case QuestionTypes.Choice:
              case QuestionTypes.Date:
              case QuestionTypes.Text:
                initialValues[
                  getExplanationFieldName(section.label, question?.questionKey!)
                ] = doctorPreferences[currentQuestionKey as keyof object];
                break;
            }
          }
        });
      });
    });
    return initialValues;
  }
);

export const selectStepCountFromMostRecentlyApprovedTpStaging = (
  state: RootState
) => state.patient.mostRecentlyApprovedTpStagingForPatient?.data?.steps;

export const selectLatestCaseWithApprovedTp = (state: RootState) => {
  const relevantCase = state.patient.cases.find(
    (c) =>
      c.caseRef ===
      state.patient.mostRecentlyApprovedTpStagingForPatient?.caseRef
  );
  return relevantCase;
};

export const selectTreatmentGoalsInitialValues = createSelector(
  selectTreatmentGoalForms,
  selectLatestIntake,
  selectDefaultTreatmentGoalValues,
  (forms, latestForm, defaultTreatmentGoalValues) => {
    const initialValues: FormikValues = {
      ...defaultTreatmentGoalValues,
    };

    const relevantForm = forms.find((f) => {
      return f?.materialType?.name === latestForm?.materialType?.name;
    });

    if (!relevantForm) {
      return initialValues;
    }

    latestForm.data.sections.forEach((section) => {
      const questionSection = relevantForm.formSchema.find(
        (f) => f.label === section.label
      );

      section.answers?.forEach((a) => {
        const questionType = questionSection?.questions.find(
          (q) => q.questionKey === a.questionKey
        )?.questionType!;

        switch (questionType) {
          case QuestionTypes.Boolean:
            if (a.answer !== null) {
              initialValues[
                getAnswerFieldName(section.label, a?.questionKey!)
              ] = a.answer ? 'yes' : 'no';
            }
            break;
          case QuestionTypes.Choice:
          case QuestionTypes.Date:
          case QuestionTypes.Text:
            initialValues[
              getExplanationFieldName(section.label, a?.questionKey!)
            ] = a.explanation;
            break;
          case QuestionTypes.ToothChart:
            initialValues[
              getListAnswerFieldName(section.label, a?.questionKey!)
            ] = a.listAnswer;
            break;
        }
      });
    });
    return initialValues;
  }
);

// HIC SUNT DRACONES, assuming only one aggregate per collection right
// now, this will need to change once new aggregates are added.
export const selectCurrentAggregate = createSelector(
  selectPrismAggregates,
  (aggregates) => aggregates?.[0]
);

export const selectCurrentSubmissionSet = createSelector(
  selectCurrentAggregate,
  (aggregate) =>
    [...(aggregate?.submissionSet || [])].sort(sortByCreated(Sort.Asc))
);

export const selectLastSubmission = createSelector(
  selectCurrentSubmissionSet,
  (submissionSet) => submissionSet[submissionSet.length - 1] ?? null
);

export const selectPhotoViewNames = createSelector(
  selectCurrentAggregate,
  (aggregate) =>
    splitList(
      aggregate?.aggregateType.requiredPhotoTypes.map(({ name }) => name) ?? [],
      (name) => name.endsWith('_w_aligners')
    )
);

export const selectCurrentPhotoSet = createSelector(
  selectCurrentAggregate,
  (aggregate) => aggregate?.photoSet ?? []
);

export const selectCurrentPhotosByPhotoType = createSelector(
  selectCurrentAggregate,
  (currentAggregate) => {
    // Group and sort all the uploaded photo to get the latest for each type
    const photoSet = currentAggregate?.photoSet || [];
    const photoMap = groupBy(
      [...photoSet].sort(sortByCreated(Sort.Desc)),
      (photo) => photo.photoType.name
    );
    type PhotoType = (typeof photoSet)[number];
    return Object.entries(photoMap).reduce(
      (acc, [photoType, photos]) => {
        // first item is the latest version
        acc[photoType] = photos[0];
        return acc;
      },
      {} as { [key: string]: PhotoType }
    );
  }
);

export const selectPatientProfilePicture = (state: RootState) =>
  state.patient.profilePhoto;

/**
 * There's no easy way to update the prism clarification provided state without
 * refetching the prism aggregates. This is slow and not an ideal user experience
 *
 * This object is only set after a clinician responds to clarificaiton for prism
 */
const selectPostSubmitPrismClarificationInfo = (state: RootState) =>
  state.patient.postSubmitPrismClarificationInfo;

export const selectPendingClarificationResponse = (state: RootState) =>
  state.patient.pendingClarificationResponse;

/**
 * Helper selector used to help determine the prism state in the prism state selector.
 */
export const selectPrismLastSubmissionState = createSelector(
  selectLastSubmission,
  selectPostSubmitPrismClarificationInfo,
  (lastSubmission, postSubmitPrismClarificationInfo) => {
    if (postSubmitPrismClarificationInfo) {
      // hack when user submits clarification, instead of refetching data for bad UX
      // we set this object for the local state to change
      return LegacyMaterialStates.CLARIFICATION_PROVIDED;
    }
    const prismSubmissionState = lastSubmission?.stateData?.data!;
    const submissionHistoryLength = lastSubmission?.stateData?.history?.length!;
    // greater than one means more than just the first submitted row in the history
    const submissionHadOrthoFeedback = submissionHistoryLength > 1;
    if (
      prismSubmissionState === LegacyMaterialStates.SUBMITTED &&
      submissionHadOrthoFeedback
    ) {
      const stateBeforeCurrent =
        lastSubmission?.stateData?.history?.[submissionHistoryLength - 2];
      if (
        stateBeforeCurrent?.data === LegacyMaterialStates.NEEDS_CLARIFICATION
      ) {
        return LegacyMaterialStates.CLARIFICATION_PROVIDED;
      }
    }
    return prismSubmissionState;
  }
);

export const selectHasPhotosForCaseSubmission = createSelector(
  selectLastSubmission,
  selectCurrentPhotosByPhotoType,
  (lastPrismSubmission, currentPhotosByPhotoType) => {
    return Object.values(currentPhotosByPhotoType).some(
      (photo) =>
        !lastPrismSubmission?.submissionItems.some(
          (item) => item.photo.ref === photo.ref
        )
    );
  }
);

export const selectPrismState = createSelector(
  selectCurrentAggregate,
  selectCurrentPhotosByPhotoType,
  selectPrismLastSubmissionState,
  selectHasPhotosForCaseSubmission,
  (
    currentAggregate,
    currentPhotosByPhotoType,
    lastPrismSubmissionState,
    hasPhotosToSubmit
  ) => {
    if (!currentAggregate?.photoSet.length) {
      return LegacyMaterialStates.NOT_STARTED;
    }
    // first check if the photos are rejected since the prism aggregate will be in
    // a collection state, we want to confirm it's not in collection due to rejected photos
    const isRejected = Object.values(currentPhotosByPhotoType).some(
      (photo) => photo.stateData?.data === LegacyMaterialStates.REJECTED
    );
    if (isRejected) {
      return LegacyMaterialStates.REJECTED;
    }
    // if the aggregate state is evaluation or collection, then the last submission
    // doesn't matter for determining state as a new submission is needed
    const currentAggregateState = currentAggregate.stateData?.data!;
    const preSubmissionAggregateStates = [
      LegacyMaterialStates.COLLECTION,
      LegacyMaterialStates.EVALUATION,
      LegacyMaterialStates.NOT_STARTED,
    ];

    if (
      preSubmissionAggregateStates.includes(
        currentAggregateState as (typeof preSubmissionAggregateStates)[number]
      )
    ) {
      return currentAggregateState;
    }
    // we've confirmed that we can use the submission as our state
    // so check if the current photos are different from the submitted photos
    // which means there are photos to submit and we can be in EVALUATION state
    if (hasPhotosToSubmit) {
      return LegacyMaterialStates.EVALUATION;
    }
    return lastPrismSubmissionState;
  }
);

export const selectArePhotosReadyForSubmit = createSelector(
  selectPrismState,
  (state) => !ClinicianActionStates.includes(state as ClinicianActionState)
);

/**
 * CORE XRAYS
 *
 * PLEASE REFACTOR THIS IN THE FUTURE!!!
 * We are inefficently requesting data and copying selectors
 */

export type XrayFragment =
  GetXraysByCaseRefQuery['getXrayMaterialsByCaseRef'][0];
export const selectXrays = (state: RootState) => state.patient.xrays;

export const selectUnsubmittedXrays = createSelector(selectXrays, (xrays) =>
  xrays.filter((xray) => !xray.submissions?.length)
);

export type SubmissionFragment = XrayFragment['submissions'][0];
type SubmissionMap = {
  [key: string]: SubmissionFragment;
};

// helper function to export until we find a good home for it
export const getSubmissionsFromXrays = (xrays: XrayFragment[]) => {
  const submissionMap: SubmissionMap = {};
  xrays.forEach((xray) => {
    xray.submissions.forEach((submission) => {
      if (!submissionMap[submission.id]) {
        submissionMap[submission.id] = submission;
      }
    });
  });
  const submissions = Object.values(submissionMap);
  submissions.sort(sortByCreated(Sort.Desc));
  return submissions;
};

export const selectXraySubmissions = createSelector(
  selectXrays,
  getSubmissionsFromXrays
);

const selectLatestXraySubmission = createSelector(
  selectXraySubmissions,
  (submissions) => submissions[0]
);

type ClarificationRequestsFragment = XrayFragment['clarificationRequests'][0];
type ClarificationRequestsMap = {
  [key: string]: ClarificationRequestsFragment;
};
const selectXrayClarificationRequests = createSelector(selectXrays, (xrays) => {
  const clarificationRequestsMap: ClarificationRequestsMap = {};
  xrays.forEach((xray) => {
    xray.clarificationRequests.forEach((clarification) => {
      if (!clarificationRequestsMap[clarification.id]) {
        clarificationRequestsMap[clarification.id] = clarification;
      }
    });
  });
  const clarificationRequests = Object.values(clarificationRequestsMap);
  clarificationRequests.sort(sortByCreated(Sort.Desc));
  return clarificationRequests;
});

const selectLatestXrayClarification = createSelector(
  selectXrayClarificationRequests,
  (clarificationRequests) => clarificationRequests[0]
);

export type MaterialEvaluationFragment =
  MaterialFragment['materialEvaluations'][0];
type MaterialEvaluationMap = {
  [key: string]: MaterialEvaluationFragment;
};

// helper function to export until we find a good home for it
const getEvaluationsFromXrays = (xrays: XrayFragment[]) => {
  const materialEvaluationMap: MaterialEvaluationMap = {};
  xrays.forEach((xray) => {
    xray.materialEvaluations.forEach((materialEvaluation) => {
      if (!materialEvaluationMap[materialEvaluation.id]) {
        materialEvaluationMap[materialEvaluation.id] = materialEvaluation;
      }
    });
  });
  const materialEvaluations = Object.values(materialEvaluationMap);
  materialEvaluations.sort(sortByCreated(Sort.Desc));
  return materialEvaluations;
};

const selectXrayMaterialEvaluations = createSelector(
  selectXrays,
  getEvaluationsFromXrays
);

export type MaterialEvaluationSetFragment = NonNullable<
  MaterialFragment['materialEvaluations'][0]['evaluationSet']
>;
type MaterialEvaluationSetMap = {
  [key: string]: MaterialEvaluationSetFragment;
};

// gets the evaluations from the latest evaluation set
// TODO change our data fetching and resolvers so that
// a lot of this massaging is on the backend
const selectMaterialEvaluationSets = createSelector(
  selectXrayMaterialEvaluations,
  (materialEvaluations) => {
    const materialEvaluationSetMap: MaterialEvaluationSetMap = {};
    materialEvaluations.forEach((materialEvaluation) => {
      const evalSet = materialEvaluation.evaluationSet;
      if (!!evalSet && !materialEvaluationSetMap[evalSet.id]) {
        materialEvaluationSetMap[evalSet.id!] = evalSet;
      }
    });
    const materialEvaluationSets = Object.values(materialEvaluationSetMap);
    materialEvaluationSets.sort(sortByCreated(Sort.Desc));
    return materialEvaluationSets;
  }
);

export const selectLatestXrayMaterialEvaluationSet = createSelector(
  selectMaterialEvaluationSets,
  (materialEvaluationSets) => materialEvaluationSets[0]
);

export const selectLatestXrayMaterialEvaluations = createSelector(
  selectXrayMaterialEvaluations,
  selectLatestXrayMaterialEvaluationSet,
  (materialEvaluations, latestMaterialEvaluationSet) => {
    if (!latestMaterialEvaluationSet) {
      return [];
    }
    return materialEvaluations.filter(
      (evals) => latestMaterialEvaluationSet.id === evals.evaluationSet?.id
    );
  }
);

export const selectXrayMaterialState = createSelector(
  selectSelectedCaseState,
  selectLatestXraySubmission,
  selectPendingClarificationResponse,
  (caseState, latestXraySubmission, pendingClarification) => {
    if (!caseState?.xrays) {
      return null;
    }
    // Temp workaround, due to xrays state not being reverted to UPLOADED on client deletion
    // Before a case is submitted we need to check if the latest xray submission were rejected
    if (
      caseState.providerFacing === ProviderFacingStates.ACTION_NEEDED &&
      latestXraySubmission?.history?.[0].action === MaterialActionTypes.Rejected
    ) {
      return {
        ...caseState.xrays,
        transition: StateTransitions.Reject,
      };
    }

    //If there's a pending unsubmitted clarification request, mark the state as uploaded
    //We need to do this, because while needs clarification has been saved, it hasn't been submitted
    //until the case is submitted
    if (
      caseState.xrays.state === MaterialStates.NeedsClarification &&
      !!pendingClarification?.scanClarificationRequestId
    ) {
      return {
        state: MaterialStates.Uploaded,
        transition: StateTransitions.ProvideClarification,
      };
    }
    return caseState.xrays;
  }
);

export const selectAreXraysReadyToSubmit = createSelector(
  selectSelectedCase,
  selectXrayMaterialState,
  selectUnsubmittedXrays,
  selectLatestXraySubmission,
  (selectedCase, xrayMaterialState, unsubmittedXrays, latestXraySubmission) => {
    return _isMaterialReadyToSubmit({
      selectedCase,
      materialState: xrayMaterialState,
      unsubmittedMaterials: unsubmittedXrays,
      materialName: 'xrays',
      latestMaterialSubmission: latestXraySubmission,
    });
  }
);

/**
 * CORE SCANS
 *
 * PLEASE REFACTOR THIS IN THE FUTURE!!!
 * We are inefficently requesting data and copying selectors
 */

export type ScanFragment =
  GetScansByCaseRefQuery['getScanMaterialsByCaseRef'][0];
export const selectScans = (state: RootState) => state.patient.scans;

export const selectUnsubmittedScans = createSelector(selectScans, (scans) =>
  scans.filter((scan) => !scan.submissions?.length)
);

// helper function to export until we find a good home for it
const getSubmissionsFromMaterials = (materials: MaterialFragment[]) => {
  const submissionMap: SubmissionMap = {};
  materials.forEach((material) => {
    material.submissions.forEach((submission) => {
      if (!submissionMap[submission.id]) {
        submissionMap[submission.id] = submission;
      }
    });
  });
  const submissions = Object.values(submissionMap);
  submissions.sort(sortByCreated(Sort.Desc));
  return submissions;
};

export const selectScanSubmissions = createSelector(
  selectScans,
  getSubmissionsFromMaterials
);

export const selectLatestScanSubmission = createSelector(
  selectScanSubmissions,
  (submissions) => submissions[0]
);

const selectScanClarificationRequests = createSelector(selectScans, (scans) => {
  const clarificationRequestsMap: ClarificationRequestsMap = {};
  scans.forEach((scan) => {
    scan.clarificationRequests.forEach((clarification) => {
      if (!clarificationRequestsMap[clarification.id]) {
        clarificationRequestsMap[clarification.id] = clarification;
      }
    });
  });
  const clarificationRequests = Object.values(clarificationRequestsMap);
  clarificationRequests.sort(sortByCreated(Sort.Desc));
  return clarificationRequests;
});

const selectLatestScanClarification = createSelector(
  selectScanClarificationRequests,
  (clarificationRequests) => clarificationRequests[0]
);

// helper function to export until we find a good home for it
const getEvaluationsFromScans = (scans: ScanFragment[]) => {
  const materialEvaluationMap: MaterialEvaluationMap = {};
  scans.forEach((scan) => {
    scan.materialEvaluations.forEach((materialEvaluation) => {
      if (!materialEvaluationMap[materialEvaluation.id]) {
        materialEvaluationMap[materialEvaluation.id] = materialEvaluation;
      }
    });
  });
  const materialEvaluations = Object.values(materialEvaluationMap);
  materialEvaluations.sort(sortByCreated(Sort.Desc));
  return materialEvaluations;
};

const selectScanMaterialEvaluations = createSelector(
  selectScans,
  getEvaluationsFromScans
);

// gets the evaluations from the latest evaluation set
// TODO change our data fetching and resolvers so that
// a lot of this massaging is on the backend
const selectScanMaterialEvaluationSets = createSelector(
  selectScanMaterialEvaluations,
  (materialEvaluations) => {
    const materialEvaluationSetMap: MaterialEvaluationSetMap = {};
    materialEvaluations.forEach((materialEvaluation) => {
      const evalSet = materialEvaluation.evaluationSet;
      if (!!evalSet && !materialEvaluationSetMap[evalSet.id]) {
        materialEvaluationSetMap[evalSet.id!] = evalSet;
      }
    });
    const materialEvaluationSets = Object.values(materialEvaluationSetMap);
    materialEvaluationSets.sort(sortByCreated(Sort.Desc));
    return materialEvaluationSets;
  }
);

export const selectLatestScanMaterialEvaluationSet = createSelector(
  selectScanMaterialEvaluationSets,
  (materialEvaluationSets) => materialEvaluationSets[0]
);

export const selectLatestScanMaterialEvaluations = createSelector(
  selectScanMaterialEvaluations,
  selectLatestScanMaterialEvaluationSet,
  (materialEvaluations, latestMaterialEvaluationSet) => {
    if (!latestMaterialEvaluationSet) {
      return [];
    }
    return materialEvaluations.filter(
      (evals) => latestMaterialEvaluationSet.id === evals.evaluationSet?.id
    );
  }
);

export const selectScanMaterialState = createSelector(
  selectSelectedCaseState,
  selectLatestScanSubmission,
  selectPendingClarificationResponse,
  (caseState, latestScanSubmission, pendingClarification) => {
    if (!caseState?.scans) {
      return null;
    }
    // Temp workaround, due to scans state not being reverted to UPLOADED on client deletion
    // Before a case is submitted we need to check if the latest scan submission were rejected
    if (
      caseState.providerFacing === ProviderFacingStates.ACTION_NEEDED &&
      latestScanSubmission?.history?.[0].action === MaterialActionTypes.Rejected
    ) {
      return {
        ...caseState.scans,
        transition: StateTransitions.Reject,
      };
    }

    //If there's a pending unsubmitted clarification request, mark the state as uploaded
    //We need to do this, because while needs clarification has been saved, it hasn't been submitted
    //until the case is submitted
    if (
      caseState.scans.state === MaterialStates.NeedsClarification &&
      !!pendingClarification?.scanClarificationRequestId
    ) {
      return {
        state: MaterialStates.Uploaded,
        transition: StateTransitions.ProvideClarification,
      };
    }
    return caseState.scans;
  }
);

export const selectScansUploadType = createSelector(
  selectSelectedCase,
  (selectedCase) => {
    const req = selectedCase?.workflow?.materialIntakeRequirements.find(
      (req) => req.name === 'scans'
    );
    return req?.options?.uploadSource ?? WorkflowUploadSources.Provider;
  }
);

export const selectAreScansReadyToSubmit = createSelector(
  selectSelectedCase,
  selectScanMaterialState,
  selectUnsubmittedScans,
  selectLatestScanSubmission,
  selectScansUploadType,
  (
    selectedCase,
    scanMaterialState,
    unsubmittedScans,
    latestScanSubmission,
    scanUploadType
  ) => {
    return (
      scanUploadType !== WorkflowUploadSources.Provider ||
      _isMaterialReadyToSubmit({
        selectedCase,
        materialState: scanMaterialState,
        unsubmittedMaterials: unsubmittedScans,
        materialName: 'scans',
        latestMaterialSubmission: latestScanSubmission,
      })
    );
  }
);

const _isMaterialReadyToSubmit = ({
  selectedCase,
  materialState,
  unsubmittedMaterials,
  materialName,
  latestMaterialSubmission,
}: {
  selectedCase?: CoreCase;
  materialState?: MaterialState | null;
  unsubmittedMaterials?: SubmittableMaterialTypes[];
  materialName: string;
  latestMaterialSubmission: SubmissionFragment;
}) => {
  if (!materialState) {
    return false;
  }

  const materialRequirements =
    selectedCase?.workflow?.materialIntakeRequirements.find(
      ({ name }) => name === materialName
    )?.materialRequirements || [];

  const requiredMaterialTypes = materialRequirements
    .filter(
      (materialRequirement) => materialRequirement?.options?.optional === false
    )
    .flatMap((materialRequirement) => materialRequirement?.materialTypeChoices);

  // Check if materials need clarification
  if (materialState.state === MaterialStates.NeedsClarification) {
    return false;
  }

  if (materialState.state === MaterialStates.Completed) {
    // Material has previously been submitted and accepted, so we don't need to check unsubmitted materials
    return true;
  }

  // Clarifcation request has already been responded to by the provider and no further action is needed
  if (
    unsubmittedMaterials?.length === 0 &&
    latestMaterialSubmission?.history?.[0].action ===
      MaterialActionTypes.ClarificationProvided
  ) {
    return true;
  }

  //Material wasn't submitted/edited but clarification was still provided
  if (
    unsubmittedMaterials?.length === 0 &&
    materialState.state === MaterialStates.Uploaded &&
    materialState.transition === StateTransitions.ProvideClarification
  ) {
    return true;
  }

  // Material has been previously submitted by the lab/support, prior to case submission
  if (
    unsubmittedMaterials?.length === 0 &&
    latestMaterialSubmission?.history?.[0].action ===
      MaterialActionTypes.Submitted
  ) {
    return true;
  }

  //If we're uploading (from not started OR rejection) verify that every required material is present in the unsubmitted materials
  if (
    materialState.state === MaterialStates.Uploaded ||
    materialState.state === MaterialStates.NotStarted
  ) {
    return requiredMaterialTypes.every((requiredMaterialType) =>
      unsubmittedMaterials?.some(
        (unsubmittedMaterial) =>
          unsubmittedMaterial?.materialType?.name === requiredMaterialType
      )
    );
  }
  return true;
};

/**
 * GENERAL SELECTORS
 * (selectors that use a combination of the above selectors)
 */

export type MaterialFragment = ScanFragment | XrayFragment;

export type NeedsClarificationInfo = {
  xrayClarificationRequestId?: string;
  scanClarificationRequestId?: string;
  prismSubmissionRef?: string;
  attachments?: FileInfo[];
  question: string;
  createdAt: string;
  responseInfo?: {
    response: string;
    respondedAt?: string | null;
    respondedByEmail?: string | null;
  };
};

// NOTE!!: do not use this directly, use selectClarificationRequestInfo
const selectXrayClarificationInfo = createSelector(
  selectLatestXrayClarification,
  (latestXrayClarification) => {
    if (!latestXrayClarification) {
      return null;
    }
    return {
      xrayClarificationRequestId: latestXrayClarification.id,
      question: latestXrayClarification.question,
      createdAt: latestXrayClarification.createdAt,
      responseInfo: latestXrayClarification.response
        ? {
            response: latestXrayClarification.response,
            respondedAt: latestXrayClarification.respondedAt,
            respondedByEmail: latestXrayClarification.respondedByEmail,
          }
        : undefined,
    } as NeedsClarificationInfo;
  }
);

// NOTE!!: do not use this directly, use selectClarificationRequestInfo
const selectScanClarificationInfo = createSelector(
  selectLatestScanClarification,
  (latestScanClarification) => {
    if (!latestScanClarification) {
      return null;
    }
    return {
      scanClarificationRequestId: latestScanClarification.id,
      attachments: latestScanClarification.attachments,
      question: latestScanClarification.question,
      createdAt: latestScanClarification.createdAt,
      responseInfo: latestScanClarification.response
        ? {
            response: latestScanClarification.response,
            respondedAt: latestScanClarification.respondedAt,
            respondedByEmail: latestScanClarification.respondedByEmail,
          }
        : undefined,
    } as NeedsClarificationInfo;
  }
);

// NOTE!!: do not use this directly, use selectClarificationRequestInfo
const selectPrismClarificationInfo = createSelector(
  selectPrismLastSubmissionState,
  selectLastSubmission,
  selectPostSubmitPrismClarificationInfo,
  (
    prismSubmissionState,
    lastPrismSubmission,
    postSubmitPrismClarificationInfo
  ) => {
    if (postSubmitPrismClarificationInfo) {
      // hack when user submits clarification, instead of refetching data for bad UX
      // we set this object for the local state to change
      return postSubmitPrismClarificationInfo;
    }
    const isNeedsOrProvidedClarification =
      prismSubmissionState === LegacyMaterialStates.NEEDS_CLARIFICATION ||
      prismSubmissionState === LegacyMaterialStates.CLARIFICATION_PROVIDED;

    if (!isNeedsOrProvidedClarification || !lastPrismSubmission?.stateData) {
      return null;
    }
    // default to needs clarification
    const history = lastPrismSubmission?.stateData.history!;
    const currentStateData = history[history.length - 1];
    let question = currentStateData.extra?.rejectionNotes || '';
    let response;
    let respondedAt;
    let respondedByEmail;
    // update if clarification provided
    if (prismSubmissionState === LegacyMaterialStates.CLARIFICATION_PROVIDED) {
      const needsClarificationStateData = history[history.length - 2];
      question = needsClarificationStateData.extra?.rejectionNotes || '';
      response = currentStateData.extra?.rejectionNotes;
      respondedAt = currentStateData.created;
      respondedByEmail = currentStateData.user?.email;
    }
    return {
      prismSubmissionRef: lastPrismSubmission.ref,
      question,
      createdAt: currentStateData.created,
      responseInfo: response
        ? {
            response,
            respondedAt,
            respondedByEmail,
          }
        : undefined,
    } as NeedsClarificationInfo;
  }
);

/**
 * Provides the latest clarification info and doesn't take state into account
 * Clarification requests are each separate http requests and objects but they
 * have the same question / response so we need to combine them into one object.
 */
export const selectClarificationRequestInfo = createSelector(
  selectPrismClarificationInfo,
  selectScanClarificationInfo,
  selectXrayClarificationInfo,
  (prismClarificationInfo, scanClarificationInfo, xrayClarificationInfo) => {
    const clarificationInfos: Array<NeedsClarificationInfo | null> = [
      scanClarificationInfo,
      prismClarificationInfo,
      xrayClarificationInfo,
    ];

    // Filter out null values and sort by descending before reducing.
    // This way, can be confident that the first item is the latest and we should try to merge into it
    return clarificationInfos
      .filter((info) => Boolean(info))
      .sort((a, b) => {
        const aDate = moment(a!.createdAt);
        const bDate = moment(b!.createdAt);
        // sort by descending
        return aDate.isAfter(bDate) ? -1 : 1;
      })
      .reduce(
        (acc, currentValue) => {
          if (!acc) {
            return currentValue;
          }
          // if the question and response are the same, we can assume it's the same clarification request info
          const isEqual =
            acc.question === currentValue!.question &&
            acc.responseInfo?.response === currentValue!.responseInfo?.response;
          if (!isEqual) {
            return acc;
          }
          return {
            ...acc,
            ...currentValue,
          };
        },
        null as NeedsClarificationInfo | null
      );
  }
);

/*
 * Returns case can be submitted.
 *
 * If the case has not been submitted yet, all matereials are required to be submitted.
 * If a case has been rejected, make sure all rejected mateials are being submitted. Other materials are optional
 */
export const selectAreDiagnosticMaterialsReadyForSubmit = createSelector(
  selectActiveCase,
  selectArePhotosReadyForSubmit,
  selectAreScansReadyToSubmit,
  selectAreXraysReadyToSubmit,
  selectCanCollectPhotos,
  selectCanCollectScans,
  selectCanCollectXrays,
  (
    activeCase,
    photosValid,
    scansValid,
    xraysValid,
    canCollectPhotos,
    canCollectScans,
    canCollectXrays
  ) => {
    if (!activeCase) {
      return false;
    }

    const photosReady = !canCollectPhotos || photosValid;
    const scansReady = !canCollectScans || scansValid;
    const xraysReady = !canCollectXrays || xraysValid;
    return photosReady && scansReady && xraysReady;
  }
);

export const selectSortedTreatmentPlanStagings = createSelector(
  selectTreatmentPlanStagings,
  (plans) => {
    if (!plans) {
      return [] as TreatmentPlanStaging[];
    }

    return [...plans].sort(
      (p2, p1) =>
        new Date(p2.createdAt).getTime() - new Date(p1.createdAt).getTime()
    ) as TreatmentPlanStaging[];
  }
);

const getCatalogItems = createAsyncThunk(
  'patient/getOrderCatalog',
  getOrderCatalog
);

export const selectProviderFacingStatus = createSelector(
  selectSelectedCase,
  (selectedCase) => selectedCase?.caseState?.providerFacing
);

export const selectSelectedCaseMaterials = createSelector(
  selectSelectedCase,
  (selectedCase) => {
    const { materialIntakeRequirements = [], productionRequirements = [] } =
      selectedCase?.workflow || {};
    const caseMaterials =
      [...materialIntakeRequirements, ...productionRequirements].map(
        (requirement) => requirement.name
      ) || [];

    return caseMaterials;
  }
);

export const selectAlignerTrackingLink = createSelector(
  selectOrderItems,
  (orderItems) => {
    const alignerOrderItem = orderItems?.find(
      (orderItem) => orderItem?.product_type === 'ALIGNER_GOOD'
    );
    const { carrier, tracking_id } = alignerOrderItem?.shipment ?? {};

    return getCarrierTrackingUrl(carrier as string, tracking_id as string);
  }
);

export const selectLatestTreatmentPlanStaging = createSelector(
  selectSortedTreatmentPlanStagings,
  (treatmentPlans) => {
    return treatmentPlans?.[treatmentPlans.length - 1];
  }
);

export const selectSubsequentCase = createSelector(
  selectCases,
  selectSelectedCase,
  (cases, selectedCase) => {
    if (!selectedCase) {
      return null;
    }
    const { caseRef } = selectedCase;
    const subsequentCases = cases.find((c) => caseRef === c?.precedingCaseRef);
    return subsequentCases;
  }
);

export const selectIsSubmitted = createSelector(
  selectActiveCase,
  (activeCase) =>
    activeCase?.caseState?.providerFacing ===
    ProviderFacingStates.MATERIALS_UNDER_REVIEW
);

export const selectCanEditSubmission = createSelector(
  selectActiveCase,
  (activeCase) => {
    if (!activeCase?.caseState?.providerFacing) {
      return false;
    }
    return [
      ProviderFacingStates.ACTION_NEEDED,
      ProviderFacingStates.INCOMPLETE_SUBMISSION,
    ].includes(activeCase.caseState.providerFacing as ProviderFacingStates);
  }
);

// note only works for retainer cases
export const selectIsRetainerCaseWithOrderInfo = createSelector(
  selectActiveCase,
  (activeCase) =>
    // hack to check if we've submitted before which means we don't need to checkout again
    // and so we only want to resubmit the case -- only care about for retainer cases
    activeCase &&
    activeCase.caseType.name === 'retainer' &&
    activeCase.orderInfo
);

export const selectSkipCaseSummary = createSelector(
  selectActiveCase,
  selectCanCollectTreatmentGoals,
  (activeCase, treatmentGoalRequired) =>
    // currently have to hack this by checking the case type
    // since the case tasks include basic info we can't just rely on workflow
    // to determine which tasks to show.
    activeCase?.caseType?.name === CaseTypeNames.RETAINER &&
    !treatmentGoalRequired
);

export const selectSkipToCheckoutPage = createSelector(
  selectActiveCase,
  selectCanCollectTreatmentGoals,
  (activeCase, treatmentGoalRequired) =>
    // THIS DOESNT WORK FOR ALIGNERS THAT ARE ORDERING LAST STEP RETAINERS
    // this is meant as a stop gap until we make the flow more consistent
    // for all case types
    // currently have to hack this by checking the case type
    // TODO: replace 'retainer' with graphql enum when available
    activeCase?.caseType?.name === 'retainer' && !treatmentGoalRequired
);

export const selectAlertStatus = createSelector(
  selectSelectedCase,
  (selectedCase) => {
    let alertStatus = null;

    // Use the selected case's workflow to determine what materials are required
    const requiredMaterialNames =
      selectedCase?.workflow?.materialIntakeRequirements?.map(
        (materialIntakeRequirement) =>
          snakeCaseToCamelCase(materialIntakeRequirement?.name)
      ) || [];

    // Check for any required materials that are in a rejected or needs clarification state and set appropriate alert status
    requiredMaterialNames?.forEach((requiredMaterialName) => {
      if (requiredMaterialName in (selectedCase?.caseState || {})) {
        const material: MaterialState =
          selectedCase!.caseState![
            requiredMaterialName as keyof CoreCase['caseState']
          ];
        if (material.transition === StateTransitions.Reject) {
          alertStatus = AlertTypeEnum.Critical;
        }

        if (material.state === MaterialStates.NeedsClarification) {
          alertStatus = AlertTypeEnum.Warning;
        }
      }
    });

    return alertStatus;
  }
);

export const selectShortName = createSelector(selectPatient, (patient) => {
  if (!patient) {
    return '';
  }
  const [firstInitial] = patient.firstName;
  return `${firstInitial}. ${patient.lastName}`;
});

export const selectIsLastStepEligible = (state: RootState) =>
  state.patient.isLastStepEligible;

export const selectActiveCaseScanTypes = createSelector(
  selectActiveCase,
  (activeCase) => {
    const scanRequirements =
      activeCase?.workflow?.materialIntakeRequirements.find(
        (req) => req.name === 'scans'
      );

    const result = scanRequirements?.materialRequirements.flatMap((req) => ({
      name: req!.materialTypeChoices[0] as ScanTypes,
      optional: req!.options?.optional,
    }));

    return result || [];
  }
);

export const selectActiveCaseHasApprovedTP = createSelector(
  selectActiveCase,

  (activeCase) => {
    return (
      activeCase?.isActive &&
      activeCase.caseState?.treatmentPlanStaging?.state ===
        MaterialStates.Completed
    );
  }
);

export const selectSelectedProductType = (state: RootState) =>
  state.patient.selectedProductType;

export const selectIsRefinementModalOpen = (state: RootState) =>
  state.patient.isRefinementModalOpen;

export const selectIsQualityTicketModalOpen = (state: RootState) =>
  state.patient.isQualityTicketModalOpen;

export const selectIsProductSelectionModalOpen = (state: RootState) =>
  state.patient.isProductSelectionModalOpen;

export const selectIsPatientElgiibleForRefinement = createSelector(
  selectActiveCase,
  (activeCase) => {
    if (!activeCase) {
      return true;
    }

    if (
      activeCase.caseState?.providerFacing ===
        ProviderFacingStates.IN_TREATMENT ||
      activeCase.caseState?.providerFacing ===
        ProviderFacingStates.IN_RETENTION ||
      activeCase.caseState?.providerFacing ===
        ProviderFacingStates.ALIGNER_KIT_DELIVERED
    ) {
      return true;
    }
    return false;
  }
);

export const selectReplacementAlignerFormLink = createSelector(
  selectPatient,
  (patient) => {
    return `https://formfacade.com/public/113606064810223952308/all/form/1FAIpQLSfdhC6cWGKFuy1-qPVfh4TbC0qC0yokiwH3pJxm_pbKqnB4oQ?emailAddress=${
      patient?.referringDentist?.email
    }&entry.1081703492=${patient?.practice?.name}&entry.433797451=${
      patient?.referringDentist?.fullName
    }&entry.402285835=${patient?.firstName} ${
      patient?.lastName
    }&entry.790415107=${moment(patient?.birthday?.toString()).format(
      'YYYY-MM-DD'
    )}`;
  }
);

export const selectPatientDoctorPreferences = createSelector(
  selectPatient,
  (patient) => {
    return patient?.referringDentist?.user?.accountPreferences?.doctor;
  }
);
export default patientSlice.reducer;
