import styled from 'styled-components/macro';
import { css } from 'styled-components';

export const TabContent = styled.div<{ isVisible: boolean }>`
  ${({ isVisible }) =>
    !isVisible &&
    css`
      display: none;
    `};
`;

export const TabButton = styled.button<{ isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  padding: 16px 0;
  gap: 12px;
  line-height: 1.5rem;
  ${({ isSelected, theme }) =>
    isSelected
      ? css`
          border-bottom: 2px solid ${theme.colors.text90};
          > svg > path {
            stroke: ${theme.colors.text90};
          }
        `
      : css`
          color: ${theme.colors.text60};
          > svg > path {
            stroke: ${theme.colors.black60};
          }
        `}
`;

export const TabsContainer = styled.nav`
  display: flex;
  width: 100%;
  gap: 32px;
  padding:;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    gap: 16px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
