import React, { useContext, useEffect, useState } from 'react';
import { Grid, NotificationContext } from 'core/components';
import { Link } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import ChevronRightSVG from 'assets/ic_chevron-right.svg?react';
import {
  ContainerBody,
  ContainerHeader,
  ErrorMessage,
  MainText,
  Note,
  StyledTable,
  SubText,
} from 'components/Modals/OrderShippingModal/OrderShipping.css';
import { Skeleton } from 'components/Modals/OrderShippingModal/Skeleton';
import {
  CoreShippingDetails,
  OrderItemsWithShipment,
  OrderShippingProps,
} from 'components/Modals/OrderShippingModal/types';
import { useGQLQuery } from 'hooks/useGQL';
import {
  GetShipmentForOrderItemDocument,
  GetShipmentForOrderItemQuery,
  GetShipmentForOrderItemQueryVariables,
  GetReturnShipmentForOrderItemDocument,
  GetReturnShipmentForOrderItemQuery,
  GetReturnShipmentForOrderItemQueryVariables,
  OrderStatus,
} from 'generated/core/graphql';
import Sidebar from 'components/Modals/OrderShippingModal/Sidebar';
import api from 'state/api';

const OrderShipping = ({
  internal = false,
  caseRef,
  orderItems = [],
  incitingOrderItemRef,
  patientName = '',
}: OrderShippingProps) => {
  const isOrthoPrism = window.location.pathname.includes('/ortho-prism');
  const { showNotification } = useContext(NotificationContext);
  const [isFetchingShipment, setIsFetchingShipment] = useState<boolean>(false);
  const [fetchedOrderItems, setFetchedOrderItems] = useState<
    Array<OrderItemsWithShipment>
  >([]);
  const [errorFetchCoreShipment, setErrorFetchCoreShipment] =
    useState<boolean>(false);
  const [selectedOrderItem, setSelectedOrderItem] =
    useState<OrderItemsWithShipment>();
  const [selectedOrderItemIndex, setSelectedOrderItemIndex] =
    useState<number>(0);
  const [getShipmentForOrderItem, { data: coreShipmentOutbound }] = useGQLQuery<
    GetShipmentForOrderItemQuery,
    GetShipmentForOrderItemQueryVariables
  >(GetShipmentForOrderItemDocument);

  const { useLazyGetOrderItemsCoreQuery } = api;

  const [getOrderItems, { isLoading, isFetching, isError }] =
    useLazyGetOrderItemsCoreQuery();

  const [getReturnShipmentForOrderItem, { data: coreShipmentInbound }] =
    useGQLQuery<
      GetReturnShipmentForOrderItemQuery,
      GetReturnShipmentForOrderItemQueryVariables
    >(GetReturnShipmentForOrderItemDocument);
  const orderItemsRefs = orderItems?.map((a) => a?.orderItemRef) || [];
  useEffect(() => {
    const getShipmentsFromCore = async (orderItemRef: string) => {
      setIsFetchingShipment(true);
      if (!orderItemRef) {
        setIsFetchingShipment(false);
        return;
      }
      const outbound = await getShipmentForOrderItem({
        orderItemRef,
      });
      await getReturnShipmentForOrderItem({
        orderItemRef,
      });
      if (!outbound) {
        setErrorFetchCoreShipment(true);
        Sentry.captureException("Couldn't fetch shipment from core");
      } else {
        setErrorFetchCoreShipment(false);
      }
      setIsFetchingShipment(false);
    };

    if (selectedOrderItem) {
      getShipmentsFromCore(selectedOrderItem?.order_item_ref);
    }
  }, [selectedOrderItem]);

  const getOrdersData = async () => {
    if (!caseRef && !orderItems?.length && !incitingOrderItemRef) {
      return;
    }
    if (incitingOrderItemRef) {
      orderItemsRefs.push(incitingOrderItemRef);
    }

    try {
      const response = await getOrderItems({
        caseRef,
        orderStatus: [OrderStatus.Processing, OrderStatus.Fulfilled],
        ...(orderItemsRefs.length > 0 && {
          orderItemRefs: orderItemsRefs as string[],
        }),
      });

      const fetchedOrderItems: OrderItemsWithShipment[] =
        response?.data?.edges?.map((edge) => {
          return {
            order_item_ref: edge.node.id,
            order_id: edge.node.order.id,
            short_order_ref: edge.node.order.shortOrderRef,
            quantity: edge.node.quantity,
            order_date: edge.node.order.createdAt,
            product_name: edge.node.productVariant.label || '',
            product_type: edge.node.productVariant.name,
            product_sku: edge.node.productVariant.sku,
            sent_patient_shipping_update: edge?.node?.sentPatientShippingUpdate,
            shipping_address: {
              name: edge.node.order.shippingAddress?.name || '',
              address_lines:
                edge.node.order.shippingAddress?.addressLines || [],
              city: edge.node.order.shippingAddress?.city || '',
              country_code: edge.node.order.shippingAddress?.country || '',
              state_code: edge.node.order.shippingAddress?.adminRegion || '',
              zip: edge.node.order.shippingAddress?.postalCode || '',
            },
            shipment: undefined,
          };
        }) || [];

      const sortedOrderItems = fetchedOrderItems.sort(
        (a: OrderItemsWithShipment, b: OrderItemsWithShipment) =>
          new Date(b.order_date).getTime() - new Date(a.order_date).getTime()
      );

      if (sortedOrderItems.length > 0) {
        // Not all order item come with shipping information, so we attempt to look for the ALIGNER_GOOD which should alway have shipping information
        const initialSelectedOrderItem =
          sortedOrderItems.find(
            (a: OrderItemsWithShipment) => a.product_type === 'ALIGNER_GOOD'
          ) || sortedOrderItems[0];
        setSelectedOrderItem(initialSelectedOrderItem);
        setSelectedOrderItemIndex(
          sortedOrderItems.findIndex(
            (a: OrderItemsWithShipment) =>
              a.order_item_ref === initialSelectedOrderItem.order_item_ref
          ) || 0
        );
      }

      setFetchedOrderItems(sortedOrderItems);
    } catch (error) {
      showNotification(`Failed to load order items: ${error}`, 'error');
    }
  };

  const getCoreShippingDetails = (
    coreShipment:
      | GetShipmentForOrderItemQuery['getShipmentForOrderItem']
      | GetReturnShipmentForOrderItemQuery['getReturnShipmentForOrderItem']
  ): CoreShippingDetails | undefined => {
    if (coreShipment) {
      return {
        id: selectedOrderItem?.order_id, // We're passing in the Order ID here because the inbound shipment id is not meant to be displayed. The new UI will not include this "hack"
        shipmentRef: coreShipment?.id,
        shipDate: coreShipment?.tracker?.shipDate,
        shipped_at: coreShipment?.tracker?.shipDate,
        deliveryDate: coreShipment?.tracker?.deliveryDate,
        delivered_at: coreShipment?.tracker?.deliveryDate,
        tracking_id: coreShipment?.tracker?.trackingId,
        status: coreShipment?.tracker?.status,
        carrier: coreShipment?.tracker?.carrier,
        publicUrl: coreShipment?.tracker?.publicUrl,
      };
    }
  };

  useEffect(() => {
    getOrdersData();
  }, [caseRef]);

  const renderOrderItems = () => {
    const columns = [
      {
        name: 'short_order_ref',
        label: 'Order ref',
        options: {
          customBodyRender: (order_ref: string) => {
            if (isOrthoPrism) {
              return (
                <Link
                  to={`/orders-admin?orderId=${order_ref}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {order_ref}
                </Link>
              );
            }
            return order_ref;
          },
          setCellProps: () => {
            return {
              style: {
                width: '14%',
              },
            };
          },
        },
      },
      {
        name: 'product_name',
        label: 'Product',
      },
      {
        name: 'quantity',
        label: 'Quantity',
        options: {
          setCellProps: () => {
            return {
              style: {
                width: '5%',
              },
            };
          },
        },
      },
      {
        name: 'product_sku',
        label: 'SKU',
        options: {
          setCellProps: () => {
            return {
              style: {
                width: '10%',
              },
            };
          },
        },
      },
      {
        name: 'order_item_ref',
        label: 'Order item ref',
      },
      {
        name: '',
        label: '',
        options: {
          customBodyRender: () => <ChevronRightSVG />,
          setCellProps: () => {
            return {
              style: {
                width: '5%',
              },
            };
          },
        },
      },
    ];

    return (
      <StyledTable
        data={fetchedOrderItems}
        columns={columns}
        options={{
          selectableRowsHideCheckboxes: true,
          selectableRowsOnClick: true,
          selectableRows: 'single',
          rowsSelected: [selectedOrderItemIndex],
          pagination: false,
          fixedHeader: true,
          fixedSelectColumn: true,
          responsive: 'standard',
          selectToolbarPlacement: 'none',
          onRowSelectionChange: (_allRowsSelected: any, _: any) => {
            // This is the array of value fromm the table, hence no type here
            const onClickIndex = _allRowsSelected[0].index;
            if (onClickIndex === selectedOrderItemIndex) {
              return;
            }
            setSelectedOrderItem(fetchedOrderItems[onClickIndex]);
            setSelectedOrderItemIndex(onClickIndex);
          },
        }}
      />
    );
  };

  const renderContent = () => {
    if (isLoading || isFetching) {
      return <Skeleton />;
    }

    if (isError) {
      return (
        <ErrorMessage>
          We encountered an error while fetching the orders.
        </ErrorMessage>
      );
    }

    return (
      <Grid container spacing={5}>
        <Grid item xs={12} lg={8}>
          <Note style={{ marginLeft: '1rem' }}>
            NOTE: Aligner Product represents the "good" which has the shipment
            information. Aligner Kit represents the "service" which has the
            payment information
          </Note>
          {renderOrderItems()}
        </Grid>
        <Grid item xs={12} lg={4}>
          <Sidebar
            selectedOrderItem={selectedOrderItem}
            coreShipmentOutboundDetails={getCoreShippingDetails(
              coreShipmentOutbound?.getShipmentForOrderItem
            )}
            coreShipmentInboundDetails={getCoreShippingDetails(
              coreShipmentInbound?.getReturnShipmentForOrderItem
            )}
            errorFetchCoreShipment={errorFetchCoreShipment}
            isFetchingShipment={isFetchingShipment}
            internal={internal}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <ContainerBody>
      <ContainerHeader>
        <MainText>Orders & Shipping</MainText>
        <SubText>{!internal ? `${patientName}` : null}</SubText>
      </ContainerHeader>
      {renderContent()}
    </ContainerBody>
  );
};

export default OrderShipping;
