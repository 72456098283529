import React from 'react';
import { FormikValues } from 'formik';

import {
  FormikTextAreaWrapper,
  FormikInputWrapper,
} from 'components/FormikForms';

import { Template } from 'generated/core/graphql';
import {
  Container,
  Title,
  Description,
  TitleContainerWithIcon,
  PencilIconStyled,
  EditTemplateInstructions,
  EditableHeaderContainer,
  Field,
} from 'components/Modals/EditTemplateModal.css';

type EditTemplateHeaderProps = {
  template: Template;
  values: FormikValues;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ReadOnlyHeader = ({
  isEditing,
  setIsEditing,
  template,
}: {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  template: Template;
}) => (
  <Container>
    <TitleContainerWithIcon>
      <Title>{template.name}</Title>
      <PencilIconStyled onClick={() => setIsEditing(!isEditing)} />
    </TitleContainerWithIcon>
    <Description>{template.description}</Description>
  </Container>
);

const EditableHeader = ({
  values,
  handleChange,
}: {
  values: FormikValues;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <Container>
      <EditableHeaderContainer>
        <Field>Name:</Field>
        <FormikInputWrapper
          name="name"
          type="textarea"
          value={values.name}
          onChange={handleChange}
        />
        <Field>Description:</Field>
        <FormikTextAreaWrapper
          name="description"
          type="textarea"
          value={values.description}
          onChange={handleChange}
        />
      </EditableHeaderContainer>
    </Container>
  );
};

const EditTemplateFormHeader = ({
  template,
  values,
  handleChange,
}: EditTemplateHeaderProps) => {
  const [isEditing, setIsEditing] = React.useState(true);

  return (
    <>
      {isEditing ? (
        <ReadOnlyHeader
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          template={template}
        />
      ) : (
        <EditableHeader values={values} handleChange={handleChange} />
      )}
      <EditTemplateInstructions>
        Select the answers you want to use as defaults for this template. When
        you select the template during case submissions, we’ll save you time by
        pre-selecting all the answers you’ve chosen.
      </EditTemplateInstructions>
    </>
  );
};

export default EditTemplateFormHeader;
