import React, { useMemo } from 'react';
import { Column } from 'react-virtualized';
import { Skeleton } from 'core/components';
import { mediaQueries } from 'core/components';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import VirtualizedSearchTable, {
  SortableColumn,
  monitoringLinkRenderer,
  customerCellRenderer,
  privateCellRenderer,
  NotesCellContainer,
} from 'components/SearchTablePage/table';
import { getBrandFromDomain, getBrandSupportedFeatures } from 'utils/brands';
import { getCaseTypeLabel } from 'utils/case';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const PatientSearchTable = () => {
  const tabletSize = useMediaQuery(mediaQueries.tabletAndAbove);
  const { 'enable-patient-notes': featureFlagEnablesPatientNotes } = useFlags();
  const { UsePatientNotes: brandEnablesPatientNotes } =
    getBrandSupportedFeatures(getBrandFromDomain());
  const enablePatientNotes =
    featureFlagEnablesPatientNotes || brandEnablesPatientNotes;

  const mobileColumns = useMemo(
    () => [
      <SortableColumn
        label="Name"
        dataKey="firstName"
        key="firstName"
        sortField="FirstName"
        width={125}
        flexGrow={1}
        cellRenderer={({ rowData }) =>
          customerCellRenderer(
            rowData?.id,
            'patient',
            rowData?.firstName + ' ' + rowData?.lastName
          )
        }
      />,
      <Column
        label="Status"
        dataKey="providerFacingStatus"
        key="providerFacingStatus"
        width={180}
        flexGrow={1}
        cellRenderer={({ cellData }) =>
          cellData !== '-' ? cellData : <Skeleton width={100} />
        }
      />,
      <Column
        label="Other"
        dataKey="email"
        key="email"
        width={70}
        flexGrow={0}
        flexShrink={0}
        cellRenderer={({ cellData }) => monitoringLinkRenderer(cellData)}
        className="dropdown-cell"
      />,
    ],
    []
  );
  const columns = useMemo(
    () => [
      <Column
        label="ID"
        dataKey="id"
        key="id"
        width={100}
        cellRenderer={({ cellData }) =>
          customerCellRenderer(cellData, 'patient')
        }
      />,
      <SortableColumn
        label="First name"
        dataKey="firstName"
        sortField="FirstName"
        key="firstName"
        headerStyle={{ textTransform: 'none' }}
        width={125}
        flexGrow={1}
        cellRenderer={({ cellData }) => privateCellRenderer(cellData)}
      />,
      <SortableColumn
        label="Last name"
        dataKey="lastName"
        sortField="LastName"
        key="lastName"
        width={125}
        flexGrow={1}
        headerStyle={{ textTransform: 'none' }}
      />,
      <Column
        label="Case type"
        dataKey="caseType"
        key="caseType"
        width={125}
        flexGrow={1}
        headerStyle={{ textTransform: 'none' }}
        cellRenderer={({ cellData }) =>
          cellData !== '-' ? (
            getCaseTypeLabel(cellData ?? '')
          ) : (
            <Skeleton width={100} />
          )
        }
      />,
      <Column
        label="Status"
        dataKey="providerFacingStatus"
        key="providerFacingStatus"
        headerStyle={{ textTransform: 'none' }}
        width={250}
        flexGrow={1}
        cellRenderer={({ cellData }) =>
          cellData !== '-' ? cellData : <Skeleton width={100} />
        }
      />,
      <SortableColumn
        label="Treating provider"
        dataKey="treatingProvider"
        sortField="ReferringDentistFullName"
        key="treatingProvider"
        width={150}
        flexGrow={1}
        headerStyle={{ textTransform: 'none' }}
      />,
      <Column
        label="Other"
        dataKey="email"
        key="email"
        width={70}
        flexGrow={0}
        flexShrink={0}
        cellRenderer={({ cellData }) => monitoringLinkRenderer(cellData)}
        className="dropdown-cell"
      />,
    ],
    []
  );

  const notesColumn = useMemo(
    () => [
      <Column
        label="Notes"
        dataKey="notes"
        key="notes"
        width={200}
        flexGrow={1}
        cellRenderer={({ cellData }) => (
          <NotesCellContainer cellData={cellData} />
        )}
      />,
    ],
    []
  );

  const columnsWithNotes = useMemo(() => {
    // Insert notes column after providerFacingStatus
    const insertIndex = columns.findIndex(
      (c) => c.key === 'providerFacingStatus'
    );
    return columns
      .slice(0, insertIndex + 1)
      .concat(notesColumn, columns.slice(insertIndex + 1));
  }, [enablePatientNotes, columns, notesColumn]);

  const desktopColumns = useMemo(() => {
    return enablePatientNotes ? columnsWithNotes : columns;
  }, [enablePatientNotes, columnsWithNotes, columns]);

  return (
    <VirtualizedSearchTable
      columns={tabletSize ? desktopColumns : mobileColumns}
      filterType="provider_facing_status"
      title="Patients"
      enablePatientNotes={enablePatientNotes}
    />
  );
};
