import * as Sentry from '@sentry/react';
import { validateAddress } from 'api/validation/smartystreet';
import { AddressFields } from 'api/validation/types';
import { AddressFormType } from 'components/AddressForm/types';
import { coreClient } from 'gql/GraphQLProvider';
import {
  ValidateAddressQuery,
  ValidateAddressQueryVariables,
  ValidateAddressDocument,
  AddressInput,
} from 'generated/core/graphql';

//Checks if the address is valid. If it is not, it will return  a suggested address. If it is valid, it will return the nothing
export const getValidAddresses = async (originalAddress: AddressFormType) => {
  const addressFields: AddressFields = {
    addressLine1: originalAddress.addressLine1 ?? '',
    addressLine2: originalAddress.addressLine2 ?? '',
    city: originalAddress.city ?? '',
    stateCode: originalAddress.stateCode ?? '',
    zip: originalAddress.zip ?? '',
    countryCode: originalAddress.countryCode ?? 'US',
  };

  return await validateAddress(addressFields);
};

export const getAddressInputFromAddressForm = (
  address: AddressFormType
): AddressInput | null | undefined => {
  if (
    address.addressLine1 &&
    address.city &&
    address.stateCode &&
    address.zip &&
    address.countryCode
  ) {
    const addressLines: string[] = [address.addressLine1];
    if (address.addressLine2) {
      addressLines.push(address.addressLine2);
    }

    return {
      addressLines,
      city: address.city,
      adminRegion: address.stateCode,
      postalCode: address.zip,
      country: address.countryCode,
      name: `${address.firstName || ''} ${address.lastName || ''}`.trim(),
      company: address.businessName || null,
      skipDeliveryValidation: Boolean(address.validatedBy),
    };
  }
};

export const validateAddressViaGql = async <T>(
  values: T,
  getAddressField: (values: T) => AddressFormType,
  addressPath: string
) => {
  const address = getAddressField(values);
  const addressInput = getAddressInputFromAddressForm(address);
  if (addressInput) {
    try {
      const res = await coreClient.query<
        ValidateAddressQuery,
        ValidateAddressQueryVariables
      >({
        query: ValidateAddressDocument,
        variables: {
          address: addressInput,
          validateDeliverability: true,
        },
      });

      return buildValidationError(res.data, addressPath);
    } catch (e) {
      Sentry.captureException(e);
    }
  }
};

/*
Kind of janky but gets the job done. TODO update backend to return keyed values or something
*/
const buildValidationError = (
  rsp: ValidateAddressQuery,
  addressPath: string
) => {
  const errors = {
    [addressPath]: {
      zip: '',
      stateCode: '',
      countryCode: '',
    },
  };

  let hasError = false;
  rsp?.validateAddress?.problems?.forEach((problem) => {
    const errorMessage = problem?.message.substring(
      problem?.message.indexOf(':') + 1
    );
    if (problem?.message.includes('postal_code')) {
      errors[addressPath].zip = errorMessage || '';
      hasError = true;
    } else if (problem?.message.includes('admin_region')) {
      errors[addressPath].stateCode = errorMessage || '';
      hasError = true;
    } else if (problem?.message.includes('country:')) {
      errors[addressPath].countryCode = errorMessage || '';
      hasError = true;
    }
  });
  return hasError ? errors : {};
};
