import { ReactSelectOption } from 'components/FormikForms';
import { CoreCase } from 'pages/OrthoPrism/orthoSlice';
export type CloseCaseReason =
  (typeof CloseCaseReasonDict)[keyof typeof CloseCaseReasonDict];
export type CloseCaseReasonOption = ReactSelectOption<CloseCaseReason>;

export const CloseCaseReasonDict = {
  PATIENT_REQUESTED_CANCELLATION: 'Patient requested cancellation',
  DUPLICATE_CASE: 'Duplicate case',
  UNRECOVERABLE_MATERIALS: 'Unrecoverable materials',
  PATIENT_RECEIVING_DENTAL_WORK: 'Patient is receiving dental work',
  IN_TREATMENT_ISSUE: 'In treatment Issue',
  OTHER: 'Other',
} as const;

export const customerDescriptions: Record<CaseAction, string> = {
  reopenCase: 'Reopen this case',
  closeCase: 'Close this case',
  revertQC: 'Revert the Treatment Plan to QC Review',
  clearTreatmentPlanningDraftFiles: 'Delete Treatment Planning drafts',
  reinitiateTreatmentPlanning:
    'Switch Treatment Planning Software and Reinitiate Treatment Planning',
};

export enum CaseAction {
  reopenCase = 'reopenCase',
  closeCase = 'closeCase',
  revertQC = 'revertQC',
  clearTreatmentPlanningDraftFiles = 'clearTreatmentPlanningDraftFiles',
  reinitiateTreatmentPlanning = 'reinitiateTreatmentPlanning',
}

export const closeCaseReasonOptions: CloseCaseReasonOption[] = [
  {
    label: 'Patient requested cancellation',
    value: 'Patient requested cancellation',
  },
  {
    label: 'Duplicate case',
    value: 'Duplicate case',
  },
  {
    label: 'Unrecoverable materials',
    value: 'Unrecoverable materials',
  },
  {
    label: 'Patient is receiving dental work',
    value: 'Patient is receiving dental work',
  },
  {
    label: 'In Treatment Issue',
    value: 'In treatment Issue',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const caseActionList = Object.keys(CaseAction) as CaseAction[];

export type CaseActionWrapProps = {
  customerId: string;
  selectedCase: CoreCase;
  showNotification: (message: string, variant: string) => void;
};

export type CaseActionProps = Omit<CaseActionWrapProps, 'caseRef'> & {
  hiddenCaseActions?: CaseAction[];
  selectedCase: CoreCase;
  refetchCases: () => void;
  closeCaseReasonOptions: CloseCaseReasonOption[];
};
