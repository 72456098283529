import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { Template } from 'generated/core/graphql';
import { Chip, colors, Modal, Button } from 'core/components';
import api from 'state/api';

import {
  TemplateItemContainer,
  TemplateName,
  TemplateDescription,
  EditButton,
  MetaItem,
  TemplateHeader,
  PencilIconStyled,
  TrashIconStyled,
  TemplateMeta,
  InlineEditingFeaturesContainer,
  DeletionConfirmationModalContainer,
  DeletionConfirmation,
  DeletionModalButtons,
  IncompleteChipContainer,
  MobileTooltip,
} from 'pages/AccountPreferencesPage/CaseTemplates.css';
import { getTotalAndAnsweredQuestionsCount } from 'utils/treatmentObjective';
import { DATE_TIME_FORMAT } from 'constants/treatmentObjective';

type CaseTemplateProps = {
  template: Template;
  setCurrentTemplate: (template: Template) => void;
  setIsEditModalOpen: (isOpen: boolean) => void;
  setIsSnackbarOpen: (isOpen: boolean) => void;
};

const DeletionConfirmationModal = ({
  setConfirmationModalOpen,
  setIsSnackbarOpen,
  template,
}: {
  setConfirmationModalOpen: (isOpen: boolean) => void;
  setIsSnackbarOpen: (isOpen: boolean) => void;
  template: Template;
}) => {
  const [updateUserTemplate] = api.useUpdateUserTemplateMutation();

  return (
    <Modal
      isOpen
      onClose={() => setConfirmationModalOpen(false)}
      closeButtonColor={colors.black70}
      hideOverlay={false}
      closeOnOverlayClick={true}
      isFullScreenOnMobile={false}
      hideCloseButton={true}
    >
      <DeletionConfirmationModalContainer>
        <DeletionConfirmation>
          Are you sure you want to delete the template {template.name}?
        </DeletionConfirmation>
        <DeletionModalButtons>
          <Button
            buttonType="secondary-outline"
            onClick={() => setConfirmationModalOpen(false)}
          >
            Cancel
          </Button>

          <Button
            buttonType="secondary"
            onClick={() => {
              updateUserTemplate({
                input: {
                  id: template.id,
                  delete: true,
                },
              }).unwrap();
              setConfirmationModalOpen(false);
              setIsSnackbarOpen(true);
            }}
          >
            Delete
          </Button>
        </DeletionModalButtons>
      </DeletionConfirmationModalContainer>
    </Modal>
  );
};

export const CaseTemplate = ({
  template,
  setCurrentTemplate,
  setIsEditModalOpen,
  setIsSnackbarOpen,
}: CaseTemplateProps) => {
  const templateCompleted = useMemo(() => {
    const { total, current } = getTotalAndAnsweredQuestionsCount({
      values: JSON.parse(template.userAnswers?.root || '{}'),
      systemTemplate: template,
    });
    return total === current;
  }, [template]);

  const [deletionConfirmationModalOpen, setDeletionConfirmationModalOpen] =
    useState<boolean>(false);

  const createdOnCopy = `Created on ${moment.parseZone(template.createdAt).local().format(DATE_TIME_FORMAT)}`;
  const updatedOnCopy = `Updated on ${moment.parseZone(template.updatedAt).local().format(DATE_TIME_FORMAT)}`;

  return (
    <TemplateItemContainer>
      <TemplateHeader>
        <TemplateName>{template.name}</TemplateName>
        <IncompleteChipContainer>
          {!templateCompleted && (
            <Chip
              label={'Incomplete'}
              size="small"
              style={{
                minWidth: '20px',
                color: colors.black70,
                marginRight: '9px',
              }}
            />
          )}
        </IncompleteChipContainer>
        <InlineEditingFeaturesContainer>
          <EditButton
            complete={templateCompleted}
            onClick={() => {
              setCurrentTemplate(template);
              setIsEditModalOpen(true);
            }}
          >
            <span>Edit</span>
            <PencilIconStyled />
          </EditButton>
          <TrashIconStyled
            onClick={() => {
              setDeletionConfirmationModalOpen(true);
            }}
          />
          {deletionConfirmationModalOpen && (
            <DeletionConfirmationModal
              setConfirmationModalOpen={setDeletionConfirmationModalOpen}
              setIsSnackbarOpen={setIsSnackbarOpen}
              template={template}
            />
          )}
        </InlineEditingFeaturesContainer>
      </TemplateHeader>
      <TemplateDescription>{template.description}</TemplateDescription>
      <TemplateMeta hasMarginTop={!template.description}>
        <MobileTooltip
          title={
            <div>
              {createdOnCopy}
              <br />
              {updatedOnCopy}
            </div>
          }
          style={{ paddingTop: '8px' }}
        ></MobileTooltip>
        <MetaItem>{createdOnCopy}</MetaItem>
        <MetaItem>•</MetaItem>
        <MetaItem>{updatedOnCopy}</MetaItem>
      </TemplateMeta>
    </TemplateItemContainer>
  );
};
