import styled from 'styled-components/macro';

export const Container = styled.div`
  padding: 32px;
  margin-bottom: 16px;
  border: 1px solid ${({ theme }) => theme.colors.black30};
  border-radius: 16px;
  box-shadow: 0px 1px 4px 0px ${({ theme }) => theme.colors.text20};
  scroll-margin-top: 200px;
`;

export const TitleContainer = styled.div`
  margin-bottom: 24px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.black30}`};
`;

export const Title = styled.h4`
  ${({ theme }) => theme.text.body.default};
  font-size: 20px;
  margin-bottom: 4px;
`;

export const Description = styled.p`
  ${({ theme }) => theme.text.body.default};
  color: ${({ theme }) => theme.colors.black70};
`;
