import React, { useContext } from 'react';
import { Grid, Box, Text, theme, Modal, Button } from 'core/components';
import styled from 'styled-components/macro';
import { buildToAddress, useShippingContext } from 'pages/Shipping/utils';
import { REFACTOR_ANY } from '@Types/refactor';
import { ACCESS_GROUPS } from 'constants/index';
import { AuthContext } from 'components/AuthProvider';
import { useFlags } from 'launchdarkly-react-client-sdk';

// we do not export the BoxProps from enamel or have
// styled system as a depedency we can import types from directly
const ShippingOverview = (containerProps: any = {}) => {
  const { 'fetch-consolidated-order-items': usingConsolidatedQuery } =
    useFlags();
  const { selectedOrder, selectedOrderItem } = useShippingContext();
  if (!selectedOrder) {
    return <></>;
  }
  /* TODO: Casting is a quickfix */
  const unsafeSelectedOrderItem = selectedOrderItem as REFACTOR_ANY;
  const address = buildToAddress(selectedOrderItem, selectedOrder);
  return (
    <Box
      {...containerProps}
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      border={1}
      borderRadius={8}
      borderStyle="solid"
      borderColor={theme.colors.black20}
    >
      <Box
        padding="24px"
        border={0}
        borderBottom={1}
        borderStyle="solid"
        borderColor={theme.colors.black20}
        width="100%"
      >
        <Text style={{ fontSize: '14px' }} color={theme.colors.text70}>
          {usingConsolidatedQuery ? 'Order ref' : 'Order number'}
        </Text>
        <Box display="flex" alignItems="center" alignSelf="stretch">
          <Text style={{ fontSize: '24px' }} color={theme.colors.text90}>
            {usingConsolidatedQuery
              ? selectedOrder?.order?.order?.shortOrderRef
              : selectedOrder?.order?.order?.orderNumber}
          </Text>
          {/* Order Chip here once can be calculated */}
        </Box>
      </Box>
      <Grid container style={{ padding: '24px' }}>
        <Grid item xs={3}>
          {selectedOrderItem && (
            <>
              <Text style={{ fontSize: '14px' }} color={theme.colors.text70}>
                Patient name
              </Text>
              <Text color={theme.colors.text90}>
                {unsafeSelectedOrderItem?.customer?.firstName}{' '}
                {unsafeSelectedOrderItem?.customer?.lastName}
              </Text>
            </>
          )}
        </Grid>
        <Grid item xs={3}>
          <Text style={{ fontSize: '14px' }} color={theme.colors.text70}>
            Patient Candid ID
          </Text>
          <Text color={theme.colors.text90}>{selectedOrder?.patientId}</Text>
        </Grid>
        <Grid item xs={3}>
          <Text style={{ fontSize: '14px' }} color={theme.colors.text70}>
            Kraken Case ID
          </Text>
          <Text color={theme.colors.text90}>{selectedOrder?.krakenId}</Text>
        </Grid>
        {selectedOrderItem && (
          <Grid item xs={3}>
            <Text style={{ fontSize: '14px' }} color={theme.colors.text70}>
              Shipping Address
            </Text>
            <Text color={theme.colors.text90}>{address?.name}</Text>
            {address?.company && (
              <Text color={theme.colors.text80}>{address.company}</Text>
            )}
            {address?.addressLines.map((line, idx) => (
              <Text color={theme.colors.text90} key={String(idx)}>
                {line}
              </Text>
            ))}
            <Text color={theme.colors.text90}>
              {address?.city}, {address?.adminRegion} {address?.postalCode}
            </Text>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ShippingOverview;

const ModalContainer = styled(Box)`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ClearShipmentModal = () => {
  const {
    closeClearShipmentModal,
    isClearShipmentModalOpen,
    fetchedShipment,
    selectedOrderItem,
    removeItemFromShipment,
  } = useShippingContext();
  const problemOrderItem = fetchedShipment?.containedItems?.find(
    (item) => item?.orderItemRef === selectedOrderItem?.orderItemRef
  );
  const { checkHasAccess } = useContext(AuthContext);
  const isShippingManager = checkHasAccess(ACCESS_GROUPS.STAFF);
  if (!problemOrderItem || !fetchedShipment) {
    return <></>;
  }
  return (
    <Modal
      onClose={closeClearShipmentModal}
      isOpen={isShippingManager && isClearShipmentModalOpen}
    >
      <ModalContainer>
        <h2>Remove item from Shipment?</h2>
        The item with the following details will be removed from the shipment
        <br />
        Order Item Ref: {problemOrderItem.orderItemRef}
        <br />
        Quantity: {problemOrderItem.quantity}
        <br />
        Item Type: {problemOrderItem.productType}
        <b>Warning: This action is final</b>
        <Box display="flex" width="100%" justifyContent="space-between">
          <Button
            onClick={() =>
              removeItemFromShipment(problemOrderItem.id, fetchedShipment.id)
            }
          >
            Remove Item
          </Button>
          <Button buttonType="black-outline" onClick={closeClearShipmentModal}>
            Cancel
          </Button>
        </Box>
      </ModalContainer>
    </Modal>
  );
};
