import React, { useState, useContext, useMemo, useEffect } from 'react';
import { SingleValueProps, components } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CANDID_BRAND_NAME, getBrandSupportedFeatures } from 'utils/brands';
import Dinero from 'dinero.js';
import { REFACTOR_ANY } from '@Types/refactor';

import {
  selectPatient,
  resetPatientState,
  selectActiveCase,
  refreshCaseState,
  selectIsLastStepEligible,
  fetchPatientLastStepEligible,
  selectCanCollectTreatmentGoals,
  selectPatientBrandName,
} from 'pages/Patient/patientSlice';
import {
  useParams,
  useHistory,
  Redirect,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { RouteParams } from 'pages/Patient/CaseCreator/types';
import {
  CheckoutContainer,
  StyledSelectInput,
  QuestionHeading,
  StyleToggle,
  RetainerContainer,
} from 'pages/Patient/Checkout/Checkout.css';
import { SkeletonCheckout } from 'pages/Patient/Checkout/Skeletons';
import retainerImgSrc from 'assets/orderItems/retainer.png';
import { AddressSelection } from 'components/AddressSelection/AddressSelection';
import CaseCreatorFooter, { Steps } from 'pages/Patient/Footer';
import { NotificationContext } from 'core/components';
import { AppDispatch } from 'state/store';
import { useIsLoading } from 'state/system';
import * as Sentry from '@sentry/react';
import useSubmitCase from 'pages/Patient/CaseCreator/useSubmitCase';
import { BackButton, LeftArrow } from 'pages/Patient/styles.css';
import { useGQLQuery } from 'hooks/useGQL';
import {
  ApplyPromotionsToCartDocument,
  ApplyPromotionsToCartQueryVariables,
  ApplyPromotionsToCartQuery,
  AddressInput,
} from 'generated/core/graphql';
import LegacyTotalBox from 'pages/Patient/Checkout/LegacyTotalBox';
import { findCachedLineItem } from 'pages/Patient/Checkout/utils';
import { getBrandSKUs } from 'utils/brands';
import { getAddressInputFromAddressForm } from 'components/AddressForm/utils';
import { PartialLineItem } from 'components/TotalBox/types';

import { CatalogItemType, SelectionOptionTypes } from 'types/checkout';
import {
  CartItemContainer,
  CartItemCostContainer,
  Description,
  ItemBody,
  Question,
  StrikethroughText,
  Title,
} from 'components/CatalogItem/Catalog.css';
import { AddressFormType } from 'components/AddressForm/types';
import api from 'state/api';

const MAX_QUANTITY = 4;

type QuantityOptionsType = {
  label: string;
  value: number;
};

const SingleValue = (props: SingleValueProps<{ value: number }>) => (
  // This is used so that the selected quantity displays only the quantity
  // and not the full label which includes the price
  <components.SingleValue {...props}>{props.data.value}</components.SingleValue>
);

const CatalogItem = ({
  item,
  onChange,
  quantityOptions,
  displayPrices,
}: {
  item: CatalogItemType;
  onChange: (quantity: number) => void;
  quantityOptions: QuantityOptionsType[];
  displayPrices: boolean;
}) => {
  const { 'enable-volume-based-discounts': enableVolumeBasedDiscounts } =
    useFlags();
  const costDisplay = (quantity: number, priceInCents: Dinero.Dinero) => {
    return quantity + ' for ' + priceInCents.toFormat();
  };

  // Reset the quantity to 0 on mount
  useEffect(() => {
    onChange(item.quantity);
  }, []);

  const StrikethroughPrice = (
    <CartItemCostContainer>
      Cost:
      <StrikethroughText>
        {costDisplay(item.quantity, item.totalPriceBeforeDiscounts)}
      </StrikethroughText>
      {costDisplay(item.quantity, item.totalPriceAfterDiscounts)}
    </CartItemCostContainer>
  );
  const UnitPrice = (
    <CartItemCostContainer>{`Cost: ${item.totalPriceBeforeDiscounts.toFormat()}`}</CartItemCostContainer>
  );
  return (
    <div style={{ width: '100%' }}>
      {item.question && <Question>{item.question}</Question>}
      <CartItemContainer>
        <img src={item.imageSrc} alt="item" />
        <ItemBody>
          <Title>{item.title}</Title>
          <Description>{item.description}</Description>
          {displayPrices &&
            (enableVolumeBasedDiscounts &&
            item.totalPriceAfterDiscounts.getAmount() <
              item.totalPriceBeforeDiscounts.getAmount()
              ? StrikethroughPrice
              : UnitPrice)}
        </ItemBody>
        {item.optionType === SelectionOptionTypes.Toggle && (
          <StyleToggle
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(e.target.checked ? 1 : 0)
            }
          />
        )}
        {item.optionType === SelectionOptionTypes.QuantityDropDown && (
          <StyledSelectInput
            $displayPrices={displayPrices}
            options={quantityOptions}
            onChange={(e: REFACTOR_ANY) => onChange(e.value)}
            placeholder={item.quantity > 0 ? item.quantity.toString() : '—'}
            quantity={item.quantity}
            components={{ SingleValue }}
          />
        )}
      </CartItemContainer>
    </div>
  );
};

const DEFAULT_PRICE_IN_CENTS = 9900;

const defaultRetainerItem = {
  question: 'How many retainers do you want to order?',
  title: 'Select quantity',
  description:
    'Order up to 4 retainers at a time to avoid booking extra appointments.',
  price: Dinero({ amount: DEFAULT_PRICE_IN_CENTS }),
  defaultPrice: Dinero({ amount: DEFAULT_PRICE_IN_CENTS }),
  sku: getBrandSKUs(CANDID_BRAND_NAME).lastStepAligner.sku,
  imageSrc: retainerImgSrc,
  optionType: SelectionOptionTypes.QuantityDropDown,
  quantity: 0,
  totalPriceAfterDiscounts: Dinero({ amount: 0 }),
  totalPriceAfterLoyalty: Dinero({ amount: 0 }),
  totalPriceBeforeDiscounts: Dinero({ amount: 0 }),
  providerFacingProductName: 'Retainer',
};

const RetainerCheckout = () => {
  const [createOrder] = api.useCreateOrderMutation();
  const isLastStepEligible = useSelector(selectIsLastStepEligible);
  const isFetchingLastStepEligible = useIsLoading(
    fetchPatientLastStepEligible.typePrefix
  );
  const match = useRouteMatch<RouteParams>();
  const addressFormSubmitBtnRef = React.createRef<HTMLButtonElement>();
  const history = useHistory();
  const location = useLocation();
  const { showNotification } = useContext(NotificationContext);
  const hasTreatmentGoals = useSelector(selectCanCollectTreatmentGoals);
  const brandName = useSelector(selectPatientBrandName);
  const { DisplayPricesToProviders: displayPrices } =
    getBrandSupportedFeatures(brandName);
  const { id } = useParams<RouteParams>();
  const dispatch = useDispatch<AppDispatch>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedShippingAddress, setSelectedShippingAddress] =
    useState<REFACTOR_ANY>();
  const [sendPatientUpdate, setSendPatientUpdate] = useState<boolean>(false);
  const [orderItems, setOrderItems] = useState<CatalogItemType[]>([]);
  const [lineItemDiscountsCache, setLineItemDiscountsCache] = useState<
    PartialLineItem[]
  >([]);
  const activeCase = useSelector(selectActiveCase);
  const patient = useSelector(selectPatient);
  const { push } = useHistory();
  const { canSubmitCase, submitCase } = useSubmitCase();
  const totalBeforeDiscounts = useMemo(() => {
    const totalDinero = orderItems.reduce(
      (acc, item) => acc.add(item.totalPriceBeforeDiscounts),
      Dinero({ amount: 0 })
    );
    return totalDinero;
  }, [orderItems]);
  const totalAfterDiscounts = useMemo(() => {
    const totalDinero = orderItems.reduce(
      (acc, item) => acc.add(item.totalPriceAfterDiscounts),
      Dinero({ amount: 0 })
    );
    return totalDinero;
  }, [orderItems]);
  const [applyPromotionsToCart] = useGQLQuery<
    ApplyPromotionsToCartQuery,
    ApplyPromotionsToCartQueryVariables
  >(ApplyPromotionsToCartDocument);
  const selectedSkus = useMemo(() => {
    if (brandName) {
      return getBrandSKUs(brandName);
    }
    return getBrandSKUs(CANDID_BRAND_NAME);
  }, [brandName]);

  const isRetainerCase =
    activeCase && activeCase?.caseType?.name === 'retainer';
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (!location?.search) {
      return;
    }
    // Hack to know which sku to select.
    // In the future we'll most likely want to fetch available products from the backend
    let sku;
    if (searchParams.get('reorder')) {
      sku = isRetainerCase
        ? selectedSkus.lastStepRetainer.sku
        : selectedSkus.lastStepAligner.sku;
    } else if (searchParams.get('last_step')) {
      sku = selectedSkus.lastStepAligner.sku;
    } else if (searchParams.get('new_scans')) {
      sku = selectedSkus.retainer.sku;
    }
    if (sku) {
      defaultRetainerItem.sku = sku;
      defaultRetainerItem.quantity = 0;
      defaultRetainerItem.description =
        'We recommend ordering at least one backup retainer to avoid future appointments. Save by ordering more.';
      setOrderItems([defaultRetainerItem]);
      loadCartPromotionTiers(sku);
    } else {
      Sentry.captureException('No sku on checkout page');
      showNotification('Issue loading checkout page', 'error');
    }
  }, [location.search]);

  const [quantityOptions, setQuantityOptions] = useState<QuantityOptionsType[]>(
    [...Array(MAX_QUANTITY).keys()].map((i) => ({
      label: (i + 1).toString(), // +1 is hack to avoid showing 0
      value: i + 1,
    }))
  );

  const onBackClick = () => {
    push(`${match.url.split('/').slice(0, -1).join('/')}`);
  };

  const postOrderSubmit = () => {
    showNotification('Order confirmed', 'success');
    dispatch(resetPatientState()); // Reset patient state to avoid confusion between selected case
    history.push(`/patient/${id}`);
  };

  const loadCartPromotionTiers = async (sku: string) => {
    // load cart promotions so we can get the list of prices in the dropdown
    // In the future we can consider creating a new promotions endpoint that will
    // retrieve pricing for individual items instead of creating a new cart for each quantity
    const lines: PartialLineItem[] = [];
    const updatedQuantityOptions = [...quantityOptions];

    const promises = [];
    for (let qty = 1; qty <= MAX_QUANTITY; qty++) {
      const input = {
        input: {
          lineItems: [
            {
              sku: sku,
              quantity: qty,
              originalPriceInCents: DEFAULT_PRICE_IN_CENTS * qty,
            },
          ],
          couponCodes: [],
          practiceId: patient?.practice?.id,
          patientId: patient?.id,
        },
      };
      promises.push(applyPromotionsToCart(input));
    }

    await Promise.all(promises)
      .then((results) => {
        results.forEach((result) => {
          const cart = result?.applyPromotionsToCart;
          cart?.lineItems?.forEach((line) => {
            if (line) {
              lines.push(line);
              const displayPrice = Dinero({
                amount:
                  // fall back to the original price if the final price is not available
                  line.finalPriceInCents || line.originalPriceInCents,
              });
              updatedQuantityOptions[line.quantity - 1]['label'] = displayPrices
                ? line.quantity.toString() + ' - ' + displayPrice.toFormat()
                : line.quantity.toString();
            }
          });
        });
      })
      .catch((e) => {
        Sentry.captureException(e);
        console.error(e);
      });

    setQuantityOptions(updatedQuantityOptions);

    setLineItemDiscountsCache(lines);
  };

  const updateOrderItems = (index: number, quantity: number) => {
    const sku = orderItems[index].sku;

    const cartLine = findCachedLineItem(sku, quantity, lineItemDiscountsCache);
    const newOrderItems = [...orderItems];
    newOrderItems[index].quantity = quantity;
    const totalPriceBeforeDiscounts =
      newOrderItems[index].price.getAmount() * quantity;
    newOrderItems[index].totalPriceBeforeDiscounts = Dinero({
      amount: totalPriceBeforeDiscounts,
    });
    newOrderItems[index].totalPriceAfterDiscounts = Dinero({
      amount: cartLine?.finalPriceInCents || totalPriceBeforeDiscounts,
    });
    setOrderItems(newOrderItems);
  };

  const prepareShippingAddress = (otherAddress?: REFACTOR_ANY) => {
    if (otherAddress) {
      return {
        address: {
          ...otherAddress,
          firstName: patient?.firstName,
          lastName: patient?.lastName,
        },
        addressType: 'other',
      };
    }
    return {
      address: selectedShippingAddress.value,
      addressType: selectedShippingAddress.addressType,
    };
  };

  /**
   * This is for creating the inital retainer order for a new patient that is standalone based on new material.
   */
  const createStandaloneRetainerOrder = async ({
    shippingAddress,
    caseRef,
  }: {
    shippingAddress: AddressFormType;
    caseRef: string;
  }) => {
    if (!patient?.practice) {
      return {
        error: 'Attempted to create standalone retainer without a practice id.',
      };
    }
    try {
      const result = await createOrder({
        orderItems: orderItems.map((item) => ({
          productVariantSku: item.sku,
          quantity: item.quantity,
          sentPatientShippingUpdate: sendPatientUpdate,
        })),
        couponCodes: [],
        autoActivate: false,
        patientId: Number(patient.id),
        practiceId: Number(patient.practice.id),
        shippingAddress: getAddressInputFromAddressForm(
          shippingAddress
        ) as AddressInput,
        caseRef,
      }).unwrap();
      // With Order Info not being assigned to the case, we need to refresh the case state.
      await dispatch(refreshCaseState({ caseRef }));
      return result;
    } catch (error) {
      return { error };
    }
  };

  /**
   * This is only for the case where the user is submitting a retainer order that is a re-order or last step.
   */
  const createRetainerOrder = async ({
    shippingAddress,
    caseRef,
  }: {
    shippingAddress: AddressInput;
    caseRef: string;
  }) => {
    if (!patient?.practice) {
      return {
        error:
          'Attempted to create last step or re-order retainer without a practice id.',
      };
    }

    try {
      const result = await createOrder({
        orderItems: orderItems.map((item) => ({
          productVariantSku: item.sku,
          quantity: item.quantity,
          sentPatientShippingUpdate: sendPatientUpdate,
        })),
        couponCodes: [],
        autoActivate: true,
        patientId: Number(patient.id),
        practiceId: Number(patient.practice.id),
        shippingAddress: getAddressInputFromAddressForm(
          shippingAddress
        ) as AddressInput,
        caseRef,
      }).unwrap();
      // With Order Info not being assigned to the case, we need to refresh the case state.
      await dispatch(refreshCaseState({ caseRef }));
      return result;
    } catch (error) {
      return { error };
    }
  };

  const submitOrder = async (otherAddress?: REFACTOR_ANY) => {
    if (!id || !activeCase?.caseRef) {
      Sentry.captureException('No patient id found when submitting the order');
      showNotification(
        'There was an issue submitting your order, please refresh and try again.',
        'error'
      );
      return;
    }
    if (!isLastStepEligible && !isRetainerCase) {
      Sentry.captureException('No eligible case found for retainer order');
      showNotification('No eligible case found for retainer order', 'error');
      return;
    }
    if (!orderItems.length) {
      Sentry.captureException('No order items found when submitting the order');
      showNotification(
        'There was an issue submitting your order, please refresh and try again.',
        'error'
      );
      return;
    }
    setIsSubmitting(true);
    let result;
    const shippingInfo = prepareShippingAddress(otherAddress);
    if (isRetainerCase && searchParams.get('new_scans')) {
      if (canSubmitCase) {
        try {
          await submitCase({
            isCore: true,
          });
        } catch (e) {
          Sentry.captureException(e);
          showNotification(
            'Problem submitting case, please try again or contact customer support',
            'error'
          );
          return;
        }
      }
      result = await createStandaloneRetainerOrder({
        shippingAddress: shippingInfo.address,
        caseRef: activeCase?.caseRef,
      });
    } else {
      result = await createRetainerOrder({
        shippingAddress: shippingInfo.address,
        caseRef: activeCase?.caseRef,
      });
    }
    setIsSubmitting(false);
    if ((result as REFACTOR_ANY)?.error) {
      Sentry.captureException((result as REFACTOR_ANY)?.error);
      showNotification(
        'Problem submitting order, please try again or contact customer support',
        'error'
      );
    } else {
      postOrderSubmit();
    }
  };

  if (!isRetainerCase && isFetchingLastStepEligible) {
    return <SkeletonCheckout />;
  }

  if (!isRetainerCase && !isLastStepEligible) {
    return <Redirect to={`/patient/${id}`} />;
  }
  return (
    <RetainerContainer>
      {hasTreatmentGoals && (
        <BackButton onClick={onBackClick}>
          <LeftArrow /> Back to all case tasks
        </BackButton>
      )}
      <CheckoutContainer>
        <h1>Review & submit</h1>
        {orderItems.map((item, i) => {
          return (
            <CatalogItem
              item={item}
              onChange={(quantity) => {
                updateOrderItems(i, quantity);
              }}
              quantityOptions={quantityOptions}
              displayPrices={displayPrices}
              key={i}
            />
          );
        })}
        {displayPrices && (
          <LegacyTotalBox
            orderItems={orderItems}
            lineItemDiscountsCache={lineItemDiscountsCache}
          />
        )}
        <QuestionHeading>Where do you want to ship this order?</QuestionHeading>
        <AddressSelection
          setResultAddress={setSelectedShippingAddress}
          setResultSendPatientUpdate={setSendPatientUpdate}
          addressFormSubmitBtnRef={addressFormSubmitBtnRef}
          handleConfirmedAddress={submitOrder}
          patientId={patient?.id}
        />
      </CheckoutContainer>
      <CaseCreatorFooter
        currentStep={Steps.Checkout}
        onSubmit={async () => {
          if (addressFormSubmitBtnRef.current) {
            addressFormSubmitBtnRef.current.click();
            return;
          }
          return await submitOrder();
        }}
        rightMessage={
          displayPrices ? `Total: ${totalAfterDiscounts.toFormat()}` : ''
        }
        disabled={
          isSubmitting ||
          orderItems.some((item) => item.quantity === 0) ||
          totalBeforeDiscounts.isZero()
        }
        isCtaLoading={isSubmitting}
      />
    </RetainerContainer>
  );
};

export default RetainerCheckout;
