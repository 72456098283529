import { Maybe } from 'graphql/jsutils/Maybe';
import { LocalCase } from 'utils/types';

import { Case, CaseTypes } from 'generated/legacy/graphql';
import { SupplierServices, CaseData } from 'pages/Case/types';
import { PROVIDER_FACING_STATUSES } from 'constants/caseStatus';
import { RequiredNonNullable } from 'utils/typeCheck';
import { CaseSoftwareChoice } from 'generated/core/graphql';
import moment from 'moment';

type RequiredCaseFields = RequiredNonNullable<Case, 'caseRef' | 'customerRef'> &
  RequiredNonNullable<Case, 'created'> &
  RequiredNonNullable<Case, 'isActive'>;

type ValidCase = Case & RequiredCaseFields;

export type DecodedCase = Omit<
  Case,
  'supplierServices' | 'treatmentplans' | 'data'
> & {
  supplierServices?: Maybe<SupplierServices>;
  data?: Maybe<CaseData>;
  caseSoftware?: Maybe<CaseSoftwareChoice>;
} & RequiredCaseFields;

const maxTeenAndYouthAge = 17;

const caseIsValid = (
  caseToValidate: Maybe<LocalCase>
): caseToValidate is ValidCase => {
  if (
    !caseToValidate ||
    !caseToValidate.caseRef ||
    !caseToValidate.customerRef ||
    !caseToValidate.created
  ) {
    return false;
  }

  return true;
};

/**
 * decode (i.e. parse) json string fields of a case
 * @param case
 */
export const decodeCase = (
  caseToDecode: Maybe<LocalCase>
): Maybe<DecodedCase> => {
  if (!caseIsValid(caseToDecode)) {
    return null;
  }

  const { supplierServices, data } = caseToDecode;

  return {
    ...caseToDecode,
    supplierServices: supplierServices ? JSON.parse(supplierServices) : null,
    data: data ? JSON.parse(data) : null,
  };
};

export const caseTypeForBirthday = (birthday: string) => {
  const current_date = moment();
  const age = current_date.diff(birthday, 'years');

  if (age <= maxTeenAndYouthAge) {
    return CaseTypes.CproTeenAligner;
  }

  return CaseTypes.CpioAdultAligner;
};

export const getCaseTypeLabel = (caseLabel: string): string => {
  return !caseLabel ? '' : caseLabel;
};

// Get a temporary mask provider facing status when ff is not on

export const availableActionsForProviderFacingStatus = (
  providerFacingStatus?: PROVIDER_FACING_STATUSES
) => {
  return {
    canOrderRetainers:
      providerFacingStatus &&
      [
        PROVIDER_FACING_STATUSES.IN_TREATMENT,
        PROVIDER_FACING_STATUSES.TREATMENT_COMPLETED,
        PROVIDER_FACING_STATUSES.CASE_COMPLETED,
      ].includes(providerFacingStatus),
    canCreateDmAccount:
      providerFacingStatus &&
      [
        PROVIDER_FACING_STATUSES.ALIGNER_KIT_IN_PRODUCTION,
        PROVIDER_FACING_STATUSES.ALIGNER_KIT_SHIPPED,
        PROVIDER_FACING_STATUSES.ALIGNER_KIT_DELIVERED,
        PROVIDER_FACING_STATUSES.IN_TREATMENT,
      ].includes(providerFacingStatus),
    canSendDmActivationEmail:
      providerFacingStatus &&
      [PROVIDER_FACING_STATUSES.IN_TREATMENT].includes(providerFacingStatus),
    canConfirmAlignerDelivery:
      providerFacingStatus &&
      [
        PROVIDER_FACING_STATUSES.ALIGNER_KIT_IN_PRODUCTION,
        PROVIDER_FACING_STATUSES.ALIGNER_KIT_SHIPPED,
        PROVIDER_FACING_STATUSES.ALIGNER_KIT_DELIVERED,
      ].includes(providerFacingStatus),
    canViewTpLinkPdf:
      providerFacingStatus &&
      [
        PROVIDER_FACING_STATUSES.ALIGNER_KIT_IN_PRODUCTION,
        PROVIDER_FACING_STATUSES.ALIGNER_KIT_SHIPPED,
        PROVIDER_FACING_STATUSES.ALIGNER_KIT_DELIVERED,
        PROVIDER_FACING_STATUSES.IN_TREATMENT,
      ].includes(providerFacingStatus),
  };
};
