export enum CaseSubmissionStates {
  Submitted = 'submitted',
  Rejected = 'rejected',
  NotSubmitted = 'not_submitted',
}

// Temporary enums until they are present in generated graphql-core file
// Copied over from core and reformatted for ts (https://github.com/candidco/candid-code/blob/a16518fd43b6767204b64d9e1038310ffe639e1c/core/domains/treatment/cases/models.py)
export enum ProviderFacingStates {
  INCOMPLETE_SUBMISSION = 'Incomplete submission',
  MATERIALS_UNDER_REVIEW = 'Materials under review',
  ACTION_NEEDED = 'Action needed',
  TREATMENT_PLANNING_IN_PROGRESS = 'Treatment planning in progress',
  TREATMENT_PLAN_READY_FOR_YOUR_REVIEW = 'Treatment plan ready for your review',
  ALIGNER_KIT_IN_PRODUCTION = 'Aligner kit in production',
  ALIGNER_KIT_SHIPPED = 'Aligner kit shipped',
  ALIGNER_KIT_DELIVERED = 'Aligner kit delivered',
  IN_TREATMENT = 'In treatment',
  IN_RETENTION = 'In retention',
  REFINEMENTS_REQUESTED = 'Refinements requested',
  CASE_CLOSED_INITIAL_FIT_ISSUE = 'Case closed: initial fit issue',
  TREATMENT_COMPLETED = 'Treatment completed',
  CASE_COMPLETED = 'Case completed',
  CASE_EXPIRED = 'Case expired',
  CASE_CANCELED = 'Case canceled',
  INITIAL_FIT_ISSUE = 'Initial fit issue',
}

export enum InternalCaseStates {
  INCOMPLETE_SUBMISSION = 'Incomplete submission',
  AWAITING_PVS_IMPRESSIONS = 'Awaiting PVS impressions',
  AWAITING_STL_EXPORT_UPLOAD = 'Awaiting STL export upload',
  MATERIAL_REVIEW = 'Materials under review',
  ACTION_NEEDED = 'Provider action needed',
  TREATMENT_PLANNING_IN_PROGRESS = 'Treatment planning in progress',
  QC_REVIEWING_TREATMENT_PLAN = 'QC reviewing treatment plan',
  ORTHO_REVIEWING_TREATMENT_PLAN = 'Ortho reviewing treatment plan',
  PROVIDER_REVIEWING_TREATMENT_PLAN = 'Provider reviewing treatment plan',
  ALIGNER_KIT_IN_PRODUCTION = 'Aligner kit in production',
  ALIGNER_KIT_SHIPPED = 'Aligner kit shipped',
  ALIGNER_KIT_DELIVERED = 'Aligner kit delivered',
  IN_TREATMENT = 'In treatment',
  IN_RETENTION = 'In retention',
  REFINEMENTS_REQUESTED = 'Refinements requested',
  CASE_CLOSED_INITIAL_FIT_ISSUE = 'Case closed: initial fit issue',
  TREATMENT_COMPLETED = 'Treatment completed',
  CASE_COMPLETED = 'Case completed',
  CASE_EXPIRED = 'Case expired',
  CASE_CANCELED = 'Case canceled',
  INITIAL_FIT_ISSUE = 'Initial fit issue',
}
export enum SearchableInternalCaseStates {
  INCOMPLETE_SUBMISSION = 'Incomplete submission',
  AWAITING_PVS_IMPRESSIONS = 'Awaiting PVS impressions',
  AWAITING_STL_EXPORT_UPLOAD = 'Awaiting STL export upload',
  MATERIAL_REVIEW = 'Materials under review',
  ACTION_NEEDED = 'Provider action needed',
  TREATMENT_PLANNING_IN_PROGRESS = 'Treatment planning in progress',
  ORTHO_REVIEWING_TREATMENT_PLAN = 'Ortho reviewing treatment plan',
  PROVIDER_REVIEWING_TREATMENT_PLAN = 'Provider reviewing treatment plan',
  ALIGNER_KIT_IN_PRODUCTION = 'Aligner kit in production',
  ALIGNER_KIT_SHIPPED = 'Aligner kit shipped',
  ALIGNER_KIT_DELIVERED = 'Aligner kit delivered',
  IN_TREATMENT = 'In treatment',
  IN_RETENTION = 'In retention',
  REFINEMENTS_REQUESTED = 'Refinements requested',
  CASE_CLOSED_INITIAL_FIT_ISSUE = 'Case closed: initial fit issue',
  TREATMENT_COMPLETED = 'Treatment completed',
  CASE_COMPLETED = 'Case completed',
  CASE_EXPIRED = 'Case expired',
  CASE_CANCELED = 'Case canceled',
}

export enum CaseTypeNames {
  ALIGNER = 'aligner',
  RETAINER = 'retainer',
  REFINEMENTS = 'additional_aligner',
}

export const LastStepRetainerIneligibleTpFeatures = [
  'attachments',
  'biteRamps',
  'cutouts',
  'elastics',
  'hooks',
];
