import React, { useRef, useMemo } from 'react';
import { StyledModal } from 'components/Modals/RefinementsModal.css';
import FullPageModal from 'components/FullPageModal/FullPageModal';
import { Template } from 'generated/core/graphql';
import api from 'state/api';
import QuestionGroups from 'components/TreatmentObjectives/TemplateQuestions';
import { Formik, FormikProps, FormikValues } from 'formik';
import EditTemplateFormHeader from 'components/Modals/EditTemplateFormHeader';

type EditTemplateModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: FormikValues) => void;
  template: Template;
};

const EditTemplateModal = ({
  isOpen,
  onClose,
  template,
}: EditTemplateModalProps) => {
  const [updateUserTemplate, { status: updatingStatus }] =
    api.useUpdateUserTemplateMutation();

  const formRef = useRef<FormikProps<FormikValues>>(null);

  if (!template) {
    return null;
  }

  const initialValues = useMemo(() => {
    const initialUserAnswers = JSON.parse(template.userAnswers?.root || '{}');
    return {
      name: template.name,
      description: template.description,
      ...initialUserAnswers,
    };
  }, [template.id]);

  const onConfirmEdit = async (values: FormikValues) => {
    if (!template) {
      return;
    }

    const userAnswers = Object.fromEntries(
      Object.entries(values).filter(
        ([key]) => key !== 'name' && key !== 'description'
      )
    );
    const input = {
      id: template.id,
      userAnswers: JSON.stringify(userAnswers),
      name: values.name ?? template.name,
      description: values?.description,
    };
    await updateUserTemplate({
      input: input,
    });

    onClose();
  };

  const { name } = template;
  return (
    <FullPageModal
      isOpen={isOpen}
      titleText="Edit case template"
      onClose={onClose}
      onContinue={() => {
        if (formRef && formRef.current) {
          formRef.current.submitForm();
        }
      }}
      continueDisabled={!template}
      patientName={name}
      isLoading={updatingStatus === 'pending'}
      continueText="Save"
    >
      <StyledModal>
        <Formik
          initialValues={initialValues}
          onSubmit={onConfirmEdit}
          innerRef={formRef}
          enableReinitialize
        >
          {({ values, handleChange }) => (
            <>
              <EditTemplateFormHeader
                values={values}
                handleChange={handleChange}
                template={template}
              />
              {template && <QuestionGroups template={template} />}
            </>
          )}
        </Formik>
      </StyledModal>
    </FullPageModal>
  );
};

export default EditTemplateModal;
