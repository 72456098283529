import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Grid } from 'core/components';
import {
  CheckBox as CheckBoxContainer,
  CheckBoxesContainer,
  Container,
} from 'components/ToothChart/ToothChart.css';
import CheckSvg from 'assets/check.svg?react';
import { DentalNotationOptions } from 'generated/legacy/graphql';
import { mediaQueries } from 'core/components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DynamicTooth from 'components/ToothChart/DynamicTooth';

export const convertFromPalmerToUniversal = (
  quadrant: number,
  index: number
) => {
  if (quadrant === 1) {
    return index + 8;
  } else if (quadrant === 2) {
    return 9 - index;
  } else if (quadrant === 4) {
    return 25 - index;
  } else {
    return index + 24;
  }
};

export const convertFromFDIToUniversal = (quadrant: number, index: number) => {
  if (quadrant === 1) {
    return index - 12;
  } else if (quadrant === 2) {
    return 19 - index;
  } else if (quadrant === 3) {
    return index - 16;
  } else {
    return 55 - index;
  }
};

const getQuadrants = (notation: DentalNotationOptions) => {
  const palmerQuadrants = [
    [1, 8], // 1st quadrant: Teeth 1 to 8 (Upper Right)
    [1, 8], // 2nd quadrant: Teeth 1 to 8 (Upper Left)
    [1, 8], // 3rd quadrant: Teeth 1 to 8 (Lower Left)
    [1, 8], // 4th quadrant: Teeth 1 to 8 (Lower Right)
  ];
  const universalQuadrants = [
    [9, 16], // 1st quadrant: Teeth 9 to 16 (Upper Right)
    [1, 8], // 2nd quadrant: Teeth 1 to 8 (Upper Left)
    [25, 32], // 3rd quadrant: Teeth 25 to 32 (Lower Left)
    [17, 24], // 4th quadrant: Teeth 17 to 24 (Lower Right)
  ];
  const fdiQuadrants = [
    [21, 28], // 1st quadrant: Teeth 21 to 28 (Upper Right)
    [11, 18], // 2nd quadrant: Teeth 11 to 18 (Upper Left)
    [41, 48], // 3rd quadrant: Teeth 41 to 48 (Lower Left)
    [31, 38], // 4th quadrant: Teeth 31 to 38 (Lower Right)
  ];

  if (notation === DentalNotationOptions.Palmer) {
    return palmerQuadrants;
  } else if (notation === DentalNotationOptions.Fdi) {
    return fdiQuadrants;
  } else {
    return universalQuadrants;
  }
};

type ToothChartProps = {
  values: number[];
  onChange?: (value: number[]) => void;
  readOnly?: boolean;
  disabled?: boolean;
  notation?: DentalNotationOptions;
};

/*
 * A component that renders an array of check boxes representing tooth positions
 * The positions are 1 indexed, and are tied to the id of the rendered checkbox
 *
 * Values is passed in as a list of ints (ex: 3,6,23) indicating which check boxes are checked in universal notation
 *
 */
const ToothChart = ({
  values,
  readOnly = false,
  onChange,
  disabled = false,
  notation = DentalNotationOptions.Universal,
}: ToothChartProps) => {
  const isPalmer = notation === DentalNotationOptions.Palmer;
  const isFDI = notation === DentalNotationOptions.Fdi;
  const tabletSize = useMediaQuery(mediaQueries.tabletAndAbove);
  const onCheckBoxChanged = (
    quadrant: number,
    index: number,
    isChecked: boolean
  ) => {
    if (notation === DentalNotationOptions.Palmer) {
      index = convertFromPalmerToUniversal(quadrant, index);
    } else if (notation === DentalNotationOptions.Fdi) {
      index = convertFromFDIToUniversal(quadrant, index);
    }
    const newValues = values.map((e) => e);
    if (isChecked && !values.includes(index)) {
      newValues.push(index);
    } else if (!isChecked) {
      const position = newValues.indexOf(index);
      if (position >= 0) {
        newValues.splice(position, 1);
      }
    }

    if (onChange) {
      onChange(newValues);
    }
  };

  const [
    [startUpperRight, endUpperRight],
    [startUpperLeft, endUpperLeft],
    [startLowerLeft, endLowerLeft],
    [startLowerRight, endLowerRight],
  ] = getQuadrants(notation);

  return (
    <Container container xs={12} disabled={disabled}>
      {tabletSize && (
        <Grid item xs={1}>
          R
        </Grid>
      )}
      <Grid item container xs={tabletSize ? 10 : 12}>
        <Grid container item xs={12} direction="row">
          <Grid
            item
            xs={6}
            style={{
              borderRight: '1px dotted #c9c9c9c9',
              borderBottom: '1px dotted #c9c9c9c9',
            }}
          >
            {!tabletSize && 'R'}
            <CheckBoxes
              start={startUpperLeft}
              end={endUpperLeft}
              quadrant={2}
              values={values}
              readOnly={readOnly}
              onChange={onCheckBoxChanged}
              reversed={isPalmer || isFDI}
              disabled={disabled}
              notation={notation}
            />
          </Grid>
          <Grid item xs={6} style={{ borderBottom: '1px dotted #c9c9c9c9' }}>
            {!tabletSize && 'L'}
            <CheckBoxes
              start={startUpperRight}
              end={endUpperRight}
              quadrant={1}
              values={values}
              readOnly={readOnly}
              onChange={onCheckBoxChanged}
              reversed={false}
              disabled={disabled}
              notation={notation}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} direction="row">
          <Grid item xs={6} style={{ borderRight: '1px dotted #c9c9c9c9' }}>
            <CheckBoxes
              start={startLowerLeft}
              end={endLowerLeft}
              quadrant={3}
              values={values}
              readOnly={readOnly}
              onChange={onCheckBoxChanged}
              reversed={true}
              disabled={disabled}
              notation={notation}
            />
          </Grid>
          <Grid item xs={6}>
            <CheckBoxes
              start={startLowerRight}
              end={endLowerRight}
              quadrant={4}
              values={values}
              readOnly={readOnly}
              onChange={onCheckBoxChanged}
              reversed={!(isPalmer || isFDI)}
              disabled={disabled}
              notation={notation}
            />
          </Grid>
        </Grid>
      </Grid>
      {tabletSize && (
        <Grid item xs={1}>
          L
        </Grid>
      )}
    </Container>
  );
};

type ToothCheckBoxesProps = {
  quadrant: number;
  start: number;
  end: number;
  values: number[];
  readOnly: boolean;
  onChange: (quadrant: number, id: number, value: boolean) => void;
  reversed: boolean;
  disabled?: boolean;
  notation: DentalNotationOptions;
};

/*
 * One quadrant of the tooth chart. Responsible for rendering the array of checkboxes, and label for
 * each quadrant. The start and end values are inclusive, so start=1 end=3 will render 1,2,3
 */
const CheckBoxes = ({
  quadrant,
  start,
  end,
  values,
  readOnly,
  onChange,
  reversed,
  disabled,
  notation,
}: ToothCheckBoxesProps) => {
  // TODO Flag this
  const { 'enable-new-tooth-chart': enableNewToothChart } = useFlags();

  const Checkbox = enableNewToothChart ? DynamicTooth : LegacyCheckbox;
  const isPalmer = notation === DentalNotationOptions.Palmer;
  const isFDI = notation === DentalNotationOptions.Fdi;
  //Creates a list if indicies from start to end, inclusive on both ends
  //Note element is unused here because it's all undefined
  let range: number[] = Array.from(
    new Array(end - start + 1),
    (_, index) => index + start
  );

  if (reversed) {
    range = range.reverse();
  }

  const isChecked = (index: number) => {
    if (isPalmer) {
      index = convertFromPalmerToUniversal(quadrant, index);
    } else if (isFDI) {
      index = convertFromFDIToUniversal(quadrant, index);
    }
    return values.includes(index);
  };

  return (
    <CheckBoxesContainer>
      {range.map((index) => {
        const isCheckedValue = isChecked(index);
        return (
          <CheckBoxContainer
            bottomRow={[3, 4].includes(quadrant)} // bottom row quadrants have a different style
            key={index}
            enableNewToothChart={enableNewToothChart}
          >
            <Checkbox
              index={index.toString()}
              checked={isCheckedValue}
              readOnly={readOnly}
              onChange={onChange}
              disabled={disabled!}
              quadrant={quadrant}
              notation={notation}
            />
            <label>{index}</label>
          </CheckBoxContainer>
        );
      })}
    </CheckBoxesContainer>
  );
};

export type ToothCheckboxProps = {
  index: string;
  checked: boolean;
  readOnly: boolean;
  onChange: (quadrant: number, index: number, value: boolean) => void;
  disabled: boolean;
  quadrant: number;
  notation?: DentalNotationOptions;
};
const LegacyCheckbox = ({
  index,
  checked,
  readOnly,
  onChange,
  disabled,
  quadrant,
}: ToothCheckboxProps) => {
  if (readOnly) {
    return checked ? <CheckSvg /> : <div> </div>;
  }
  return (
    <input
      type="checkbox"
      id={index}
      name={index}
      checked={checked}
      disabled={disabled}
      onChange={(e) => {
        onChange(quadrant, parseInt(e.target.id), e.target.checked);
      }}
    />
  );
};

export default ToothChart;
