import React, { useContext, useState } from 'react';
import moment from 'moment';
import * as Sentry from '@sentry/react';
import { Formik, FormikErrors } from 'formik';
import { Modal, NotificationContext } from 'core/components';
import EditPatientForm from 'components/EditPatientModal/EditPatientForm';

import { formatPhoneNumber } from 'utils/customer';
import { sexToDisplay } from 'constants/sex';

import pick from 'lodash/pick';
import {
  useUpdatePatientInfoMutation,
  UpdateCustomerInput,
  AddressType,
  UsCanadaAdminRegions,
  Gender,
  CandidProCustomerQuery,
  UpdateAddressFields,
  UpdateCustomerFields,
  AddressValidatedBy,
  GenericAddressValidatedBy,
} from 'generated/legacy/graphql';

export type EditPatientInfo = {
  firstName: string;
  middleName: string;
  lastName: string;
  sex: string;
  phone: string;
  preferredName: string;
  birthday: string;
  email: string;
  addressLine1: string;
  addressLine2: string;
  businessName: string;
  city: string;
  stateCode: string;
  countryCode: string;
  zip: string;
  validatedBy: AddressValidatedBy | GenericAddressValidatedBy | null;
  guardian_preferredName: string;
  guardian_firstName: string;
  guardian_middleName: string;
  guardian_lastName: string;
  guardian_phone: string;
  guardian_sex: string;
  guardian_birthday: string;
};

export type EditPatientError = {
  email: boolean;
  birthDate: boolean;
};

type EditPatientModalProps = {
  isOpen?: boolean;
  onClose: () => void;
  onSuccess: () => void;
  showShippingNote?: boolean;
  patientData: CandidProCustomerQuery['customer'];
  shippingAddress: AddressType;
};

const validateForm = (values: EditPatientInfo) => {
  const errors: FormikErrors<EditPatientInfo> = {};
  if (!values['birthday'].trim()) {
    errors['birthday'] = 'Please input patient birthday';
  } else {
    const birthDate = moment(values['birthday']);
    const threeMonthsAgo = moment().subtract(3, 'months');

    if (birthDate.isAfter(threeMonthsAgo)) {
      errors['birthday'] = 'Patient must be at least 3 months old';
    }
  }
  if (!values['email'].trim()) {
    errors['email'] = 'Please input patient email';
  }
  if (!values['addressLine1'].trim()) {
    errors['addressLine1'] = 'Please input patient address';
  }
  if (!values['city'].trim()) {
    errors['city'] = 'Please input patient city';
  }
  if (!values['zip'].trim()) {
    errors['zip'] = 'Please input patient zipcode';
  }
  const phoneValidation = formatPhoneNumber(values['phone']);
  if (!phoneValidation.valid) {
    errors['phone'] = 'Please input correct phone number';
  }

  return errors;
};

const EditPatientModal = ({
  isOpen,
  onClose,
  onSuccess,
  showShippingNote = true,
  patientData,
  shippingAddress,
}: EditPatientModalProps) => {
  const legalGuardian = patientData?.legalGuardian;
  const { showNotification } = useContext(NotificationContext);
  const {
    preferredName: guardianPreferredName,
    firstName: guardianFirstName,
    lastName: guardianLastName,
    middleName: guardianMiddleName,
    phone: guardianPhone,
    sex: guardianSex,
    birthday: guardianBirthday,
  } = legalGuardian || {};

  const patientInfo = {
    firstName: patientData?.firstName ?? '',
    middleName: patientData?.middleName ?? '',
    lastName: patientData?.lastName ?? '',
    phone: formatPhoneNumber(patientData?.phone).formatedPhoneNumber ?? '',
    preferredName: patientData?.preferredName ?? '',
    birthday: patientData?.birthday ?? '',
    sex: patientData?.sex ?? '',
    email: patientData?.user?.email ?? '',
    addressLine1: shippingAddress?.addressLine1 ?? '',
    addressLine2: shippingAddress?.addressLine2 ?? '',
    businessName: shippingAddress?.businessName ?? '',
    city: shippingAddress?.city ?? '',
    stateCode: shippingAddress?.stateCode ?? '',
    countryCode: shippingAddress?.countryCode ?? '',
    zip: shippingAddress?.zip ?? '',
    validatedBy: shippingAddress?.validatedBy ?? null,
    guardian_preferredName: guardianPreferredName || '',
    guardian_firstName: guardianFirstName || '',
    guardian_middleName: guardianMiddleName || '',
    guardian_lastName: guardianLastName || '',
    guardian_phone: guardianPhone || '',
    guardian_sex: guardianSex || '',
    guardian_birthday: guardianBirthday || '',
  };

  const submitButtonRef = React.useRef<HTMLButtonElement>(null);
  const defaultError = {
    email: false,
    birthDate: false,
  };

  const [isEditingPatient, setIsEditingPatient] = useState(false);

  const [EditPatientInfoErrorMessage, setEditPatientInfoErrorMessage] =
    useState<EditPatientError>(defaultError);

  const [updatePatientInfo] = useUpdatePatientInfoMutation({
    onError(error) {
      const errorMsg = error.graphQLErrors[0].message;
      //Would be nice if there was something to grab here, but oh well
      if (errorMsg.includes('Unable to update address.')) {
        showNotification(
          'Error updating address. Please check the address and try again',
          'error'
        );
      } else if (errorMsg.includes('birthday')) {
        showNotification(
          'Patient must be at least 3 months old and birthday cannot be in the future',
          'error'
        );
      } else {
        //Should there be a more helpful error?
        showNotification(
          'Could not update patient information, please check the entered information and try again',
          'error'
        );
        Sentry.captureException(error);
      }
    },
    onCompleted() {
      onSuccess();
    },
  });

  const handleSaveEditPatient = async (data: EditPatientInfo) => {
    setEditPatientInfoErrorMessage(defaultError);

    const momentDob = moment(data.birthday || '');
    const birthday = momentDob.format('YYYY-MM-DD');

    const updates: UpdateCustomerFields = {
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      phone: data.phone,
      preferredName: data.preferredName,
      birthday: birthday,
      sex: data.sex,
      email: data.email,
    };

    const guardianMomentDob = moment(data.guardian_birthday || '');
    const guardianBirthday = guardianMomentDob.format('YYYY-MM-DD');

    if (legalGuardian && legalGuardian.birthday) {
      updates.legalGuardian = {
        firstName: data.guardian_firstName,
        middleName: data.guardian_middleName,
        lastName: data.guardian_lastName,
        birthday: guardianBirthday,
        preferredName: data.guardian_preferredName,
        phone: data.guardian_phone,
        sex: sexToDisplay[data.guardian_sex] as Gender,
      };
    }

    const customerInput: UpdateCustomerInput = {
      customerId: patientData?.id ?? '',
      updates: updates,
    };

    try {
      setIsEditingPatient(true);
      await updatePatientInfo({
        variables: {
          customerInput,
          shippingAddressInput: {
            ...pick(data, [
              'addressLine1',
              'addressLine2',
              'phone',
              'city',
              'zip',
            ]),
            stateCode: data.stateCode as UsCanadaAdminRegions,
            countryCode: data.countryCode,
            businessName: data.businessName,
          } as UpdateAddressFields,
          shippingAddressID: shippingAddress?.id as string,
        },
      });
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }

      setEditPatientInfoErrorMessage({
        email: err?.message.includes('email'),
        birthDate: err?.message.includes('birth'),
      });
    } finally {
      setIsEditingPatient(false);
    }
  };

  const handleOnClose = () => {
    setEditPatientInfoErrorMessage(defaultError);
    onClose();
  };

  const onSubmit = async (_: EditPatientInfo) => {
    if (submitButtonRef.current) {
      submitButtonRef.current.click();
    }
  };

  const QuarantinedForm = ({ children }: { children: React.ReactNode }) => {
    // prevent propagation of form submit event to parent form
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {children}
      </form>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {}} hideCloseButton>
      <Formik
        enableReinitialize
        initialValues={patientInfo}
        onSubmit={onSubmit}
        validate={validateForm}
      >
        {isOpen && (
          <QuarantinedForm>
            <EditPatientForm
              showShippingNote={showShippingNote}
              handleCloseModal={handleOnClose}
              editErrors={EditPatientInfoErrorMessage}
              submitForm={handleSaveEditPatient}
              submitButtonRef={submitButtonRef}
              isSubmitting={isEditingPatient}
              patientData={patientData}
            />
          </QuarantinedForm>
        )}
      </Formik>
    </Modal>
  );
};

export default EditPatientModal;
