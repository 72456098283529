import React, { useState, useEffect } from 'react';
import { Grid } from 'core/components';
import { Loading } from 'core/components';
import api, { ExtractReturnType } from 'state/api';
import RedX from 'assets/delete.svg?react';
import { Status } from 'generated/core/graphql';
import styled from 'styled-components/macro';
import CopyableText from 'components/CopyableText';

const ErrorMessage = styled(Grid)`
  font-family: 'Modern Era Mono';
  max-width: 50%;
  max-height: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type ViewerRequestUpdateType = NonNullable<
  ExtractReturnType<typeof api.useLazyGetLatestViewerUpdateRequestQuery>
>;

export const TreatmentPlanLoader = () => (
  <Grid
    container
    alignItems="center"
    justifyContent="center"
    direction="column"
    style={{ height: '100%' }}
  >
    <Grid item>Treatment plan viewer is being generated...</Grid>
    <Grid item>
      <Loading />
    </Grid>
  </Grid>
);

export const TreatmentPlanLoaderFailed = ({
  viewerUpdateRequests,
}: {
  viewerUpdateRequests: ViewerRequestUpdateType;
}) => (
  <Grid
    container
    alignItems="center"
    justifyContent="center"
    direction="column"
    style={{ height: '100%', gap: '2rem' }}
  >
    <Grid
      item
      container
      alignItems="center"
      justifyContent="center"
      direction="row"
      style={{ gap: '1rem' }}
    >
      <Grid item>Treatment plan viewer has failed to generate</Grid>
      <Grid item>
        <RedX />
      </Grid>
    </Grid>
    <Grid
      container
      direction="column"
      alignItems="center"
      style={{ gap: '1rem' }}
    >
      <Grid container direction="row" justifyContent="center">
        <CopyableText
          label="Failure Reason"
          text={viewerUpdateRequests?.failureReason || ''}
        />
        <Grid>Copy the error message</Grid>
      </Grid>
      <ErrorMessage>{viewerUpdateRequests?.failureReason}</ErrorMessage>
    </Grid>
  </Grid>
);

const TreatmentPlanViewer = ({
  IFrameComponent,
  tpStagingId,
}: {
  IFrameComponent: React.ComponentType<
    React.IframeHTMLAttributes<HTMLIFrameElement>
  >;
  tpStagingId: string;
}) => {
  const [tpViewerUrl, setTpViewerUrl] = useState<string | null>(null);
  const [pollingInterval, setPollingInterval] = useState(0);
  const [getLatestViewer, latestViewerRequest] =
    api.useLazyGetLatestViewerUpdateRequestQuery({ pollingInterval });
  const [getTpStaging] = api.useLazyGetTreatmentPlanStagingByIdQuery();

  useEffect(() => {
    getTpStaging({ id: tpStagingId }).then((response) => {
      if (response?.data?.url) {
        setTpViewerUrl(response.data.url);
      } else {
        setPollingInterval(5000);
        getLatestViewer({
          treatmentPlanStagingId: tpStagingId,
        });
      }
    });
  }, [tpStagingId]);
  useEffect(() => {
    if (
      latestViewerRequest.status === 'fulfilled' &&
      latestViewerRequest.data?.status !== Status.Pending
    ) {
      setPollingInterval(0);

      if (latestViewerRequest.data?.status === Status.Done) {
        getTpStaging({ id: tpStagingId }).then((response) => {
          if (response?.data?.url) {
            setTpViewerUrl(response.data.url);
          }
        });
      }
    }
  }, [latestViewerRequest.data, latestViewerRequest.status]);

  return tpViewerUrl ? (
    <IFrameComponent scrolling="no" src={tpViewerUrl} />
  ) : latestViewerRequest.data?.status === Status.Failed ? (
    <TreatmentPlanLoaderFailed
      viewerUpdateRequests={latestViewerRequest.data}
    />
  ) : (
    <TreatmentPlanLoader />
  );
};

export default TreatmentPlanViewer;
