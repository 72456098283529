import { Paper } from '@material-ui/core';
import { VirtualizedTable } from 'components/VirtualizedTable';
import styled from 'styled-components';

export const StyledVirtualizedTable = styled(VirtualizedTable)`
  * .dropdown-cell {
    overflow: visible !important;
  }

  .ReactVirtualized__Table__row {
    overflow: visible !important;
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }
`;

export const CollapsedNotesCell = styled.div`
  transform: translate(calc(-1rem - 0.5px), 0);
  padding: 1rem 0 1rem 1rem;
  overflow: hidden !important;
  > p {
    text-overflow: ellipsis;
    overflow: hidden;
    whitespace: nowrap;
    width: 100%;
    margin: 0;
  }
`;

export const ExpandedNotesCell = styled(Paper)`
  position: fixed;
  transform: translate(calc(-1rem - 0.5px), calc(50% - 1.75rem + 1px));
  z-index: 1000;
  width: max-content;
  max-width: 300px;
  padding: 1rem;
  margin: 0;
  overflow: visible !important;
  word-wrap: break-word;
  white-space: normal;
  > p {
    overflow: visible;
    word-wrap: break-word;
    white-space: normal;
    margin: 0;
  }
`;
