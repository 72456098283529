import React, { useEffect, useState } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import DocumentTitle from 'components/DocumentTitle';
import { TreatmentPlanLoader } from 'components/TreatmentPlanViewer';
import { useQuery } from 'hooks/useQuery';
import { useLazyQuery } from 'utils/lazyQuery';
import {
  GetTreatmentPlanUrlDocument,
  GetTreatmentPlanUrlQuery,
  GetTreatmentPlanUrlQueryVariables,
} from 'generated/legacy/graphql';
import * as Sentry from '@sentry/react';
import { TreatmentPlanIframe } from 'pages/TreatmentPlanViewer/TreatmentPlanViewer.css';
import api from 'state/api';
import { Status } from 'generated/core/graphql';

const TreatmentPlanViewer = ({ match }: RouteComponentProps) => {
  const query = useQuery();
  const treatmentPlanStagingId = query.get('treatment_plan_staging_id');
  const treatmentPlanUrlQuery = useLazyQuery<
    GetTreatmentPlanUrlQuery,
    GetTreatmentPlanUrlQueryVariables
  >(GetTreatmentPlanUrlDocument);
  const [treatmentPlanViewerLink, setTreatmentPlanViewerLink] = useState('');
  const [pollingInterval, setPollingInterval] = useState(0);
  const [getLatestViewer, latestViewerRequest] =
    api.useLazyGetLatestViewerUpdateRequestQuery({ pollingInterval });

  const fetchSignedAssetUrl = async ({
    onError,
  }: { onError?: () => void } = {}) => {
    if (!treatmentPlanStagingId) {
      return;
    }

    try {
      const { data } = await treatmentPlanUrlQuery({
        treatmentPlanStagingId,
      });

      setTreatmentPlanViewerLink(data.treatmentPlanUrl);
    } catch {
      if (onError) {
        onError();
      }

      Sentry.captureException(
        `Could not generate Treatment Plan URL for treatmentPlanStagingId: ${treatmentPlanStagingId}`
      );
    }
  };

  useEffect(() => {
    if (!treatmentPlanStagingId) {
      return;
    }

    fetchSignedAssetUrl({
      onError: () => {
        // On first attempt to fetch the URL, if it fails, start polling to see if there's a viewer request pending
        setPollingInterval(5000);
        getLatestViewer({
          treatmentPlanStagingId,
        });
      },
    });
  }, [treatmentPlanStagingId]);

  useEffect(() => {
    if (latestViewerRequest.data?.status !== Status.Pending) {
      // If there's no viewer request pending, stop polling
      setPollingInterval(0);
    }

    if (latestViewerRequest.data?.status === Status.Done) {
      // Request found and viewer is ready
      fetchSignedAssetUrl();
    }
  }, [latestViewerRequest.data?.status]);

  const showLoader =
    latestViewerRequest.isFetching ||
    (!treatmentPlanViewerLink &&
      latestViewerRequest.data?.status === Status.Pending);

  return (
    <Switch>
      <Route path={match.path} exact>
        <DocumentTitle title="Treatment Plan Viewer">
          {showLoader ? (
            <TreatmentPlanLoader />
          ) : (
            <TreatmentPlanIframe
              title={'Dynamic Treatment Plan Viewer'}
              src={treatmentPlanViewerLink}
            />
          )}
        </DocumentTitle>
      </Route>
    </Switch>
  );
};

export default TreatmentPlanViewer;
