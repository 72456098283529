import { useState, useEffect } from 'react';
import { RESERVED_QUESTION_KEYS } from 'constants/treatmentObjective';
import { FormikValues } from 'formik';
import {
  Template,
  TemplateType,
  TreatmentObjectiveQuestionTypes,
} from 'generated/core/graphql';
import api from 'state/api';
import moment from 'moment';

// Create type for the parameters
type UseTreatmentObjectiveParams = {
  userId: string | undefined;
  caseType: string | undefined;
  caseRef: string | undefined;
  userAccountPreferencesTemplateId?: string | null | undefined; // Optional if we want to force using the user account preferences in the logged in user instead of the submitted treatment objective in the form
};

const useTreatmentObjective = ({
  userId,
  caseType,
  caseRef,
  userAccountPreferencesTemplateId,
}: UseTreatmentObjectiveParams) => {
  const [initialValues, setInitialValues] = useState({});
  const [currentFormValues, setCurrentFormValues] = useState<FormikValues>({});
  const [selectedCaseTemplate, setSelectedCaseTemplate] =
    useState<Template | null>(null);
  const [
    getLatestTreatmentObjectiveWithQuestions,
    { data: latestTreatmentObjectiveWithQuestions },
  ] = api.useLazyGetLatestTreatmentObjectiveWithQuestionsQuery({});
  const [
    getSystemTemplates,
    {
      data: systemTemplates = [],
      isLoading: isLoadingGetLatestTreatmentObjectiveWithQuestions,
    },
  ] = api.useLazyGetSystemTemplatesQuery({});
  const [getTemplate, { data: originatedTemplate }] =
    api.useLazyGetTemplateQuery();
  const [
    getUserClinicalPreferencesTemplate,
    { data: clinicalPreferencesTemplate },
  ] = api.useLazyGetTemplateQuery();
  const [
    getCaseTemplates,
    { data: caseTemplates = [], isLoading: isLoadingCaseTemplates },
  ] = api.useLazyGetUserTemplatesQuery();

  useEffect(() => {
    if (!userId || !caseRef) {
      return;
    }
    getLatestTreatmentObjectiveWithQuestions({
      caseRef: caseRef,
    });
    getSystemTemplates({
      caseType: caseType,
      type: TemplateType.CaseTemplate,
    });
    const [caseTemplateSection, userClinicalTemplateSection] =
      latestTreatmentObjectiveWithQuestions?.data.templateSections ?? [];

    if (caseTemplateSection?.templateId) {
      getTemplate({
        templateId: caseTemplateSection?.templateId,
      });
    }
    const templateId =
      userAccountPreferencesTemplateId ||
      userClinicalTemplateSection?.templateId;

    if (templateId) {
      getUserClinicalPreferencesTemplate({ templateId });
    }

    getCaseTemplates({
      userId: Number(userId),
      type: TemplateType.CaseTemplate,
      caseType: caseType,
    });
  }, [
    userId,
    caseRef,
    latestTreatmentObjectiveWithQuestions?.data.templateSections[0]?.templateId,
  ]);

  useEffect(() => {
    const { templateSections, chiefComplaint, toothCharts } =
      latestTreatmentObjectiveWithQuestions?.data ?? {};
    // We want to know if the user is changing the template so we can keep the values that are not in the new template the same on the form
    const isChangingTemplate =
      selectedCaseTemplate?.id !== originatedTemplate?.id;
    const fetchedTemplateAnswers: [string, string | boolean | string[]][] = [];
    for (const sections of templateSections ?? []) {
      for (const questions of sections.sections) {
        for (const question of questions?.questions ?? []) {
          fetchedTemplateAnswers.push([question.questionId, question.answer]);
          // if the question is multi select, we need to add the question id with the option text
          if (question.type === TreatmentObjectiveQuestionTypes.MultiSelect) {
            for (const option of question.answer ?? []) {
              fetchedTemplateAnswers.push([
                `${question.questionId}-${option}`,
                true,
              ]);
            }
          }
          for (const followUp of question.followUpQuestions ?? []) {
            if (followUp.type === TreatmentObjectiveQuestionTypes.MultiSelect) {
              for (const option of followUp.answer ?? []) {
                fetchedTemplateAnswers.push([
                  `${followUp.followUpQuestionId}-${option}`,
                  true,
                ]);
              }
            } else {
              fetchedTemplateAnswers.push([
                followUp.followUpQuestionId,
                followUp.answer,
              ]);
            }
          }
        }
      }
    }
    setInitialValues({
      [RESERVED_QUESTION_KEYS.CHIEF_COMPLAINT]: isChangingTemplate
        ? currentFormValues[RESERVED_QUESTION_KEYS.CHIEF_COMPLAINT]
        : chiefComplaint,
      [RESERVED_QUESTION_KEYS.CANNOT_MOVE]: isChangingTemplate
        ? currentFormValues[RESERVED_QUESTION_KEYS.CANNOT_MOVE]
        : toothCharts?.cannotMove,
      [RESERVED_QUESTION_KEYS.WILL_EXTRACT]: isChangingTemplate
        ? currentFormValues[RESERVED_QUESTION_KEYS.WILL_EXTRACT]
        : toothCharts?.willExtract,
      [RESERVED_QUESTION_KEYS.ERUPTION_COMPENSATION]: isChangingTemplate
        ? currentFormValues[RESERVED_QUESTION_KEYS.ERUPTION_COMPENSATION]
        : toothCharts?.eruptionCompensation,
      ...Object.fromEntries(fetchedTemplateAnswers),
      ...JSON.parse(selectedCaseTemplate?.userAnswers?.root ?? '{}'),
    });
  }, [latestTreatmentObjectiveWithQuestions?.id, selectedCaseTemplate?.id]);

  return {
    initialValues,
    latestTreatmentObjectiveWithQuestions,
    systemTemplates: systemTemplates?.sort((a, b) =>
      moment(b.createdAt).diff(moment(a.createdAt))
    ), // Sort by createdAt date by the latest
    originatedTemplate: originatedTemplate,
    clinicalPreferencesTemplate: clinicalPreferencesTemplate,
    caseTemplates: caseTemplates,
    isLoadingGetLatestTreatmentObjectiveWithQuestions,
    isLoadingCaseTemplates,
    selectedCaseTemplate,
    setSelectedCaseTemplate,
    setCurrentFormValues,
  };
};

export default useTreatmentObjective;
