import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loading } from 'core/components';
import moment from 'moment';

import { formatIntakeSections } from 'utils/intake';
import VersionPills, { useVersionPills } from 'components/StyledVersionPills';
import { determineIntakeName } from 'utils/intake';
import TreatmentObjectives from 'components/TreatmentObjectives/TreatmentObjectives';

import {
  selectCompletedIntakeForms,
  selectSelectedCase,
  selectPatientDoctorPreferences,
  selectPatient,
} from 'pages/Patient/patientSlice';
import { DentalNotationOptions } from 'generated/legacy/graphql';
import {
  IntakeSection,
  TreatmentObjectiveTemplateSection,
} from 'generated/core/graphql';
import { usePatientLoadingStates } from 'pages/Patient/utils';
import SurveySection from 'components/Intake/SurveySection';
import {
  TgWrapper,
  TgDetailHeader,
  TgHeaderContainer,
  TgSubmissionInformation,
} from 'pages/Patient/PatientDetail/DiagnosticMaterials/DiagnosticMaterials.css';
import { DATE_TIME_FORMAT } from 'constants/treatmentObjective';
import { convertTemplateSectionToReadOnlyIntake } from 'utils/treatmentObjective';
import useTreatmentObjective from 'hooks/useTreatmentObjective';
import { Formik } from 'formik';
import { Template } from 'generated/core/graphql';

const TreatmentGoalsTab = () => {
  const intakeForms = useSelector(selectCompletedIntakeForms);
  const doctorPreferences = useSelector(selectPatientDoctorPreferences);
  const { isFetchingTGs } = usePatientLoadingStates();
  const selectedCase = useSelector(selectSelectedCase);
  const patient = useSelector(selectPatient);
  const vpProps = useVersionPills(intakeForms ?? [], true);
  const intakeForm = intakeForms?.[vpProps.currentIndex];
  const [formattedIntakeSections, setFormattedIntakeSections] = useState<
    IntakeSection[]
  >([]);
  const [[intakeName, submissionSubtext], setIntakeInfo] = useState(['', '']);

  const {
    initialValues,
    originatedTemplate,
    systemTemplates,
    latestTreatmentObjectiveWithQuestions,
    clinicalPreferencesTemplate,
  } = useTreatmentObjective({
    userId: patient?.referringDentist?.id,
    caseType: selectedCase?.caseType.name,
    caseRef: selectedCase?.caseRef,
  });

  useEffect(() => {
    if (latestTreatmentObjectiveWithQuestions) {
      const { chiefComplaint, toothCharts, templateSections } =
        latestTreatmentObjectiveWithQuestions.data;

      setFormattedIntakeSections([
        {
          answers: convertTemplateSectionToReadOnlyIntake(
            templateSections as TreatmentObjectiveTemplateSection[],
            chiefComplaint,
            toothCharts
          ),
          label: 'Treatment Objectives',
        },
      ]);

      const intakeSubmissionTime = moment(
        latestTreatmentObjectiveWithQuestions.createdAt
      ).format(DATE_TIME_FORMAT);
      const submissionSubtext = `Submitted by ${latestTreatmentObjectiveWithQuestions.createdByEmail}, at ${intakeSubmissionTime}`;

      setIntakeInfo(['Treatment Objectives', submissionSubtext]);
    } else if (intakeForm) {
      const intakeName = determineIntakeName(selectedCase?.isGen2);
      setFormattedIntakeSections(
        formatIntakeSections(intakeForm.data.sections)
      );
      const intakeSubmissionTime = moment(intakeForm?.signedDate!).format(
        DATE_TIME_FORMAT
      );
      const submissionSubtext = `Submitted by ${intakeForm?.createdByEmail}, at ${intakeSubmissionTime}`;
      setIntakeInfo([intakeName, submissionSubtext]);
    }
  }, [latestTreatmentObjectiveWithQuestions, intakeForm]);

  if (!intakeForm && !latestTreatmentObjectiveWithQuestions) {
    return null;
  }

  if (isFetchingTGs) {
    return <Loading />;
  }

  return (
    <TgWrapper>
      {!selectedCase?.isGen2 && <VersionPills {...vpProps} />}
      <TgHeaderContainer>
        <div>
          <TgDetailHeader>{intakeName}</TgDetailHeader>
          <TgSubmissionInformation>{submissionSubtext}</TgSubmissionInformation>
        </div>
      </TgHeaderContainer>

      {latestTreatmentObjectiveWithQuestions && originatedTemplate ? (
        <Formik
          initialValues={initialValues}
          onSubmit={() => {}}
          enableReinitialize
        >
          <TreatmentObjectives
            systemTemplates={
              [originatedTemplate || systemTemplates] as Template[]
            }
            showDescription={false}
            canEditSubmission={false}
            dentalNotation={
              doctorPreferences?.dentalNotation as DentalNotationOptions
            }
            clinicalPreferencesTemplate={
              clinicalPreferencesTemplate as Template
            }
          />
        </Formik>
      ) : (
        formattedIntakeSections.map((section, index) => {
          return (
            <SurveySection
              section={section}
              displayDivider={index < formattedIntakeSections.length - 1}
              key={index}
              dentalNotation={
                doctorPreferences?.dentalNotation as DentalNotationOptions
              }
            />
          );
        })
      )}
    </TgWrapper>
  );
};

export default TreatmentGoalsTab;
