import {
  ButtonContainer,
  EditButton,
  EditorHeaderContainer,
  LengthTracker,
  NoteEditorContainer,
  StyledTextArea,
  StyledEditIcon,
  StyledClockIcon,
  LastModifiedText,
} from 'pages/Patient/PatientDetail/NoteEditor/NoteEditor.css';
import { Button, NotificationContext, Skeleton, theme } from 'core/components';
import { Maybe, Note } from 'generated/core/graphql';
import React, { useContext, useEffect, useState } from 'react';
import api from 'state/api';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getBrandFromDomain, getBrandSupportedFeatures } from 'utils/brands';
import { useMediaQuery } from '@material-ui/core';
import { AuthContext } from 'components/AuthProvider';
import moment from 'moment';
const { UsePatientNotes: brandEnablesPatientNotes } =
  getBrandSupportedFeatures(getBrandFromDomain());

type NoteEditorProps = { patientId: string; patientReferringDentistId: string };

type EditorHeaderProps = {
  userIsReferringDentist: boolean;
  canEditNoteInput: boolean;
  setIsEditingNote: (value: boolean) => void;
};

type EditorBodyProps = {
  loading: boolean;
  isDesktop: boolean;
  noteTextInputValue: string;
  setNoteTextInputValue: (value: string) => void;
  isEditingNote: boolean;
};

type EditorButtonsProps = {
  note: Maybe<Note>;
  isEditingNote: boolean;
  handleCancelEdit: () => void;
  isSavingNote: boolean;
  handleSaveNote: () => void;
  canSaveNote: boolean;
};

const EditorHeader = ({
  userIsReferringDentist,
  canEditNoteInput,
  setIsEditingNote,
}: EditorHeaderProps) => (
  <EditorHeaderContainer>
    <h3>Notes</h3>
    {userIsReferringDentist && (
      <EditButton
        onClick={() => setIsEditingNote(true)}
        disabled={!canEditNoteInput}
      >
        <StyledEditIcon />
      </EditButton>
    )}
  </EditorHeaderContainer>
);

const EditorBody = ({
  loading,
  isDesktop,
  noteTextInputValue,
  setNoteTextInputValue,
  isEditingNote,
}: EditorBodyProps) => (
  <div>
    {loading ? (
      <Skeleton variant="rect" height={isDesktop ? 250 : 100} />
    ) : (
      <StyledTextArea
        value={noteTextInputValue}
        onChange={(e) => setNoteTextInputValue(e.target.value)}
        disabled={!isEditingNote}
      />
    )}
  </div>
);

const EditorButtons = ({
  handleCancelEdit,
  isSavingNote,
  handleSaveNote,
  canSaveNote,
}: EditorButtonsProps) => (
  <>
    <Button
      onClick={handleCancelEdit}
      type="button"
      buttonSize="small"
      buttonType="tertiary"
    >
      Cancel
    </Button>
    <Button
      onClick={handleSaveNote}
      disabled={!canSaveNote}
      isLoading={isSavingNote}
      type="button"
      buttonSize="small"
      buttonType="secondary"
    >
      Save
    </Button>
  </>
);

const NoteEditor = ({
  patientId,
  patientReferringDentistId,
}: NoteEditorProps) => {
  const { showNotification } = useContext(NotificationContext);
  const { userInfo } = useContext(AuthContext);
  const userIsReferringDentist =
    userInfo?.doctor?.id === patientReferringDentistId;

  const { 'enable-patient-notes': featureFlagEnablesPatientNotes } = useFlags();
  const isDesktop = useMediaQuery(theme.mediaQueries.tabletAndAbove);

  const enablePatientNotes =
    brandEnablesPatientNotes || featureFlagEnablesPatientNotes;

  const {
    useGetPatientNoteQuery,
    useUpdatePatientNoteMutation,
    useCreatePatientNoteMutation,
  } = api;

  const { data: noteData, isLoading: isLoadingNote } = useGetPatientNoteQuery(
    {
      patientId,
    },
    {
      skip: !enablePatientNotes,
    }
  );

  const [note, setNote] = useState<Maybe<Note>>(noteData ?? null);
  const [noteTextInputValue, setNoteTextInputValue] = useState(
    noteData?.text || ''
  );
  const [isEditingNote, setIsEditingNote] = useState(false);
  const [createNote, { isLoading: isCreatingNote }] =
    useCreatePatientNoteMutation();
  const [updateNote, { isLoading: isUpdatingNote }] =
    useUpdatePatientNoteMutation();

  useEffect(() => {
    if (noteData) {
      setNote(noteData);
      setNoteTextInputValue(noteData.text || '');
    }
  }, [noteData]);

  const isSavingNote = isCreatingNote || isUpdatingNote;

  // you can edit the input if the note isn't loading, and
  // it's not already being edited or saved
  const canEditNoteInput =
    userIsReferringDentist && !isLoadingNote && !isEditingNote && !isSavingNote;

  // you can create a note if it doesn't exist and it's not
  // still being loaded, or already being created
  const canCreateNote = !isLoadingNote && !isCreatingNote && !note;

  // you can update a note if it does exist after
  // being loaded, and it's not already being updated
  const canUpdateNote = !isLoadingNote && !isUpdatingNote && !!note;

  // you can save a note if you can create or update it
  const canSaveNote = canUpdateNote || canCreateNote;

  const handleCreateNote = async () => {
    try {
      const result = await createNote({
        input: { patientId, text: noteTextInputValue },
      }).unwrap();
      if (!result?.note) {
        showNotification('Failed to create note', 'error');
        return;
      }
      setNote(result.note);
      setIsEditingNote(false);
    } catch (e) {
      if (e instanceof Object && 'message' in e) {
        showNotification(e.message as string, 'error');
      }
    }
  };

  const handleUpdateNote = async (updatedNote: Note) => {
    try {
      const result = await updateNote({
        input: { noteId: updatedNote.id, text: noteTextInputValue },
      }).unwrap();
      if (!result?.note) {
        showNotification('Failed to save note', 'error');
        return;
      }
      setNote(result.note);
      setIsEditingNote(false);
    } catch (e) {
      if (e instanceof Object && 'message' in e) {
        showNotification(e.message as string, 'error');
      }
    }
  };

  const handleCancelEdit = () => {
    setIsEditingNote(false);
    setNoteTextInputValue(note?.text || '');
  };

  const handleSaveNote = async () => {
    if (!note) {
      handleCreateNote();
    } else {
      handleUpdateNote(note);
    }
  };

  return (
    enablePatientNotes && (
      <NoteEditorContainer>
        <EditorHeader
          userIsReferringDentist={userIsReferringDentist}
          canEditNoteInput={canEditNoteInput}
          setIsEditingNote={setIsEditingNote}
        />
        <EditorBody
          loading={isLoadingNote}
          isDesktop={isDesktop}
          noteTextInputValue={noteTextInputValue}
          setNoteTextInputValue={setNoteTextInputValue}
          isEditingNote={isEditingNote}
        />
        <ButtonContainer>
          {isEditingNote ? (
            <>
              <LengthTracker text={noteTextInputValue} maxLength={250} />
              <EditorButtons
                note={note}
                isEditingNote={isEditingNote}
                handleCancelEdit={handleCancelEdit}
                isSavingNote={isSavingNote}
                handleSaveNote={handleSaveNote}
                canSaveNote={canSaveNote}
              />
            </>
          ) : (
            note && (
              <LastModifiedText>
                <StyledClockIcon />
                <p>
                  {moment(note?.updatedAt ?? note?.createdAt).format(
                    'M/DD/YYYY h:mm a'
                  )}
                </p>
              </LastModifiedText>
            )
          )}
        </ButtonContainer>
      </NoteEditorContainer>
    )
  );
};

export default NoteEditor;
