import React from 'react';
import moment from 'moment';

import {
  Author,
  DateTime,
  HistoryList,
  Subtitle,
  Title,
  UnderlineLink,
  LinkRow,
  VersionTitle,
} from 'components/MaterialHistory/History.css';
import { renderRichTextAsHtml } from 'utils/RichText';

export interface IHistoryAttachment {
  filename?: string;
  onClick: () => void;
}

export type HistoryItemType = {
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  dateTime: string;
  title: string;
  notes?: string;
  author?: string;
  attachments?: IHistoryAttachment[];
};

type HistoryItemSectionProps = {
  children: React.ReactNode;
  subtitle: string;
};

const HistoryItemSection = ({
  subtitle,
  children,
}: HistoryItemSectionProps) => (
  <>
    <Subtitle>{subtitle}</Subtitle>
    {children}
  </>
);

const Attachment = ({ attachment }: { attachment: IHistoryAttachment }) => (
  <LinkRow>
    <UnderlineLink onClick={attachment.onClick}>
      {attachment.filename}
    </UnderlineLink>
  </LinkRow>
);

const HistoryItem = ({
  Icon,
  dateTime,
  title,
  notes,
  author,
  attachments,
}: HistoryItemType) => {
  return (
    <li
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {Icon && <Icon stroke="#6F6F6F" aria-hidden />}
        <DateTime>
          {moment(dateTime).format('MMM. D, YYYY [at] h:mma')}
        </DateTime>
        <Title>{title}</Title>
        {author && <Author>{`(${author})`}</Author>}
        {notes && (
          <HistoryItemSection subtitle="Notes">
            {renderRichTextAsHtml(notes)}
          </HistoryItemSection>
        )}
        {!!attachments?.length && (
          <HistoryItemSection subtitle="Attachments">
            {attachments.map((a) => (
              <Attachment key={a.filename} attachment={a} />
            ))}
          </HistoryItemSection>
        )}
      </div>
    </li>
  );
};

type HistorySectionProps = {
  historyItems: HistoryItemType[];
};

const HistorySection = ({ historyItems }: HistorySectionProps) => (
  <HistoryList>
    {historyItems.map((item) => (
      <HistoryItem key={`${item.title}-${item.dateTime}`} {...item} />
    ))}
  </HistoryList>
);

type HistorySectionType = {
  historyItems: HistoryItemType[];
  version?: number;
};

const HistoryVersionSection = ({
  version,
  historyItems,
}: HistorySectionType) => (
  <ul>
    <VersionTitle>Version {version}</VersionTitle>
    <HistorySection historyItems={historyItems} />
  </ul>
);

type HistoryProps = {
  historySections: HistorySectionType[];
};

const History = ({ historySections }: HistoryProps) =>
  historySections.length === 1 ? (
    <HistorySection {...historySections[0]} />
  ) : (
    <>
      {historySections.map((section) => (
        <HistoryVersionSection key={section.version} {...section} />
      ))}
    </>
  );

export default History;
