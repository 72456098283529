export enum PROVIDER_FACING_STATUSES {
  INCOMPLETE_SUBMISSION = 'Incomplete submission',
  MATERIALS_UNDER_REVIEW = 'Materials under review',
  ACTION_NEEDED = 'Action needed',
  TREATMENT_PLANNING_IN_PROGRESS = 'Treatment planning in progress',
  TREATMENT_PLAN_READY_FOR_YOUR_REVIEW = 'Treatment plan ready for your review',
  ALIGNER_KIT_IN_PRODUCTION = 'Aligner kit in production',
  ALIGNER_KIT_SHIPPED = 'Aligner kit shipped',
  ALIGNER_KIT_DELIVERED = 'Aligner kit delivered',
  IN_TREATMENT = 'In treatment',
  IN_RETENTION = 'In retention',
  REFINEMENTS_REQUESTED = 'Refinements requested',
  CASE_CLOSED_INITIAL_FIT_ISSUE = 'Case closed: initial fit issue',
  TREATMENT_COMPLETED = 'Treatment completed',
  CASE_COMPLETED = 'Case completed',
  CASE_EXPIRED = 'Case expired',
  CASE_CANCELED = 'Case canceled',
}

export const POST_TREATMENT_STATUSES = [
  PROVIDER_FACING_STATUSES.ALIGNER_KIT_SHIPPED,
  PROVIDER_FACING_STATUSES.ALIGNER_KIT_DELIVERED,
  PROVIDER_FACING_STATUSES.IN_TREATMENT,
  PROVIDER_FACING_STATUSES.IN_RETENTION,
  PROVIDER_FACING_STATUSES.REFINEMENTS_REQUESTED,
  PROVIDER_FACING_STATUSES.CASE_CLOSED_INITIAL_FIT_ISSUE,
  PROVIDER_FACING_STATUSES.TREATMENT_COMPLETED,
  PROVIDER_FACING_STATUSES.CASE_COMPLETED,
];

export enum ACCORDIONS {
  CASE_TRACKER = 'CaseTracker',
  DIAGNOSTIC_MATERIALS = 'DiagnosticMaterials',
}

export const statusToActiveStepMapping: { [key: string]: number } = {
  [PROVIDER_FACING_STATUSES.INCOMPLETE_SUBMISSION]: 0,
  [PROVIDER_FACING_STATUSES.MATERIALS_UNDER_REVIEW]: 1,
  [PROVIDER_FACING_STATUSES.ACTION_NEEDED]: 1,
  [PROVIDER_FACING_STATUSES.TREATMENT_PLANNING_IN_PROGRESS]: 2,
  [PROVIDER_FACING_STATUSES.TREATMENT_PLAN_READY_FOR_YOUR_REVIEW]: 3,
  [PROVIDER_FACING_STATUSES.ALIGNER_KIT_IN_PRODUCTION]: 4,
  [PROVIDER_FACING_STATUSES.ALIGNER_KIT_SHIPPED]: 5,
  [PROVIDER_FACING_STATUSES.ALIGNER_KIT_DELIVERED]: 6,
  [PROVIDER_FACING_STATUSES.IN_TREATMENT]: 6,
  [PROVIDER_FACING_STATUSES.IN_RETENTION]: 6,
  [PROVIDER_FACING_STATUSES.REFINEMENTS_REQUESTED]: 6,
  [PROVIDER_FACING_STATUSES.CASE_CLOSED_INITIAL_FIT_ISSUE]: 6,
  [PROVIDER_FACING_STATUSES.TREATMENT_COMPLETED]: 6,
  [PROVIDER_FACING_STATUSES.CASE_COMPLETED]: 6,
  [PROVIDER_FACING_STATUSES.CASE_EXPIRED]: -1, // These status is invalid for CaseTracker, we won't be showing CaseTracker for these status
  [PROVIDER_FACING_STATUSES.CASE_CANCELED]: -1, // These status is invalid for CaseTracker, we won't be showing CaseTracker for these status
};

export const retainerStatusToActiveStepMapping: { [key: string]: number } = {
  [PROVIDER_FACING_STATUSES.INCOMPLETE_SUBMISSION]: 0,
  [PROVIDER_FACING_STATUSES.MATERIALS_UNDER_REVIEW]: 1,
  [PROVIDER_FACING_STATUSES.ACTION_NEEDED]: 1,
  [PROVIDER_FACING_STATUSES.TREATMENT_PLANNING_IN_PROGRESS]: 2,
  [PROVIDER_FACING_STATUSES.IN_RETENTION]: 2,
  [PROVIDER_FACING_STATUSES.CASE_EXPIRED]: -1, // These status is invalid for CaseTracker, we won't be showing CaseTracker for these status
  [PROVIDER_FACING_STATUSES.CASE_CANCELED]: -1, // These status is invalid for CaseTracker, we won't be showing CaseTracker for these status
};

type StatusToStepMapping = { [key: string]: boolean };
type StatusToAccordionMapping = {
  [key: string]: StatusToStepMapping;
};

export const statusToDefaultAccordionExpanded: StatusToAccordionMapping = {
  [PROVIDER_FACING_STATUSES.INCOMPLETE_SUBMISSION]: {
    [ACCORDIONS.CASE_TRACKER]: true,
    [ACCORDIONS.DIAGNOSTIC_MATERIALS]: false,
  },
  [PROVIDER_FACING_STATUSES.MATERIALS_UNDER_REVIEW]: {
    [ACCORDIONS.CASE_TRACKER]: true,
    [ACCORDIONS.DIAGNOSTIC_MATERIALS]: false,
  },
  [PROVIDER_FACING_STATUSES.ACTION_NEEDED]: {
    [ACCORDIONS.CASE_TRACKER]: true,
    [ACCORDIONS.DIAGNOSTIC_MATERIALS]: false,
  },
  [PROVIDER_FACING_STATUSES.TREATMENT_PLANNING_IN_PROGRESS]: {
    [ACCORDIONS.CASE_TRACKER]: true,
    [ACCORDIONS.DIAGNOSTIC_MATERIALS]: true,
  },
  [PROVIDER_FACING_STATUSES.TREATMENT_PLAN_READY_FOR_YOUR_REVIEW]: {
    [ACCORDIONS.CASE_TRACKER]: false,
    [ACCORDIONS.DIAGNOSTIC_MATERIALS]: true,
  },
  [PROVIDER_FACING_STATUSES.ALIGNER_KIT_IN_PRODUCTION]: {
    [ACCORDIONS.CASE_TRACKER]: true,
    [ACCORDIONS.DIAGNOSTIC_MATERIALS]: true,
  },
  [PROVIDER_FACING_STATUSES.ALIGNER_KIT_SHIPPED]: {
    [ACCORDIONS.CASE_TRACKER]: true,
    [ACCORDIONS.DIAGNOSTIC_MATERIALS]: true,
  },
  [PROVIDER_FACING_STATUSES.ALIGNER_KIT_DELIVERED]: {
    [ACCORDIONS.CASE_TRACKER]: true,
    [ACCORDIONS.DIAGNOSTIC_MATERIALS]: true,
  },
  [PROVIDER_FACING_STATUSES.IN_TREATMENT]: {
    [ACCORDIONS.CASE_TRACKER]: false,
    [ACCORDIONS.DIAGNOSTIC_MATERIALS]: true,
  },
  [PROVIDER_FACING_STATUSES.IN_RETENTION]: {
    [ACCORDIONS.CASE_TRACKER]: false,
    [ACCORDIONS.DIAGNOSTIC_MATERIALS]: true,
  },
  [PROVIDER_FACING_STATUSES.REFINEMENTS_REQUESTED]: {
    [ACCORDIONS.CASE_TRACKER]: false,
    [ACCORDIONS.DIAGNOSTIC_MATERIALS]: true,
  },
  [PROVIDER_FACING_STATUSES.CASE_CLOSED_INITIAL_FIT_ISSUE]: {
    [ACCORDIONS.CASE_TRACKER]: false,
    [ACCORDIONS.DIAGNOSTIC_MATERIALS]: true,
  },
  [PROVIDER_FACING_STATUSES.TREATMENT_COMPLETED]: {
    [ACCORDIONS.CASE_TRACKER]: false,
    [ACCORDIONS.DIAGNOSTIC_MATERIALS]: true,
  },
  [PROVIDER_FACING_STATUSES.CASE_COMPLETED]: {
    [ACCORDIONS.CASE_TRACKER]: false,
    [ACCORDIONS.DIAGNOSTIC_MATERIALS]: true,
  },
  [PROVIDER_FACING_STATUSES.CASE_EXPIRED]: {
    [ACCORDIONS.CASE_TRACKER]: false,
    [ACCORDIONS.DIAGNOSTIC_MATERIALS]: false,
  },
  [PROVIDER_FACING_STATUSES.CASE_CANCELED]: {
    [ACCORDIONS.CASE_TRACKER]: false,
    [ACCORDIONS.DIAGNOSTIC_MATERIALS]: true,
  },
};

export enum ACTIONS {
  ARCHIVE_CASE = 'Archive this case',
  MARK_DELIVERED = 'Mark delivered',
  VIEW_IN_CANDID_MONITORING = 'View in ProMonitoring',
  ORDER_REPLACEMENT = 'Order a replacement',
  ORDER_RETAINERS = 'Order retainers',
  REQUEST_REFINEMENTS = 'Request refinements',
  REQUEST_ADDITIONAL_ALIGNERS = 'Request additional aligners',
  VIEW_PRINT_TREATMENT_PLAN_PDF = 'View/print Treatment Plan PDF',
  RESEND_CANDID_APP_ACTIVATION_EMAIL_TO_PATIENT = 'Re-send CandidApp activation email to patient',
  CREATE_NEW_CASE = 'Create new case',
  TRACK_SHIPMENT = 'Track shipment',
  VIEW_ORDERS_AND_SHIPPING = 'View orders and shipping',
  RESEND_ACTIVATION_EMAIL = 'Resend activation email',
  CONTACT_SUPPORT = 'Contact support',
  CREATE_QUALITY_TICKET = 'Report a product issue',
}
