import React, { useState, useContext } from 'react';

import {
  HistoryWrapper,
  HistoryHeading,
} from 'components/MaterialSubmissionHistory/History.css';

import { AUTO_EXPAND_HISTORY_GROUPS } from 'constants/index.js';
import { AuthContext } from 'components/AuthProvider';
import PhotoModal from 'components/Modals/PhotoModal';
import History, {
  HistoryItemType,
  IHistoryAttachment,
} from 'components/MaterialHistory/History';
import { SubmissionFragment } from 'pages/OrthoPrism/orthoSlice';
import {
  titleMapping,
  evaluationTypeMapping,
} from 'components/MaterialSubmissionHistory/config';
import CollapsibleHistory from 'components/CollapsableHistory/CollapsibleHistory';
import { Nullable, isNotNullish } from 'utils/types';

type Props = {
  submissions: SubmissionFragment[];
  isExpanded?: boolean;
  includeHeader?: boolean;
  showEmail?: boolean;
  materialName: string;
};

type FileInfo = {
  filename?: string;
  url?: string;
};

type SubmissionHistoryItem = NonNullable<SubmissionFragment['history']>[number];

const MaterialSubmissionHistory = ({
  submissions,
  isExpanded,
  includeHeader = true,
  showEmail = true,
  materialName,
}: Props) => {
  const [selectedAttachment, setSelectedAttachment] = useState<FileInfo | null>(
    null
  );
  const { checkHasAccess } = useContext(AuthContext);

  const convertToHistoryAttachment = (info: FileInfo): IHistoryAttachment => ({
    onClick: () => setSelectedAttachment(info),
    filename: info.filename,
  });

  const convertToSections = (submissions: SubmissionFragment[]) => {
    const sections = submissions.map((submission, idx) => ({
      version: submissions.length - idx,
      historyItems: submission
        .history!.map((h) => convertToHistoryItem(h))
        .filter(isNotNullish),
    }));

    return sections;
  };

  const convertToHistoryItem = ({
    action,
    actionDate,
    actionBy,
    notes,
    attachments,
    evaluationType,
  }: SubmissionHistoryItem): Nullable<HistoryItemType> => {
    const materialState = action.toLowerCase();
    const { Icon, text } = titleMapping[materialState] ?? {};

    const title = text ? text.replace('Material', materialName) : '';

    const reviewer = getReviewer(materialName, evaluationType?.name ?? '');
    if (!title) {
      return null;
    }

    return {
      attachments:
        attachments?.map((attachment) =>
          convertToHistoryAttachment(attachment as FileInfo)
        ) ?? undefined,
      author: (showEmail && actionBy) || '',
      dateTime: actionDate,
      title: `${title} ${reviewer}`,
      notes: notes ?? undefined,
      Icon: Icon,
    };
  };

  const getReviewer = (materialName: string, evaluationType: string) => {
    if (evaluationType) {
      //Scans and xrays currently have an incorrect evaluation associated with their reviews.
      //Just bandaid it on the front end.
      if (materialName === 'Scans' || materialName === 'X-rays') {
        return evaluationTypeMapping['quality_control_evaluation'];
      }
      return evaluationTypeMapping[evaluationType];
    }
    return '';
  };

  const historySections = convertToSections(submissions);

  // If the isExpanded prop is not passed in use AUTO_EXPAND_HISTORY_GROUPS to determine collapse state
  const autoExpandHistory =
    typeof isExpanded === 'undefined'
      ? checkHasAccess(AUTO_EXPAND_HISTORY_GROUPS)
      : isExpanded;

  return (
    <HistoryWrapper>
      <PhotoModal
        isOpen={!!selectedAttachment}
        fileName={selectedAttachment?.filename ?? null}
        url={selectedAttachment?.url ?? null}
        onClose={() => setSelectedAttachment(null)}
      />
      {includeHeader ? (
        <CollapsibleHistory
          header={<HistoryHeading>Submission History</HistoryHeading>}
          isExpanded={autoExpandHistory}
        >
          <History historySections={historySections} />
        </CollapsibleHistory>
      ) : (
        <History historySections={historySections} />
      )}
    </HistoryWrapper>
  );
};

export default MaterialSubmissionHistory;
