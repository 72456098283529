import React, { useContext } from 'react';
import { ActionSection } from 'pages/TpCentralEnhanced/Actions/components';
import { useTranslation } from 'react-i18next';
import {
  CaseSoftwareChoice,
  TreatmentPlanningSoftware,
  TreatmentPlanStaging,
} from 'generated/core/graphql';
import { Nullable } from 'utils/types';
import api, { ExtractVariablesType } from 'state/api';
import { NotificationContext } from 'core/components';

export const OpenTPSoftware = ({
  caseRef,
  isLoading = true,
  selectedTP,
  software,
}: {
  caseRef: string;
  isLoading: boolean;
  selectedTP: TreatmentPlanStaging;
  software: Nullable<CaseSoftwareChoice>;
}) => {
  const { showNotification } = useContext(NotificationContext);
  const [getPayload] =
    api.useLazyGetTreatmentPlanningSoftwareOpeningPayloadQuery();
  type params = ExtractVariablesType<
    typeof api.useLazyGetTreatmentPlanningSoftwareOpeningPayloadQuery
  >;
  const { t } = useTranslation();
  const l10nResource = `tp_central_enhanced.open_treatment_plan_in_${(software ?? CaseSoftwareChoice.Vision).toLowerCase()}`;
  return (
    <ActionSection
      title={t(l10nResource)}
      buttonTitle={t('common.open')}
      buttonAction={async () => {
        let params: params;
        if (software === CaseSoftwareChoice.Archform) {
          params = {
            caseRef,
            software: TreatmentPlanningSoftware.Archform,
          };
        } else if (
          software === CaseSoftwareChoice.Vision ||
          software === null
        ) {
          params = {
            caseRef,
            software: TreatmentPlanningSoftware.Vision,
          };
        } else {
          showNotification(
            'Error, Unsupported software, please contact support',
            'error'
          );
          return;
        }
        if (selectedTP?.iteration) {
          params.treatmentPlanStagingId = selectedTP?.id;
        }
        let payload: string | undefined | null;
        if (software === CaseSoftwareChoice.Archform) {
          const { archform } = await getPayload(params).unwrap();
          payload = archform?.payload;
        }
        if (software === CaseSoftwareChoice.Vision || software === null) {
          const { vision } = await getPayload(params).unwrap();
          payload = vision?.payload;
        }
        if (!payload) {
          showNotification(
            'Error, could not fetch the Archform link, please contact support',
            'error'
          );
          return;
        }
        window.open(payload);
      }}
      isDisabled={isLoading}
      disabledText={t('tp_central_enhanced.this_is_disabled')}
    />
  );
};
