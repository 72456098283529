import React, {
  CSSProperties,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import styled from 'styled-components/macro';
import {
  Chip,
  Heading,
  Loading,
  Skeleton,
  colors,
  type,
} from 'core/components';
import { Dropdown } from 'components/Dropdown/Dropdown';
import ArrowDownLine from 'assets/arrow-down-line.svg?react';
import ArrowUpAndDownLine from 'assets/arrow-up-and-down-line.svg?react';
import ArrowUpLine from 'assets/arrow-up-line.svg?react';
import FilterSVG from 'assets/filter.svg?react';
import LinkOutSVG from 'assets/link-out.svg?react';
import MoreVerticalIcon from 'assets/ic_more-vertical.svg?react';

import { PageSection } from 'styles/layout.css';
import { LinkButton } from 'styles/inputs.css';
import {
  getBrandFromDomain,
  getBrandSettings,
  getBrandTheme,
  SupportedBrand,
} from 'utils/brands';
import { searchDMPatientsURL } from 'utils/url';
import querier, {
  Filter,
  QueryResult,
  Sort,
} from 'components/SearchTablePage/customerQuerier';
import { PAGINATION_LIMIT } from 'components/SearchTablePage/constants';
import { Column, ColumnProps, TableHeaderProps } from 'react-virtualized';
import {
  FilterType,
  SearchSidebar,
} from 'components/SearchTablePage/SearchSidebar';
import {
  CustomerSearchProps,
  CustomerSearchRow,
} from 'components/SearchTablePage/types';
import { SortDirection, SortField } from 'generated/legacy/graphql';
import { FormikInputWithIcon } from 'components/FormikForms';
import { Formik } from 'formik';
import api from 'state/api';
import {
  StyledVirtualizedTable,
  ExpandedNotesCell,
  CollapsedNotesCell,
} from 'components/SearchTablePage/table/index.css';
import { debounce } from 'lodash';

const DEFAULT_OFFSET = 5;
const FIRST_COLUMN_OFFSET = 12;

const NonSortableColumnHeader = styled.div`
  cursor: default;
`;

const SortableColumnHeader = styled.div`
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  transition: opacity 0.2s ease-in-out;
`;

const BrandedDot = styled.span`
  color: ${({ color }) => color};
  margin-right: 4px;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }
`;

type SortableColumnProps = ColumnProps & {
  sortField: keyof typeof SortField;
};

export const SortableColumn = ({
  sortField,
  ...props
}: SortableColumnProps) => <Column {...props} />;

export default function VirtualizedSearchTable({
  columns,
  filterType,
  title,
  enablePatientNotes,
}: {
  columns: ReactElement[];
  filterType: FilterType;
  title: string;
  enablePatientNotes?: boolean;
}) {
  /* SORTING */
  const [sort, setSort] = useState<Sort | null>(null);

  /* FILTERING */
  const [filter, setFilter] = useState<Filter | null>(null);

  /* QUERYING */
  const [cancelFn, setCancelFn] = useState<() => void>(() => {});
  const [result, setResult] = useState<QueryResult | null>(null);
  useEffect(() => {
    if (!filter) {
      return;
    }

    cancelFn?.();
    setResult(null);
    const updatedFilter = {
      ...filter,
      state: filter.state,
    };

    const { cancel } = querier(
      PAGINATION_LIMIT,
      updatedFilter,
      sort,
      setResult
    );

    // Annoying, but React treats the `cancel` function as a functional update if
    // passed directly to `setCancelFn`, so we need to wrap it to avoid that.
    setCancelFn(() => cancel);
  }, [filter, sort]);

  const appliedFiltersCount = useMemo(
    () =>
      filter
        ? (filter.doctors && filter.doctors.length > 0 ? 1 : 0) +
          (filter.state && filter.state.length > 0 ? 1 : 0)
        : 0,
    [filter]
  );

  /* NOTES */
  const { useLazyGetPatientNotesQuery } = api;
  const [getNotes, { data: notesData }] = useLazyGetPatientNotesQuery();
  const [patientIdsForNotesQuery, setPatientIdsForNotesQuery] = useState<
    string[]
  >([]);
  const [cachedNotesData, setCachedNotesData] = useState<
    Record<string, string>
  >({});

  useEffect(() => {
    if (enablePatientNotes && result?.data) {
      const patientIds = result.data.map((row) => row.id);
      setPatientIdsForNotesQuery(
        patientIds.filter((id) => cachedNotesData[id] === undefined)
      );
    }
  }, [result?.data]);

  useEffect(() => {
    if (enablePatientNotes && patientIdsForNotesQuery.length) {
      getNotes({
        patientIds: patientIdsForNotesQuery,
      });
    }
  }, [patientIdsForNotesQuery, getNotes]);

  useEffect(() => {
    if (enablePatientNotes && notesData) {
      const newNotesData = patientIdsForNotesQuery.reduce(
        (acc, id) => {
          acc[id] = notesData.find((n) => n.patientId === id)?.text ?? '';
          return acc;
        },
        { ...cachedNotesData }
      );
      setCachedNotesData(newNotesData);
    }
  }, [notesData]);

  /* SYNTHETICS */
  const rows = useMemo(() => result?.data ?? [], [result?.data]);

  const rowsWithNotes = useMemo(() => {
    return enablePatientNotes
      ? rows.map((row) => {
          return {
            ...row,
            notes: cachedNotesData?.[row.id],
          };
        })
      : [];
  }, [rows, cachedNotesData]);

  const fetchMore = useMemo(
    () => () => result?.fetchMore?.(),
    [result?.fetchMore]
  );

  /* PRESENTATION */
  const defaultHeaderRenderer = useMemo(
    () =>
      function defaultRenderHeader(
        style: CSSProperties,
        { label }: TableHeaderProps
      ) {
        return (
          <NonSortableColumnHeader>
            <span style={style}>{label}</span>
          </NonSortableColumnHeader>
        );
      },
    []
  );

  const sortableHeaderRenderer = useMemo(
    () =>
      function sortableHeaderRenderer(
        style: CSSProperties,
        sortField: keyof typeof SortField,
        { dataKey, label }: TableHeaderProps
      ) {
        return (
          <SortableColumnHeader
            onClick={() => setSort(getNextSort(sort, sortField))}
          >
            <div
              style={{
                display: 'flex',
              }}
            >
              <span
                style={{
                  ...style,
                  marginRight: '8px',
                }}
              >
                {label}
              </span>
              <span>
                <SortIcon
                  sort={
                    sort?.field === sortField
                      ? SortDirection[sort.direction]
                      : undefined
                  }
                  testId={`table-sort-icon-${dataKey}`}
                />
              </span>
            </div>
          </SortableColumnHeader>
        );
      },
    [sort, setSort]
  );

  const sortableColumns = useMemo(
    () =>
      React.Children.map(columns, (child, index) => {
        switch (child.type) {
          case SortableColumn: {
            const item = child as ReactElement<SortableColumnProps>;
            return (
              <Column
                {...item.props}
                width={
                  index === 0
                    ? item.props.width + (FIRST_COLUMN_OFFSET - DEFAULT_OFFSET)
                    : item.props.width
                }
                style={{
                  ...item.props.style,
                  paddingLeft:
                    index === 0 ? FIRST_COLUMN_OFFSET : DEFAULT_OFFSET,
                }}
                headerRenderer={sortableHeaderRenderer.bind(
                  undefined,
                  index === 0
                    ? { paddingLeft: FIRST_COLUMN_OFFSET }
                    : { paddingLeft: DEFAULT_OFFSET },
                  item.props.sortField
                )}
              />
            );
          }
          case Column: {
            const item = child as ReactElement<ColumnProps>;

            return React.cloneElement(item, {
              ...item.props,
              width:
                index === 0
                  ? item.props.width + (FIRST_COLUMN_OFFSET - DEFAULT_OFFSET)
                  : item.props.width,
              style: {
                ...item.props.style,
                paddingLeft: index === 0 ? FIRST_COLUMN_OFFSET : DEFAULT_OFFSET,
              },
              headerRenderer: defaultHeaderRenderer.bind(
                undefined,
                index === 0
                  ? { paddingLeft: FIRST_COLUMN_OFFSET }
                  : { paddingLeft: DEFAULT_OFFSET }
              ),
            });
          }
          default:
            return child;
        }
      }),
    [columns, defaultHeaderRenderer, sortableHeaderRenderer]
  );

  const isLoading = result?.loading ?? true;
  const isInitializing = !rows.length && (result?.loading ?? true);

  const [isOpen, setIsOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          marginLeft: '1.25rem',
          width: 'calc(100vw - 80px)',
        }}
      >
        <StyledHeading>{title}</StyledHeading>

        <Formik initialValues={{}} onSubmit={() => {}}>
          {isSearchOpen ? (
            <div
              style={{
                margin: 'auto 0',
                paddingRight: '12px',
                flex: '0 1 400px',
              }}
            >
              <FormikInputWithIcon
                leadingIcon={<SearchIcon />}
                trailingIcon={
                  <CloseIcon
                    style={{
                      height: '16px',
                      width: '16px',
                      color: '#BCBCBC',
                    }}
                  />
                }
                placeholder={'Search for patient'}
                type="text"
                autoFocus={true}
                name="searchInput"
                testId="customer-search-input"
                onBlur={() => {
                  if (filter?.searchTerm === '') {
                    setIsSearchOpen(false);
                  }
                }}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    searchTerm: e.target.value,
                  })
                }
                onClear={() => {
                  setFilter({
                    ...filter,
                    searchTerm: '',
                  });
                  setIsSearchOpen(false);
                }}
                value={filter?.searchTerm}
              />{' '}
            </div>
          ) : (
            <div
              data-testid="table-search"
              style={{
                margin: 'auto 0',
                paddingRight: '12px',
              }}
            >
              <StyledSearchIcon onClick={() => setIsSearchOpen(true)} />
            </div>
          )}
        </Formik>

        <StyledChip
          label={appliedFiltersCount}
          hidden={!appliedFiltersCount}
          size="tiny"
        />
        <StyledFilterSVG
          data-testid="table-filter"
          isActive={!!appliedFiltersCount}
          onClick={() => setIsOpen(true)}
        />
      </div>

      <SearchSidebar
        drawer={{
          isOpen,
          setIsOpen,
        }}
        filterDoctors={true}
        filterType={filterType}
        search={false}
        onSubmit={(values: CustomerSearchProps) => {
          setFilter({
            searchTerm: filter?.searchTerm,
            component: values.filterComponent,
            doctors: values.doctors,
            state: values.filterValue,
          });
        }}
        showClearFilter={true}
      />
      <PureVirtualizedSearchTable
        fetchMore={fetchMore}
        isLoading={isLoading}
        isInitializing={isInitializing}
        rows={enablePatientNotes ? rowsWithNotes : rows}
      >
        {sortableColumns}
      </PureVirtualizedSearchTable>
    </>
  );
}

const TableSkeleton = ({
  children,
  rows,
}: {
  children: ReactElement<ColumnProps>[];
  rows: number;
}) => (
  <StyledVirtualizedTable
    loadMoreRows={() => {}}
    rowHeight={ROW_HEIGHT}
    headerHeight={HEADER_HEIGHT}
    rowCount={rows}
    rows={new Array(rows).fill({})}
  >
    {children.map((c) => ({
      ...c,
      props: {
        ...c.props,
        headerRenderer: () => <Skeleton width="80%" />,
        cellRenderer: () => <Skeleton width="80%" />,
      },
    }))}
  </StyledVirtualizedTable>
);

const PureVirtualizedSearchTable = ({
  children,
  fetchMore,
  isLoading,
  isInitializing,
  rows,
}: {
  children: ReactElement<ColumnProps>[];
  fetchMore: () => void;
  isLoading: boolean;
  isInitializing: boolean;
  rows: readonly CustomerSearchRow[];
}) =>
  isInitializing ? (
    <TableSkeleton rows={20}>{children}</TableSkeleton>
  ) : (
    <Container>
      <Wrapper>
        {rows.length ? (
          <StyledVirtualizedTable
            loadMoreRows={fetchMore}
            rowHeight={ROW_HEIGHT}
            headerHeight={HEADER_HEIGHT}
            rowCount={rows.length}
            rows={rows}
            scrollToAlignment="start"
          >
            {children}
          </StyledVirtualizedTable>
        ) : (
          <EmptyResults />
        )}
      </Wrapper>
      {isLoading && <Loading isCentered />}
    </Container>
  );

export const customerCellRenderer = (
  cellData: string | number | boolean,
  baseUrl: string,
  copy?: string
) => (
  <LinkButton
    data-testid="ortho-prism-search-table"
    href={`${baseUrl}/${cellData}`}
  >
    {copy || cellData}
  </LinkButton>
);

export const brandedCustomerCellRenderer = (
  id: string | number | boolean,
  baseUrl: string,
  brandName: string
) => {
  const { brandChip } = getBrandTheme(brandName as SupportedBrand);
  return (
    <>
      <BrandedDot color={brandChip?.props?.background}>&#x25cf;</BrandedDot>
      <LinkButton
        data-testid="ortho-prism-search-table"
        href={`${baseUrl}/${id}`}
      >
        {id}
      </LinkButton>
    </>
  );
};

export const privateCellRenderer = (cellData: string | number | boolean) => (
  <span data-private>{cellData}</span>
);

const NotesCell = ({
  cellData,
  isExpanded,
  setIsExpanded,
}: {
  cellData: string;
  isExpanded: boolean;
  setIsExpanded: (isOpen: boolean) => void;
}) => {
  const textRef = useRef<HTMLParagraphElement>(null);
  const [textIsOverflowing, setTextIsOverflowing] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      setTextIsOverflowing(
        textRef.current.scrollWidth > textRef.current.clientWidth
      );
    }
  }, []);

  const debouncedExpand = debounce((open: boolean) => {
    setIsExpanded(open && textIsOverflowing);
  }, 100);

  return isExpanded ? (
    <ExpandedNotesCell
      elevation={2}
      onMouseOver={() => debouncedExpand(true)}
      onMouseLeave={() => debouncedExpand(false)}
    >
      <p>{cellData}</p>
    </ExpandedNotesCell>
  ) : (
    <CollapsedNotesCell
      onMouseOver={() => debouncedExpand(true)}
      onMouseLeave={() => debouncedExpand(false)}
    >
      <p ref={textRef}>{cellData}</p>
    </CollapsedNotesCell>
  );
};

export const NotesCellContainer = ({ cellData }: { cellData?: string }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  // can't test if the cellData is falsy because it can be an empty string
  if (cellData === undefined) {
    return <Skeleton width={100} />;
  }
  return (
    <NotesCell
      cellData={cellData}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
    />
  );
};

const { monitoringLabel } = getBrandSettings(getBrandFromDomain());
export const monitoringLinkRenderer = (cellData: string | number | boolean) => {
  return (
    <Dropdown title={<MoreVerticalIcon />}>
      {[
        <LinkWithIcon
          key="monitoring-link"
          href={`${searchDMPatientsURL()}${encodeURIComponent(cellData)}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Open in {monitoringLabel}
          <div>
            <LinkOutSVG />
          </div>
        </LinkWithIcon>,
      ]}
    </Dropdown>
  );
};

const HEADER_HEIGHT = 54;
const TOP_NAV_HEIGHT = 200;
const ROW_HEIGHT = 54;

const Container = styled.div`
  position: relative;
  padding-bottom: 2.5rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100vw;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow-x: scroll;
  }
`;

const Wrapper = styled.div`
  position: relative;
  height: calc(100vh - ${TOP_NAV_HEIGHT}px);
  margin: auto;
`;

const EmptyRender = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 400px;
  background-color: ${colors.black05};
`;

const LinkWithIcon = styled.a`
  color: ${colors.blue50};
  display: flex;
  align-items: center;
  gap: 0.4rem;
  div {
    width: 1rem;
    svg {
      padding-bottom: 0.2rem;
    }
  }
`;

const StyledChip = styled(Chip)`
  margin: auto 8px auto 0;

  &.MuiChip-root {
    background-color: ${({ theme }) => theme.colors.blue50};
  }

  .MuiChip-label {
    color: white;
  }
`;

const StyledHeading = styled(Heading)`
  color: ${({ theme }) => theme.colors.black90};
  cursor: default;
  flex: 1;
  font-size: 24px;
  font-weight: 500;
  margin: 24px 0;
`;

const StyledSearchIcon = styled(SearchIcon)`
  color: ${({ theme }) => theme.colors.black70};
  margin: auto 0;
`;

const StyledFilterSVG = styled(FilterSVG)<{
  isActive: boolean;
}>`
  cursor: pointer;
  margin: auto 0;
  path {
    stroke: ${({ isActive, theme }) =>
      isActive ? theme.colors.blue50 : undefined};
  }
`;

const EmptyResults = () => (
  <PageSection>
    <EmptyRender>
      <type.H3>There are no search results</type.H3>
    </EmptyRender>
  </PageSection>
);

const SortIcon = ({
  sort,
  testId,
}: {
  sort: SortDirection | undefined;
  testId?: string;
}) => {
  switch (sort) {
    case SortDirection.Asc:
      return <ArrowUpLine data-testid={testId} />;
    case SortDirection.Desc:
      return <ArrowDownLine data-testid={testId} />;
    default:
      return <ArrowUpAndDownLine data-testid={testId} />;
  }
};

const getNextSort = (sort: Sort, field: keyof typeof SortField): Sort =>
  sort?.field !== field
    ? {
        field,
        direction: 'Asc',
      }
    : sort.direction === 'Asc'
      ? { field, direction: 'Desc' }
      : null;
