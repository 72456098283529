import styled from 'styled-components/macro';
import { colors, Tooltip } from 'core/components';
import Plus from 'assets/plus-circle-outline.svg?react';
import PencilIcon from 'assets/pencil-2.svg?react';
import TrashIcon from 'assets/trash.svg?react';

export const AddButton = styled.div`
  cursor: pointer;
  height: 105px;
  margin-bottom: 1.5rem;
  border: 2px dashed ${({ theme }) => theme.colors.blue50};
  border-radius: 8px;
`;

export const PlusIcon = styled(Plus)`
  width: 20px;
  height 20px;
  margin-right: 0.5rem;
  margin-bottom: 2px;
  > path {
    fill: ${colors.blue50};
  }
`;

export const ButtonContent = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.blue50};
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  font-weight: 700;
  margin: 32px auto;
  svg {
    margin-left: 0.5rem;
  }
`;

export const TemplateItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid ${colors.black05};
  border: 2px solid ${({ theme }) => theme.colors.black20};
  border-radius: 8px;
  margin-bottom: 0.5rem;
  position: relative;

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.blue50};
  }
`;

export const TemplateHeader = styled.div`
  display: flex;
  justify-content: space-between; /* Push items to the edges */
  align-items: center; /* Align items vertically in the center */
  width: 100%; /* Set container width */
`;

export const InlineEditingFeaturesContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 1rem; /* Position at the bottom */
  right: 1rem; /* Align to the right */
`;

export const TemplateName = styled.div`
  font-size: 16px;
  font-weight: 700;
  flex: 0 0 auto; /* Ensure items don't shrink or grow */
`;

export const TemplateDescription = styled.div`
  font-family: Modern Era;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const MetaItem = styled.span`
  margin-right: 0.5rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }
`;

export const MobileTooltip = styled(Tooltip)`
  @media ${({ theme }) => theme.mediaQueries.tabletAndAbove} {
    &.MuiSvgIcon-root {
      display: none;
    }
  }
`;

interface EditButtonProps {
  complete: boolean;
}

export const EditButton = styled.div<EditButtonProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: 10rem;
  flex: 0 0 auto;
  color: ${({ theme }) => theme.colors.blue50};
  font-family: Modern Era;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  & > span {
    position: relative;
    top: 1px; /* Adjust the value as needed to lower the text */
  }

  & > svg {
    margin-left: 0.25rem; /* Adjust spacing between icon and text */
  }
`;

export const PencilIconStyled = styled(PencilIcon)`
  width: 20px;
  height: 20px;
  margin-left: 0.5rem;
  path {
    stroke: ${({ theme }) => theme.colors.blue50};
  }
`;
export const TrashIconStyled = styled(TrashIcon)`
  width: 20px;
  height: 20px;
  margin-left: 0.5rem;
  path {
    stroke: ${({ theme }) => theme.colors.red50};
  }
`;

// This is required to create spacing between the incomplete chip and the edit buttons if the template is missing a description
interface TemplateMetaProps {
  hasMarginTop?: boolean;
}

export const TemplateMeta = styled.div<TemplateMetaProps>`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-top: ${({ hasMarginTop }) => (hasMarginTop ? '.75rem' : '0')};
`;

export const DeletionConfirmationModalContainer = styled.div`
  width: 20rem;
  height: 11rem;
  text-align: center;
`;

export const DeletionConfirmation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  padding-left: 0rem;
`;

export const DeletionModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 3rem 0 3rem;
`;

export const IncompleteChipContainer = styled.div`
  position: absolute;
  top: 0.25;
  right: 0.25rem;
  background-color: ${({ theme }) => theme.colors.black05};
`;
